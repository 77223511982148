import React, { Component } from 'react';
import axios from 'axios';
import {getRandomNumber} from '../getRandomNumber';
import SpeakerCard from '../template/speaker-card';
import ls from 'localstorage-slim';
import Pagination from "../template/pagination";
const {
    PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC,
    REACT_APP_LOCAL_ACCESS,
    REACT_APP_HOME_URL
} = process.env;

class SpeakersData extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);

        this.state = {
            constData: props.constData,
            data: [],
            code: 400,
            eventId: ls.get('eventId'),
            activePage: this.props.activePage !== undefined ? this.props.activePage : 1,
            speakersLimit: this.props.speakersLimit !== undefined ? this.props.speakersLimit : '',
            searchSpeaker: this.props.searchSpeaker !== undefined ? this.props.searchSpeaker : '',
            SpeakerOrder: this.props.SpeakerOrder !== undefined ? this.props.SpeakerOrder : '',
            RelatedSpeakers: this.props.RelatedSpeakers !== undefined ? this.props.RelatedSpeakers : false,
            FeatureId: this.props.FeatureId !== undefined ? this.props.FeatureId : '4',
            count: 0,
            TotalEvents: 0,
            SpeakersData: [],
            Pagination: this.props.Pagination !== undefined ? this.props.Pagination : true,
        }

    }

    GetSpeakersData = async () => {

        ls.config.encrypt = true;

        let constData = this.state.constData;

        const isLocalAccess = REACT_APP_LOCAL_ACCESS;
        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var speakersData = [];
        var DataFetch = true;

        if(GetEventsUserData !== undefined && GetEventsUserData !== null ){
            GetEventsUserData.map(function (el) {
                if( Number( el.eventId ) === Number( ls.get('eventId') ) ){
                    if( undefined !== el.SpeakersData && "" !== el.SpeakersData && null !== el.SpeakersData ){
                        DataFetch = false;
                    }
                }
                return el;
            });
        }

        let currentPath  = window.location.pathname;

        if( ( GetEventsUserData === null && Number(isLocalAccess) === 0 ) || (DataFetch && currentPath !== REACT_APP_HOME_URL+'/dashboard') ) {

            if( null !== constData && '' !== constData ) {

                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method":"getSpeakerList",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_people":"",
                            "event_agenda_people":"",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "event_id": eventId,
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {
                    let result = res.data !== undefined ? res.data[0].result : "";

                    if (undefined !== result && "" !== result && undefined !== result.code && result.code === 200) {
                        this.setState({SpeakersData: result.event_people});
                        var SpeakerExists = false;

                        if( GetEventsUserData !== null ){
                            GetEventsUserData = GetEventsUserData.map(function(el) {
                                if( el.eventId === eventId) {
                                    SpeakerExists = true;
                                    el.SpeakersData = result.event_people;
                                    speakersData = result.event_people;
                                }
                                return el;
                            })
                            if( !SpeakerExists ){
                                var speakersLists = {
                                    eventId:eventId,
                                    SpeakersData: result.event_people
                                }
                                speakersData = result.event_people;
                                GetEventsUserData.push(speakersLists);
                            }
                        }else {
                            GetEventsUserData = [{
                                eventId:eventId,
                                SpeakersData: result.event_people
                            }]
                            speakersData = result.event_people;
                        }
                        ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                        var spinner_container = document.getElementById('spinner_container');
                        if( spinner_container !== null ){
                            spinner_container.style.display = "none";
                        }
                    }
                }).catch(error => {});
            }
        } else {
            if(undefined !== GetEventsUserData && null !== GetEventsUserData) {
                GetEventsUserData.map(function(el) {
                    if( el.eventId === eventId && undefined !== el.SpeakersData ) {
                        speakersData = el.SpeakersData;
                    }
                    return el;
                })
                this.setState({
                    SpeakersData: speakersData
                })
            }
        }
    }

    SpeakerStructure = () => {
        if( this.state.SpeakersData === undefined || this.state.SpeakersData.length === 0 ){
            this.GetSpeakersData();
        }
        var AllSpeakers = [];
        if( this.state.SpeakersData !== undefined && this.state.SpeakersData !== '' ) {

            if (this.props.searchSpeaker !== undefined && this.props.searchSpeaker !== '') {
                this.state.SpeakersData.map((speaker, index) => {
                    if( Number( speaker.fk_feature_id ) ===  Number( this.props.FeatureId ) && undefined !== speaker.status && "A" === speaker.status ){
                        var spekerFirstName = speaker.first_name ? speaker.first_name.toLowerCase() : '';
                        var spekerLastName = speaker.last_name ? speaker.last_name.toLowerCase() : '';
                        var spekerSalutation = speaker.salutation ? speaker.salutation.toLowerCase() : '';
                        var spekerFullName = spekerSalutation + spekerFirstName + ' ' + spekerLastName;
                        if(spekerFullName.search(this.props.searchSpeaker.toLowerCase()) !== -1){
                            speaker = {...speaker,full_name:spekerFullName};
                            AllSpeakers.push(speaker);
                            return '';
                        }
                    }
                    return false;
                })
            } else if( undefined !== this.state.RelatedSpeakers && this.state.RelatedSpeakers ){
                this.state.SpeakersData.map((speaker, index) => {
                    if( Number( speaker.fk_feature_id ) ===  Number( this.props.FeatureId ) && undefined !== speaker.status && "A" === speaker.status ){
                        var spekerFirstName = speaker.first_name ? speaker.first_name.toLowerCase() : '';
                        var spekerLastName = speaker.last_name ? speaker.last_name.toLowerCase() : '';
                        var spekerSalutation = speaker.salutation ? speaker.salutation.toLowerCase() : '';
                        var spekerFullName = spekerSalutation + spekerFirstName + ' ' + spekerLastName;
                        speaker = {...speaker,full_name:spekerFullName};
                        AllSpeakers.push(speaker);
                        return '';
                    }
                    return false;
                })
            } else {
                this.state.SpeakersData.map((speaker, index) => {
                    if( Number( speaker.fk_feature_id ) ===  Number( this.props.FeatureId ) && undefined !== speaker.status && "A" === speaker.status ){
                        var spekerFirstName = speaker.first_name ? speaker.first_name.toLowerCase() : '';
                        var spekerLastName = speaker.last_name ? speaker.last_name.toLowerCase() : '';
                        var spekerSalutation = speaker.salutation ? speaker.salutation.toLowerCase() : '';
                        var spekerFullName = spekerSalutation + spekerFirstName + ' ' + spekerLastName;
                        speaker = {...speaker,full_name:spekerFullName};
                        AllSpeakers.push(speaker);
                        return '';
                    }
                    return false;
                })
            }

            if( undefined !== AllSpeakers && AllSpeakers.length > 0 && ( undefined === this.state.AllSpeakers || this.state.AllSpeakers.length === 0 || this.state.update ) ){
                this.setState({AllSpeakers : AllSpeakers, update : false});
            }

            if( undefined === this.props.SpeakerOrder || ( 'Newest' !== this.props.SpeakerOrder && 'Oldest' !== this.props.SpeakerOrder ) ){
                AllSpeakers = AllSpeakers.sort((a, b) => a.full_name.localeCompare(b.full_name));
            } else if ( undefined !== this.props.SpeakerOrder && 'Oldest' === this.props.SpeakerOrder ){
                AllSpeakers = AllSpeakers.reverse();
            }

            if( undefined !== AllSpeakers && AllSpeakers.length > 0 ){

                var limit = this.props.perPage !== null ? this.props.perPage : 10;
                var startFrom = ( limit * this.props.activePage ) - limit;
                var displayTo = startFrom + limit;
                var count = 0;
                var checked = false;
                return(
                    <>
                        {
                            undefined !== this.state.RelatedSpeakers && this.state.RelatedSpeakers ?
                                <>
                                    <div className="card-header border-0 pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder fs-3 mb-1">Speakers</span>
                                            <span
                                                className="text-muted mt-1 fw-bold fs-7">Over {AllSpeakers.length} speakers</span>
                                        </h3>
                                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top"
                                             data-bs-trigger="hover" title="View All Speakers">
                                            <a href="speakers" className="btn btn-sm btn-light btn-active-primary">View All</a>
                                        </div>
                                    </div>
                                    <div className="row card-body py-3 row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                        {
                                            AllSpeakers.length !== 0 ? AllSpeakers.map((speaker, index) => {
                                                if (count >= startFrom && count < displayTo) {
                                                    count++;
                                                    checked = true;
                                                    return (
                                                        <SpeakerCard constData={this.state.constData} key={speaker.person_id} speaker={speaker} data-check={checked}/>
                                                    )
                                                }
                                                count++;
                                                return false;
                                            }) : "Speakers not available"
                                        }
                                    </div>
                                </> : <>
                                    <div className="card card-xl-stretch mb-8">
                                        <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">
                                            {
                                                AllSpeakers.length !== 0 ? AllSpeakers.map((speaker, index) => {
                                                    if (count >= startFrom && count < displayTo) {
                                                        count++;
                                                        checked = true;
                                                        return (
                                                            <SpeakerCard constData={this.state.constData} key={speaker.person_id} speaker={speaker}/>
                                                        )
                                                    }
                                                    count++;
                                                    return false;
                                                }) : "Speakers not available"
                                            }
                                        </div>
                                    </div>
                                    {undefined !== this.props.Pagination && this.props.Pagination ?
                                        <div className="d-flex flex-stack flex-wrap">
                                            <Pagination constData={this.state.constData} TatalPost={AllSpeakers.length}
                                                        PaginateSpeakers={this.props.PaginateSpeakers}
                                                        ActivePage={this.props.activePage} perPage={limit}/>
                                        </div> : ""}
                                </>
                        }
                    </>
                )
            } else {
                return (
                    <div className="card card-xl-stretch mb-8">
                        <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">Loading...</div>
                    </div>
                )
            }
        } else {
            return (
                <div className="card card-xl-stretch mb-8">
                    <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">Speaker not available</div>
                </div>
            )
        }
    }

    render() {
        return this.state.SpeakersData ? this.SpeakerStructure() : <div className="card card-xl-stretch mb-8">
            <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">Loading...</div>
        </div>;
    }
}
export default SpeakersData;