import React from 'react';
import {useTranslation} from "react-i18next";
import ls from 'localstorage-slim';

const ProfileDetail = (props) => {
    const { t } = useTranslation();
    ls.config.encrypt = true;
    const {user,userEmail} = props;

    var userFirstName = user.fname !== undefined ? user.fname : '';
    var userLastName = user.lname !== undefined ? user.lname : '';
    return (
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
                <div className="card mb-6 mb-xl-9">
                    <div className="card-header cursor-pointer">
                        <div className="card-title flex-column">
                            <h2 className="mb-1">{t("Profile Details")}</h2>
                        </div>
                    </div>
                    <div className="card-body p-9 pb-2">
                        <div id="kt_user_view_details" className="collapse show">
                            <div className="row pb-5 fs-6">
                                { userFirstName !== '' || userLastName !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Full Name")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6">{userFirstName} {userLastName}</span>
                                    </div>
                                </div> : ""}
                                { user.salut !== undefined && user.salut !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Salutation")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6">{user.salut}</span>
                                    </div>
                                </div> : ""}
                                { user.nname !== undefined && user.nname !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Nick Name")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6">{user.nname}</span>
                                    </div>
                                </div> : ""}
                                { user.title !== undefined && user.title !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Job Title")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6">{user.title}</span>
                                    </div>
                                </div> : ""}
                                { user.company !== undefined && user.company !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Company")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6">{user.company}</span>
                                    </div>
                                </div> : ""}
                                { userEmail !== undefined && userEmail !== '' ?
                                <div className="row mb-7">
                                    <label className="col-lg-3 fw-bold text-muted">{t("Email")}</label>
                                    <div className="col-lg-9">
                                        <a href={"mailto:"+userEmail} target="_blank" rel="noreferrer">
                                            <span className="fw-bolder fs-6">{userEmail}</span>
                                        </a>
                                    </div>
                                </div> : ""}
                                <div className="row mb-7">
                                    <label
                                        className="col-lg-3 fw-bold text-muted">{t("Phone")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6 text-gray-800">+91 9824895645</span>
                                    </div>
                                </div>
                                <div className="row mb-7">
                                    <label
                                        className="col-lg-3 fw-bold text-muted">{t("Address")}</label>
                                    <div className="col-lg-9">
                                        <span className="fw-bolder fs-6 text-gray-800">101 Collin Street, Melbourne 3000 VIC, Australia</span>
                                    </div>
                                </div>
                                <div className="row mb-7">
                                    <label
                                        className="col-lg-3 fw-bold text-muted">{t("Social")}</label>
                                    <div className="col-lg-9">
                                        <div className="mb-6">
                                            <a
                                                href="https://facebook.com/timchand"
                                                className="fw-bolder me-2"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="bi bi-facebook text-facebook-color fs-1 me-2"></i>
                                                <span>facebook.com/timchand</span>
                                            </a>
                                        </div>
                                        <div className="mb-6">
                                            <a href="https://linkedin.com/timchand"
                                                className="fw-bolder me-2"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="bi bi-linkedin text-linkedin-color fs-1 me-2"></i>
                                                <span>linkedin.com/timchand</span>
                                            </a>
                                        </div>
                                        <div className="mb-6">
                                            <a
                                                href="https://twitter.com/timchand"
                                                className="fw-bolder me-2"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="bi bi-twitter text-twitter-color fs-1 me-2"></i>
                                                <span>@timchand</span>
                                            </a>
                                        </div>
                                        <div className="mb-6">
                                            <a href="https://plus.google.com/timchnad"
                                                className="fw-bolder me-2"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="bi bi-google text-google-plus-color fs-1 me-2"></i>
                                                <span>plus.google.com/timchnad</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ProfileDetail;