import React, { Component } from 'react';
import axios from "axios";
import ls from 'localstorage-slim';
import {getRandomNumber} from "./getRandomNumber";

const {
    REACT_APP_HOME_URL,
    PUBLIC_URL,
    REACT_APP_JSON_RPC,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_VERSION,
    REACT_APP_LOCAL_ACCESS,
    REACT_APP_DEVICE_ID,
    REACT_APP_TEST_DATE,
    REACT_APP_DEVICE_KEY,
    REACT_APP_PF
} = process.env;

class ManageAPICall extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);
        this.state = {
            AllAttendees: [],
            constData: "",
        }
    }

    getAllAPIsync(isSyncClick = false, autoSync = false, isDashboard = false, constData = {}) {
        ls.config.encrypt = true;

        var isValid = false;

        const loder = document.getElementById('spinner_container');

        const isLogin = ls.get('login_status');

        if(window.location.pathname === REACT_APP_HOME_URL+'/dashboard' && isSyncClick === false && autoSync === true ) {
            isValid = true;
        }

        if(window.location.pathname === REACT_APP_HOME_URL+'/dashboard' && isDashboard === true && autoSync === true) {
            isValid = true;
        }

        if(isSyncClick) {
            isValid = true;
        }

        if(isSyncClick === false && autoSync === true ) {
            isValid = true;
        }

        if( isValid === true ) {
            if(undefined !== loder && null !== loder && isSyncClick === false) {
                loder.style.display = "block";
            }

            const eventID = ls.get("eventId");
            var isLoader = false;
            if( Number(REACT_APP_LOCAL_ACCESS) === 1 ) {
                this.GetSidebarMenuOption(eventID, constData).then(value => {
                    if( Number(value.status) === 200 ) {
                        this.getAboutLists(eventID, constData).then(value => {
                            if( Number(value.status) === 200 ) {
                                this.getAgendaList(eventID, constData).then(value => {
                                    if( Number(value.status) === 200 ) {
                                        this.getSponsorList(eventID, constData).then(value => {
                                            if( Number(value.status) === 200 ) {
                                                this.getSpeakerList(eventID, constData).then(value => {
                                                    if( Number(value.status) === 200 ) {
                                                        this.getMaterialList(eventID, constData).then(value => {
                                                            if( Number(value.status) === 200 ) {
                                                                this.syncAllProfileInfo(eventID,'', constData).then(value => {
                                                                    if( undefined !== value && undefined !== value.data && undefined !== value.data[0] && undefined !== value.data[0].result && value.status === 200 ) {
                                                                        if( undefined !== value.data[0].result.has_more_data && Number( value.data[0].result.has_more_data ) === 0 ) {
                                                                            isLoader = true;

                                                                            if( !isSyncClick && !autoSync && window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ) {
                                                                                window.location.href = REACT_APP_HOME_URL + '/dashboard';
                                                                            } else if( isSyncClick && autoSync ) {
                                                                                document.getElementById('sync_menu_item').classList.remove('sync-active');
                                                                            }

                                                                            if( undefined !== loder && null !== loder ) {
                                                                                loder.style.display = "none";
                                                                            }
                                                                        }
                                                                    } else {
                                                                        isLoader = true;

                                                                        if( !isSyncClick && !autoSync && window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ) {
                                                                            window.location.href = REACT_APP_HOME_URL + '/dashboard';
                                                                        } else if( isSyncClick && autoSync ) {
                                                                            document.getElementById('sync_menu_item').classList.remove('sync-active');
                                                                        }

                                                                        if( undefined !== loder && null !== loder ) {
                                                                            loder.style.display = "none";
                                                                        }
                                                                    }
                                                                }).catch(error => {
                                                                    /*console.error('There was an error! ' + error[0].message);*/
                                                                    if( undefined !== loder && null !== loder ) {
                                                                        loder.style.display = "none";
                                                                    }
                                                                });
                                                            }
                                                        }).catch(error => {
                                                            /*console.error('There was an error!', error);*/
                                                            if( undefined !== loder && null !== loder ) {
                                                                loder.style.display = "none";
                                                            }
                                                        });
                                                    }
                                                }).catch(error => {
                                                    /*console.error('There was an error!', error);*/
                                                    if( undefined !== loder && null !== loder ) {
                                                        loder.style.display = "none";
                                                    }
                                                });
                                            }
                                        }).catch(error => {
                                            /*console.error('There was an error!', error);*/
                                            if( undefined !== loder && null !== loder ) {
                                                loder.style.display = "none";
                                            }
                                        });
                                    }
                                }).catch(error => {
                                    /*console.error('There was an error!', error);*/
                                    if( undefined !== loder && null !== loder ) {
                                        loder.style.display = "none";
                                    }
                                });
                            }
                        }).catch(error => {
                            /*console.error('There was an error!' + error[0].message );*/
                            if( undefined !== loder && null !== loder ) {
                                loder.style.display = "none";
                            }
                        });
                    }
                }).catch(error => {
                    /*console.error('There was an error!' + error.message );*/
                    if( undefined !== loder && null !== loder ) {
                        loder.style.display = "none";
                    }
                });
            }
        } else {
            if(undefined !== loder && null !== loder) {
                loder.style.display = "none";
            }
        }
    }

    getAboutLists(eventID, constData) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method": "getAboutSectionList",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_about_sections": "",
                    "section_icons": "",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": eventID
                },
                "jsonrpc": REACT_APP_JSON_RPC
            },
        }

        return axios({
            url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then( res => {
            let result = res.data !== undefined ? res.data[0].result : "";
            if (result.code !== undefined && result.code === 200) {
                var SpeakerExists = false;
                if( GetEventsUserData !== null ){
                    GetEventsUserData = GetEventsUserData.map(function(el) {
                        if( el.eventId === eventID) {
                            SpeakerExists = true;
                            el.EventAboutData = result.event_about_sections;
                        }
                        return el;
                    })

                    if( !SpeakerExists ){
                        var agendasLists = {
                            eventId:eventID,
                            EventAboutData: result.event_about_sections,
                        }
                        GetEventsUserData.push(agendasLists);
                    }
                } else {
                    GetEventsUserData = [{
                        eventId:eventID,
                        EventAboutData: result.event_about_sections,
                    }]
                }

                ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
            }
            return res;
        }).catch( error => {});
    }

    getAgendaList(eventID, constData) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var agendasLists = [];
        var EventAgendaLocations = [];
        var EventAgendaSponsors = [];
        var AgendaLocations = [];
        var EventAgendaCategories = [];
        var EventCategories = [];
        var EventAgendaPeople = [];
        var EventAgendaLiveSettings = [];
        var LiveSessionPreference = [];

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method": "getAgendaList",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_agendas": "",
                    "event_agenda_categories": "",
                    "event_agenda_live_settings": "",
                    "live_session_preference": "",
                    "event_agenda_locations": "",
                    "event_agenda_people": "",
                    "event_agenda_sponsors": "",
                    "event_categories": "",
                    "event_locations": "",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": eventID
                }, "jsonrpc": REACT_APP_JSON_RPC
            },
        };

        return axios({
            url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => {
            var result = res.data !== undefined ? res.data[0].result : "";
            if (result.code !== undefined && result.code === 200) {
                var SpeakerExists = false;
                if( GetEventsUserData !== null ){
                    GetEventsUserData = GetEventsUserData.map(function(el) {
                        if( Number( el.eventId )  === Number( eventID ) ) {
                            SpeakerExists = true;
                            el.AgendasData = result.event_agendas;
                            el.AgendaLocations = result.event_locations;
                            el.EventCategories = result.event_categories;
                            el.EventAgendaLocations = result.event_agenda_locations;
                            el.EventAgendaCategories = result.event_agenda_categories;
                            el.EventAgendaSponsors = result.event_agenda_sponsors;
                            el.EventAgendaPeople = result.event_agenda_people;
                            el.EventAgendaLiveSettings = result.event_agenda_live_settings;
                            el.LiveSessionPreference = result.live_session_preference;
                        }
                        return el;
                    });
                    if( !SpeakerExists ){
                        agendasLists = {
                            eventId: eventID,
                            AgendasData: result.event_agendas,
                            AgendaLocations: result.event_locations,
                            EventAgendaLocations: result.event_agenda_locations,
                            EventCategories: result.event_categories,
                            EventAgendaCategories: result.event_agenda_categories,
                            EventAgendaSponsors: result.event_agenda_sponsors,
                            EventAgendaPeople: result.event_agenda_people,
                            EventAgendaLiveSettings: result.event_agenda_live_settings,
                            LiveSessionPreference: result.live_session_preference,
                        }
                        GetEventsUserData.push(agendasLists);
                    }
                }else {
                    GetEventsUserData = [{
                        eventId: eventID,
                        AgendasData: result.event_people,
                        AgendaLocations: result.event_locations,
                        EventAgendaLocations: result.event_agenda_locations,
                        EventCategories: result.event_categories,
                        EventAgendaCategories: result.event_agenda_categories,
                        EventAgendaSponsors: result.event_agenda_sponsors,
                        EventAgendaPeople: result.event_agenda_people,
                        EventAgendaLiveSettings: result.event_agenda_live_settings,
                        LiveSessionPreference: result.live_session_preference,

                    }]
                }

                if( GetEventsUserData !== null ){
                    GetEventsUserData.map(function(el) {
                        if( Number( el.eventId ) === Number( eventID )){
                            agendasLists = el.AgendasData;
                            EventCategories = el.EventCategories;
                            AgendaLocations = el.AgendaLocations;
                            EventAgendaLocations = el.EventAgendaLocations;
                            EventAgendaCategories = el.EventAgendaCategories;
                            EventAgendaSponsors = el.EventAgendaSponsors;
                            EventAgendaPeople = el.EventAgendaPeople;
                            EventAgendaLiveSettings = el.EventAgendaLiveSettings;
                            LiveSessionPreference = el.LiveSessionPreference;
                        }
                        return el;
                    })

                    this.setState({
                        AgendaDataArr: agendasLists,
                        EventCategories: EventCategories,
                        AgendaLocations: AgendaLocations,
                        EventAgendaLocations: EventAgendaLocations,
                        EventAgendaCategories: EventAgendaCategories,
                        EventAgendaSponsors: EventAgendaSponsors,
                        EventAgendaPeople: EventAgendaPeople,
                        EventAgendaLiveSettings: EventAgendaLiveSettings,
                        LiveSessionPreference: LiveSessionPreference,
                    })

                    ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                }
            }
            return res;
        }).catch(error => {});
    }

    syncAllProfileInfo( eventID, date="", constData ) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var AttendeeData = [];
        var UserData = [];
        var ProfileId = '';

        if(GetEventsUserData !== undefined){
            GetEventsUserData.map(function (el, UserProfile) {
                if( Number( el.eventId ) === Number( eventID ) && el.UserProfile !== undefined){
                    UserData = el.UserProfile;
                    ProfileId = UserData.profile_id;
                }
                return el;
            });
        }

        if( ProfileId ) {

            const data = {
                url: constData.ApiBaseUrl+'/webapp/index.php',
                method: "POST",
                post_fields: {
                    "id": getRandomNumber,
                    "method": "syncAllProfileInfo",
                    "params": {
                        "app_version": REACT_APP_VERSION,
                        "pf": REACT_APP_PF,
                        "profile_id": ProfileId,
                        "app_device_id": REACT_APP_DEVICE_ID,
                        "event_id": eventID,
                        "profile": date,
                        "acnt_profile_admin_fields_options": REACT_APP_TEST_DATE,
                        "contacts": date,
                        "admin_fields_data": REACT_APP_TEST_DATE,
                        "acnt_profile_fields_settings": REACT_APP_TEST_DATE,
                        "my_attendee_groups": REACT_APP_TEST_DATE,
                        "lang_id": REACT_APP_LANG_ID,
                        "event_chat_messages": REACT_APP_TEST_DATE,
                        "acnt_scanned_users": REACT_APP_TEST_DATE,
                        "event_notes": REACT_APP_TEST_DATE,
                        "app_groups": REACT_APP_TEST_DATE,
                        "app_group_connections": REACT_APP_TEST_DATE,
                        "app_group_users": REACT_APP_TEST_DATE
                    },
                    "jsonrpc": "2.0"
                },
            };

            return axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {
                let result = ( undefined !== res.data && res.data !== '' ) ? res.data[0].result : "";

                if (result.code !== undefined && result.code === 200) {

                    let getAllData = this.state.AllAttendees;
                    let MergedData = this.state.AllAttendees;
                    let fetchedData = result.profile !== undefined ? result.profile : [];

                    fetchedData = fetchedData !== undefined && fetchedData.length > 0 ? fetchedData.filter((attendees) => {
                        if( attendees.status === 'A' ) {
                            return attendees;
                        }
                        return false;
                    }) : [];

                    if( undefined !== fetchedData && fetchedData.length > 0 ) {
                        MergedData = [...getAllData, ...fetchedData];
                    }

                    var SpeakerExists = false;

                    if( GetEventsUserData !== null ){

                        GetEventsUserData = GetEventsUserData.map(function(el) {

                            if( Number(el.eventId ) === Number(eventID) ) {
                                SpeakerExists = true;
                                AttendeeData = fetchedData;
                                getAllData = el.AttendeeData;
                                if ( undefined !== getAllData && null !== getAllData && null !== getAllData[0] && undefined !== getAllData[0] && undefined !== AttendeeData && null !== AttendeeData && null !== AttendeeData[0] && undefined !== AttendeeData[0] ){
                                    AttendeeData = [...getAllData, ...AttendeeData];
                                }

                                if( undefined !== getAllData && null !== getAllData && null !== getAllData[0] && undefined !== getAllData[0] && ( undefined === AttendeeData || null === AttendeeData || null === AttendeeData[0] || undefined === AttendeeData[0] ) ) {
                                    AttendeeData = getAllData;
                                }

                                var uniqueueAttendee = [];
                                AttendeeData.map( (Attendee) => {
                                    if( uniqueueAttendee.length === 0 ){
                                        uniqueueAttendee.push(Attendee);
                                    }else{
                                        var exists = true;
                                        uniqueueAttendee.map( (id) => {
                                            if( id.profile_id === Attendee.profile_id){
                                                exists = false
                                            }
                                        });
                                        if( exists ){
                                            uniqueueAttendee.push(Attendee);
                                        }
                                    }
                                });

                                el.AttendeeData = uniqueueAttendee;
                                el.app_group_users = result.app_group_users;
                                el.acnt_profile_fields_settings = result.acnt_profile_fields_settings;
                            }

                            return el;
                        });

                        if( !SpeakerExists ) {

                            GetEventsUserData.push({
                                eventId: eventID,
                                AttendeeData: MergedData,
                                app_group_users: result.app_group_users,
                                acnt_profile_fields_settings: result.acnt_profile_fields_settings
                            });
                        }

                    } else {
                        GetEventsUserData = [{
                            eventId: eventID,
                            AttendeeData: MergedData,
                            app_group_users: result.app_group_users,
                            acnt_profile_fields_settings: result.acnt_profile_fields_settings
                        }]
                    }

                    this.setState({
                        AllAttendees: MergedData
                    });

                    ls.set("eeuserdata",JSON.stringify(GetEventsUserData));

                    if( result.has_more_data > 0 && result.profile !== '' && result.profile.length > 0 ){
                        const dataProfile = result.profile[result.profile.length - 1];
                        const LastProfileDate = ( dataProfile.modified ) ? dataProfile.modified : '';
                        res = this.syncAllProfileInfo(eventID, LastProfileDate, constData);
                    }
                }

                return res;
            }).catch(error => { });
        }
    }

    GetSidebarMenuOption(eventID, constData) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var UserData = [];
        var ProfileId = '';

        var MenuExists = false;

        if(GetEventsUserData !== undefined && GetEventsUserData !== null){
            GetEventsUserData.map(function (el) {
                if( Number( el.eventId ) === Number( eventID ) && el.UserProfile !== undefined){
                    UserData = el.UserProfile;
                    ProfileId = UserData.profile_id;
                    return el;
                }
                return el;
            });
        }

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method": "getUpdates",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_small_aux": "",
                    "profile_id": this.state.ProfileId,
                    "event_small_aux_data": "",
                    "event_settings": "",
                    "app_groups": "",
                    "app_group_connections": "",
                    "app_group_users": "",
                    "event_home_page": "",
                    "event_features": "",
                    "feature_masters": "",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": ls.get('eventId')
                },
                "jsonrpc": REACT_APP_JSON_RPC
            },
        };

        return axios({
            url: PUBLIC_URL + '/functions.php?data=' + JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => {
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            var eventId = ls.get('eventId');
            var result = res.data !== undefined ? res.data[0].result : "";
            if (result !== "" && undefined !== result.code && Number(result.code) === 200) {
                if (GetEventsUserData !== null) {
                    GetEventsUserData = GetEventsUserData.map(function (el) {
                        if (Number(el.eventId) === Number(eventId)) {
                            MenuExists = true;
                            el.event_home_page = result.event_home_page;
                            el.event_features = result.event_features;
                            el.feature_masters = result.feature_masters;
                            el.app_group_connections = result.app_group_connections;
                            el.app_groups = result.app_groups;
                            el.app_group_users = result.app_group_users;
                            el.event_small_aux = result.event_small_aux;
                            el.event_small_aux_data = result.event_small_aux_data;
                        }
                        return el;
                    })
                    if (!MenuExists) {
                        var GeneralSettings = {
                            eventId: eventId,
                            event_home_page: result.event_home_page,
                            event_features: result.event_features,
                            feature_masters: result.feature_masters,
                            app_group_connections: result.app_group_connections,
                            app_groups: result.app_groups,
                            app_group_users: result.app_group_users,
                            event_small_aux: result.event_small_aux,
                            event_small_aux_data: result.event_small_aux_data,
                        }
                        GetEventsUserData.push(GeneralSettings);
                    }
                } else {
                    GetEventsUserData = [{
                        eventId: eventId,
                        event_home_page: result.event_home_page,
                        event_features: result.event_features,
                        feature_masters: result.feature_masters,
                        app_group_connections: result.app_group_connections,
                        app_groups: result.app_groups,
                        app_group_users: result.app_group_users,
                        event_small_aux: result.event_small_aux,
                        event_small_aux_data: result.event_small_aux_data,
                    }]
                }
                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));

                return res;
            }
        }).catch(error => {});
    }

    getMaterialList(eventID, constData) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method": "getMaterialList",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_materials": "",
                    "event_material_connection": "",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": eventID
                },
                "jsonrpc": REACT_APP_JSON_RPC
            },
        };

        return axios({
            url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => {

            let result = res.data !== undefined ? res.data[0].result : "";

            if (result.code !== undefined && result.code === 200) {
                var SponsorExists = false;
                if (GetEventsUserData !== null) {
                    GetEventsUserData = GetEventsUserData.map(function (el) {
                        if (el.eventId === eventID) {
                            SponsorExists = true;
                            el.EventMaterials = result.event_materials;
                            el.EventMaterialsConnection = result.event_material_connection;
                        }
                        return el;
                    })
                    if (!SponsorExists) {
                        var agendasLists = {
                            eventId: eventID,
                            EventMaterials: result.event_materials,
                            EventMaterialsConnection: result.event_material_connection,
                        }
                        GetEventsUserData.push(agendasLists);
                    }
                } else {
                    GetEventsUserData = [{
                        eventId: eventID,
                        EventMaterials: result.event_materials,
                        EventMaterialsConnection: result.event_material_connection,
                    }]
                }
                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
            }
            return res;
        }).catch(error => {});
    }

    getSponsorList(eventID, constData) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method": "getSponsorList",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_sponsors": "",
                    "event_agenda_sponsors": "",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": eventID
                },
                "jsonrpc": REACT_APP_JSON_RPC
            },
        };

        return axios({
            url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => {
            let result = res.data !== undefined ? res.data[0].result : "";
            if ( result.code !== undefined && Number( result.code ) === Number( 200 ) ) {
                var SponsorExists = false;
                if ( GetEventsUserData !== null ) {
                    GetEventsUserData = GetEventsUserData.map(function (el) {
                        if ( Number( el.eventId ) === Number( eventID ) ) {
                            SponsorExists = true;
                            el.EventSponsors = result.event_sponsors;
                            el.EventAgendaSponsors = result.event_agenda_sponsors;
                        }
                        return el;
                    })
                    if (!SponsorExists) {
                        var agendasLists = {
                            eventId: eventID,
                            EventSponsors: result.event_sponsors,
                            EventAgendaSponsors: result.event_agenda_sponsors,
                        }
                        GetEventsUserData.push(agendasLists);
                    }
                } else {
                    GetEventsUserData = [{
                        eventId: eventID,
                        EventSponsors: result.event_sponsors,
                        EventAgendaSponsors: result.event_agenda_sponsors,
                    }]
                }
                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
            }

            return res;
        }).catch(error => {});
    }

    getSpeakerList( eventID, constData ) {

        if(!constData) {
            return null;
        }

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        const data = {
            url: constData.ApiBaseUrl+'/webapp/index.php',
            method: "POST",
            post_fields: {
                "id": getRandomNumber,
                "method":"getSpeakerList",
                "params": {
                    "app_version": REACT_APP_VERSION,
                    "event_people":"",
                    "event_agenda_people":"",
                    "pkg_nm": REACT_APP_PKG_NAME,
                    "lang_id": REACT_APP_LANG_ID,
                    "event_id": eventID,
                },
                "jsonrpc": REACT_APP_JSON_RPC
            },
        };

        return axios({
            url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => {
            let result = res.data !== undefined ? res.data[0].result : "";

            if (result.code !== undefined && result.code === 200) {
                this.setState({SpeakersData: result.event_people});
                var SpeakerExists = false;

                if( GetEventsUserData !== null ){
                    GetEventsUserData = GetEventsUserData.map(function(el) {
                        if( el.eventId === eventID) {
                            SpeakerExists = true;
                            el.SpeakersData = result.event_people;
                        }
                        return el;
                    })
                    if( !SpeakerExists ){
                        var speakersLists = {
                            eventId: eventID,
                            SpeakersData: result.event_people
                        }
                        GetEventsUserData.push(speakersLists);
                    }
                }else {
                    GetEventsUserData = [{
                        eventId: eventID,
                        SpeakersData: result.event_people
                    }]
                }
                ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
            }

            return res;
        }).catch(error => {});
    }

    render() {
        return null;
    }
}

export default ManageAPICall;