import React from 'react';
import blank from '../../assets/media/avatars/blank.png';
import ls from 'localstorage-slim';

const {REACT_APP_HOME_URL} = process.env;

const AttendeeCard = (props) => {
    ls.config.encrypt = true;
    const {attendee, constData} = props;

    var spekerFirstName = attendee.fname ? attendee.fname : '';
    var spekerLastName = attendee.lname ? attendee.lname : '';
    var salutation = attendee.salut ? attendee.salut : '';
    var speaker_image = blank;

    let displayName = salutation+' '+spekerFirstName+' '+spekerLastName

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {
            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <a key={attendee.profile_id} href={REACT_APP_HOME_URL+"/attendees-details"} onClick={() => {
            ls.set("attendeeId",attendee.profile_id);
            let path_name = window.location.pathname;
            if( window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ) {
                path_name = REACT_APP_HOME_URL+'/attendees';
            }
            ls.remove("parent_page");
            ls.set("parent_page", path_name);
        }} className={"col d-flex align-items-center mb-10 " + attendee.profile_id}>
            <div className="symbol symbol-70px symbol-circle me-5">
                <img src={(attendee.pic && null !== constData) ? constData.BaseUrl +"/uploads/account/profile/" +  attendee.pic : speaker_image} className="" alt={spekerFirstName + ' ' + spekerLastName}/>
            </div>
            <div className="flex-grow-1">
                <span className="text-dark fw-bolder text-hover-primary fs-6" title={displayName}>{manageDisplayTitle(displayName)}</span>
                <span className="text-muted d-block fw-bold" title={attendee.job_title ? attendee.job_title : ''}>{attendee.job_title ? manageDisplayTitle(attendee.job_title) : ''}</span>
            </div>
        </a>
    );
};

export default AttendeeCard;