import React, { Component } from 'react';
import axios from 'axios';
import ls from 'localstorage-slim';
import {getRandomNumber} from '../getRandomNumber';

const {
    PUBLIC_URL,
    REACT_APP_LOCAL_ACCESS,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC
} = process.env;

class AboutData extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);

        this.state = {
            data: [],
            code: 400,
            eventId: ls.get('eventId'),
            EventAboutData: [],
            count: 0,
            constData: props.constData,
        }
    }

    componentDidMount() {
        ls.config.encrypt = true;

        let constData = this.state.constData;

        const isLocalAccess = REACT_APP_LOCAL_ACCESS;
        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        if( GetEventsUserData === null && Number(isLocalAccess) === 0 ) {

            if( '' !== constData && null !== constData ) {

                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "getAboutSectionList",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_about_sections": "",
                            "section_icons": "",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "event_id": eventId
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {
                    let result = res.data !== undefined ? res.data[0].result : "";
                    if (undefined !== result && "" !== result && undefined !== result.code && result.code === 200) {
                        this.setState({EventAboutData: result.event_about_sections});
                        var SpeakerExists = false;
                        if( GetEventsUserData !== null ){
                            GetEventsUserData = GetEventsUserData.map(function(el) {
                                if( el.eventId === eventId) {
                                    SpeakerExists = true;
                                    el.EventAboutData = result.event_about_sections;
                                }
                                return el;
                            })
                            if( !SpeakerExists ){
                                var agendasLists = {
                                    eventId:eventId,
                                    EventAboutData: result.event_about_sections,
                                }
                                GetEventsUserData.push(agendasLists);
                            }
                        }else {
                            GetEventsUserData = [{
                                eventId:eventId,
                                EventAboutData: result.event_about_sections,
                            }]
                        }
                        ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                    }
                }).catch(error => {});
            }
        } else {
            if( undefined !== GetEventsUserData ){
                var EventAboutData = [];
                GetEventsUserData.map(function(el) {
                    if( el.eventId === eventId){
                        EventAboutData = el.EventAboutData;
                    }
                    return el;
                })
                this.setState({
                    EventAboutData: EventAboutData,
                })
            }
        }
    }

    OpenInNew = () => { }

    AboutStructure = () => {
        let EventAboutData = this.state.EventAboutData;
        let Count = 0;
        ls.config.encrypt = true;
        if(undefined !== EventAboutData && "" !== EventAboutData){
            return(
                EventAboutData.length > 0 ? EventAboutData.map((About, index)=> {
                    if( About.status === "A" ) {
                        var about_section_id = About.about_section_id !== '' ? About.about_section_id : '';
                        Count++;
                        var HeaderShow = Count === 1 ? "" : "fw-bold collapsed";
                        var BodyShow = Count === 1 ? "show" : "";
                        var Expanded = Count === 1 ? 'true' : 'false';
                        var AboutDescription = About.description ? JSON.parse(About.description) : "";
                        return (
                            <div key={About.about_section_id} className="accordion-item">
                                <h2 className="accordion-header" id={"kt_accordion_1_header_"+about_section_id}>
                                    <button className={"accordion-button fs-4 "+HeaderShow} type="button" data-bs-toggle={ Number(About.fk_about_type_id) === 4 ? '' : "collapse"}
                                            data-bs-target={"#kt_accordion_1_body_"+about_section_id} aria-expanded={Expanded}
                                            aria-controls={"kt_accordion_1_body_"+about_section_id}>

                                        {Number(About.fk_about_type_id) === 4 && AboutDescription.web_link !== '' ? <a href={AboutDescription.web_link} target={"_blank"} rel="noreferrer"> {About.about_title} </a> : About.about_title}
                                    </button>
                                </h2>
                                <div id={"kt_accordion_1_body_"+about_section_id} className={"accordion-collapse collapse "+ BodyShow }
                                         aria-labelledby={"kt_accordion_1_header_"+about_section_id} data-bs-parent="#kt_accordion_1">
                                    <div className="accordion-body">
                                        { AboutDescription !== '' ? this.Description( About.fk_about_type_id, AboutDescription, index) : "" }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return '';
                }) : "No information available"
            )
        }
    }

    Description = ( type, Content, index ) => {
        if( Number(type) === 1 && Content.description !== ''){
            return (  <div dangerouslySetInnerHTML={{__html: Content.description}} /> )
        } else if ( Number(type) === 2 ){
            var LocationAddress = Content.location_address !== undefined ? Content.location_address : "";
            var LocationName = Content.location_name !== undefined ? Content.location_name : "";
            var LocationPhone = Content.location_phone !== undefined ? Content.location_phone : "";
            return ( <div className={"Address"}>
                <address>
                    {LocationAddress}<br />
                    {LocationName}<br />
                    {LocationPhone}
                </address>
            </div> )
        } else if ( Number(type) === 3 && Content.web_link !== '' ){
            return(<iframe title={"iframe_" + type + "_" + index} src={Content.web_link} border="0" width="100%" height="500"></iframe>);
        } else if ( Number(type) === 4 && Content.web_link !== '' ){
            return (<a href={Content.web_link} target={"_blank"} rel="noreferrer">{Content.web_link}</a>)
        }

    }
    render() {
        return (
            this.AboutStructure()
        );
    }
}
export default AboutData;