import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import SpeakersData from "../api/getSpeakers";
import ls from 'localstorage-slim';
import PageTitle from "../template/PageTitle";

class SpeakersList extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)

        this.state = {
            constData: props.constData,
            content: <SpeakersData constData={props.constData} update={true} FeatureId={"4"} activePage={1} perPage={'24'} PaginateSpeakers={this.handlePaginateSpeakersList} Pagination={true}/>,
            ActivePage: 1,
            perPage: 24,
            search : '',
            order : 'a-to-z'
        }
    }

    handleSearchSpeakersList = (e) => {
        var SearchSpeaker = e.target.value;
        SearchSpeaker = SearchSpeaker.replace(/[^a-zA-Z0-9\-\.\@\s]/, "");
        this.setState({
            search: SearchSpeaker,
            ActivePage: 1,
            content : this.renderSpeakersData(SearchSpeaker),
        })
    }

    handleOrderChange = (e) => {
        var SpeakerOrder = e.target.value;
        var SearchSpeaker = this.state.search;
        this.setState({
            order : SpeakerOrder,
            search: SearchSpeaker,
            ActivePage: 1,
            content : this.renderSpeakersData(SearchSpeaker,1 , SpeakerOrder),
        })
    }

    renderSpeakersData( search, ActivePage= 1, order ){
        var SearchSpeaker = search ? search : '';
        return (
            <SpeakersData constData={this.props.constData} update={true} FeatureId={"4"} activePage={ActivePage} perPage={this.state.perPage} searchSpeaker={SearchSpeaker} SpeakerOrder={order} PaginateSpeakers={this.handlePaginateSpeakersList} Pagination={true} />
        )
    }

    handlePaginateSpeakersList = (e) => {
        if( e !== undefined){
            this.setState({
                ActivePage: e,
                content : this.renderSpeakersData(this.state.search,e),
            })
        }else{
            this.setState({
                ActivePage: 1,
                content : this.renderSpeakersData(this.state.search,1),
            })
        }
    }

    render(){
        return (
            <>
                <SideMenu constData={this.props.constData}/>
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.props.constData}/>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-3 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.props.constData} DefaultTitle="Speakers"/></h1>
                                </div>
                                <div className="card-toolbar my-1 d-flex flex-wrap">
                                    <div className="d-flex align-items-center position-relative my-1 me-4">
                                        <span className="svg-icon svg-icon-3 position-absolute ms-3"><i className="bi bi-search"></i></span>
                                        <input type={"text"} id={"kt_filter_search"} value={this.state.search} onChange={this.handleSearchSpeakersList} className={"form-control form-select-sm w-250px ps-9"} placeholder={"Search Speakers"} style={{border: "none"}}/>
                                    </div>
                                    <div className="me-4 my-1">
                                        <select id="kt_filter_orders" name="orders" data-control="select2" data-hide-search="true" className={"w-125px form-select form-select-sm"} style={{border: "none"}} onChange={this.handleOrderChange} value={this.state.order}>
                                            <option value="a-to-z" selected="selected">A to Z</option>
                                            <option value="Newest">Newest</option>
                                            <option value="Oldest">Oldest</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {this.state.content}
                        </div>
                    </div>
                    <Footer constData={this.props.constData}/>
                </div>
            </>
        )
    }
}

export default SpeakersList;