import React from "react";
import { useTranslation} from "react-i18next";
import ls from "localstorage-slim";

const {REACT_APP_HOME_URL} = process.env;

const EventMenu = (props) => {

    ls.config.encrypt = true;

    const { t } = useTranslation();
    const {handlePastEvents,handleUpcomingEvents,ActiveTab, constData} = props;

    var upcomingEvent = '';
    var pastEvent = 'active';
    if( ActiveTab !== '' && ActiveTab === 'upcomingEvent' ){
        upcomingEvent = 'active';
        pastEvent = '';
    }

    const handleLogout = () => {

        if( null != constData ) {
            ls.config.encrypt = true;

            ls.remove("PreLoginSession");
            ls.remove("PreLoginUserProfile");
            ls.remove("userEmail");
            ls.remove("login_status");
            ls.remove("user_login_status");
            ls.remove("eventId");
            ls.remove("eventTitle");

            var eventId = ls.get('eventId');
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            if (GetEventsUserData !== undefined && GetEventsUserData !== null) {

                GetEventsUserData = GetEventsUserData.map(function (el) {

                    if (el.eventId === eventId) {
                        delete el.UserProfile;
                        delete el.userEmail;
                        delete el.UserLoginSession;
                    }

                    return el;
                });

                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
            }

            var eventRedirectURl = ('P' === constData.LoginType) ?  REACT_APP_HOME_URL + '/login' : REACT_APP_HOME_URL + '/';

            setTimeout(function () {
                window.location.href = eventRedirectURl;
            }, 1000);
        }
    }

    const LogoutButton = () => {

        const isLogin = ls.get('login_status');

        if( undefined !== constData.LoginType && 'P' === constData.LoginType && isLogin === "success") {

            return (
                <li className="nav-item mt-2">
                    <a href="#logout"
                       className={"nav-link text-active-primary ms-0 me-10 py-5"}
                       onClick={handleLogout}
                    >{t('Logout')}</a>
                </li>
            );
        }

        return "";
    }

    return (
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
            <li className="nav-item mt-2">
                <a href="#upcoming"
                   className={"nav-link text-active-primary ms-0 me-10 py-5 " + upcomingEvent}
                   data-kt-countup-tabs="true"
                   data-bs-toggle="tab"
                   data-lists-nav={true}
                   onClick={handleUpcomingEvents}
                >{t('Upcoming')}</a>
            </li>
            <li className="nav-item mt-2">
                <a href="#past"
                   className={"nav-link text-active-primary ms-0 me-10 py-5 " + pastEvent}
                   data-kt-countup-tabs="true"
                   data-bs-toggle="tab"
                   data-lists-nav={true}
                   onClick={handlePastEvents}
                >{t('Past')}</a>
            </li>
            <LogoutButton/>
        </ul>
    );
};

export default EventMenu;
