import React from 'react';
import ls from 'localstorage-slim';
import MaterialCard from "./material-card";

const RelatedMaterialsTemplate = (props) => {
    ls.config.encrypt = true;
    const {RelatedMaterials, constData} = props;

    const GetRelatedMaterials = () => {
        if(RelatedMaterials !== undefined && RelatedMaterials !== null && RelatedMaterials.length > 0 ){
            return(
                RelatedMaterials.map( (Material, index)=> {
                    return (
                        <MaterialCard constData={constData} key={index} Material={Material} />
                    )
                })
            );
        } else {
            return "Materials not available";
        }
    }

    return (
        GetRelatedMaterials()
    );
};

export default RelatedMaterialsTemplate;