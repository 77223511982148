import React from "react";
import { useTranslation } from "react-i18next";
import "../css/loader.css";
const {REACT_APP_HOME_URL} = process.env;

export default function LoadingSpinner() {
    const { t } = useTranslation();

    let currentPath  = window.location.pathname;
    if( currentPath === REACT_APP_HOME_URL+'/dashboard' || currentPath === REACT_APP_HOME_URL+'/' || currentPath === REACT_APP_HOME_URL+'/login' ) {
        let spinnerHide = '';
        if( currentPath !== REACT_APP_HOME_URL+'/dashboard' ) {
            spinnerHide = 'd-none';
        }

        return (
            <div className="spinner-container" id={"spinner_container"}>
                <div className="loading-spinner"></div>
                <div className={`loader-text ${spinnerHide}`}>
                    { window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ? <p>{t("Please wait while we are fetching event details.")}</p> : "" }
                </div>
            </div>
        );
    }

    return null;
}