import React from 'react';
import {useTranslation} from "react-i18next";
import ls from 'localstorage-slim';
import { useNavigate } from "react-router-dom";

const BackButton = (props) => {
    ls.config.encrypt = true;
    const { t } = useTranslation();

    const goToPreviousPath = useNavigate();

    return (
        <a href="#back" className={"btn btn-primary btn-sm"} onClick={() => goToPreviousPath(-1)} > {t("Back")} </a>
    );
};

export default BackButton;