import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import AgendaData from "../api/get-agenda";
import { withTranslation } from 'react-i18next';
import PageTitle from "../template/PageTitle";

class VirtualSession extends Component {

    constructor(props){
        super(props);

        this.state = {
            constData: props.constData,
            DefaultTitle: "Virtual Sessions",
        }
    }

    render(){
        const { t } = this.props;

        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle={this.state.DefaultTitle}/></h1>
                                    <span className="fw-bold opacity-50">{t("session times are in EDT")}</span>
                                </div>
                            </div>
                            <div className="row g-5 g-xl-8">
                                <AgendaData constData={this.state.constData} VirtualSession={true} />
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default withTranslation()(VirtualSession);