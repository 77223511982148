import React from 'react';
import ls from 'localstorage-slim';
import ReactTimeAgo from 'react-time-ago';
import en_in from 'javascript-time-ago/locale/en-IN.json';
import PdfIcon from "../../assets/media/svg/files/pdf.svg";
import DocIcon from "../../assets/media/svg/files/doc.svg";

const {REACT_APP_URL, REACT_APP_API_URL} = process.env;

const MaterialCard = (props) => {
    ls.config.encrypt = true;
    const {Material, constData} = props;

    var MaterialIconType = Material.type !== undefined ? Material.type : 'doc';
    MaterialIconType = MaterialIconType.replace('X','');
    var MaterialIcon = DocIcon;
    if( MaterialIconType.toLowerCase() === 'pdf' ){
        MaterialIcon = PdfIcon;
    }

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {
            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <div key={Material.material_id} className="col mb-5">
            <a target={"_blank"} href={ (null !== constData ) ? constData.BaseUrl + '/uploads/materials/' + Material.file : "" } rel="noreferrer">
                <div className="d-flex align-items-center me-2">
                    <div className="symbol symbol-70px me-3">
                        <div className="symbol-label bg-light p-4">
                            <img alt={"Icon"} src={ MaterialIcon } className={"img-fluid"} />
                        </div>
                    </div>
                    <div>
                        <div className="fs-5 text-gray-800 text-hover-primary fw-bolder" title={Material.title}>{manageDisplayTitle(Material.title, 35)}</div>
                        <div className="fs-7 text-muted fw-bold mt-1"><ReactTimeAgo date={new Date(Material.modified.replace(/\s/, 'T'))} locale={en_in.locale}/></div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default MaterialCard;