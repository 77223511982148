import React from 'react';
import {useTranslation} from "react-i18next";
import ls from 'localstorage-slim';
import AgendaItem from "../template/agenda-item";

const ScheduleList = (props) => {

    const {constData} = props;

    const { t } = useTranslation();
    ls.config.encrypt = true;
    const {agenda,EventAgendaCategories,EventAgendaLocations,AgendaLocations,EventCategories,Dashboard} = props;
    var activeClass = "";
    if(props.activeTab === agenda.agendaDateKey ){
        activeClass = "show active";
    }

    const agendaItemHandler = (agenda) =>{
        var AgendaData = agenda.agendaData
        AgendaData.sort((a, b) =>new Date(a.start_time.replace(/\s/, 'T')) - new Date(b.start_time.replace(/\s/, 'T')))
        return(
            agenda.agendaData.map((agendaItem) => {
                if( agendaItem !== undefined && agendaItem.status !== '' && agendaItem.status === 'A' ){
                    return(
                        <AgendaItem constData={constData} key={agendaItem.agenda_id} Dashboard={Dashboard} EventAgendaCategories={EventAgendaCategories} EventAgendaLocations={EventAgendaLocations} EventCategories={EventCategories} AgendaLocations={AgendaLocations} agendaItem={agendaItem} t={t} />
                    )
                }
                return '';
            })
        )
    }

    return (
        <div key={agenda.agendaDateKey} id={"kt_schedule_day_"+agenda.agendaDateKey} className={"tab-pane fade " + activeClass}>
            <div className="card-body pt-6 p-0">
                <div className="timeline-label">
                    {agendaItemHandler( agenda )}
                </div>
            </div>
        </div>
    );
};

export default ScheduleList;