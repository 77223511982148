import React from 'react';
import blank from '../../assets/media/avatars/blank.png';
import ls from 'localstorage-slim';
const {REACT_APP_HOME_URL} = process.env;

const SpeakerCard = (props) => {

    const {speaker, constData} = props;

    ls.config.encrypt = true;

    var spekerFirstName = speaker.first_name ? speaker.first_name : '';
    var spekerLastName = speaker.last_name ? speaker.last_name : '';
    var salutation = speaker.salutation ? speaker.salutation : '';
    var companyName = speaker.company_name ? speaker.company_name : '';
    var speakerId = speaker.person_id ? speaker.person_id : '';
    if( undefined === speakerId || speakerId === '' ){
        speakerId = speaker.speakerId ? speaker.speakerId : '';
    }

    var speaker_image = blank;

    let displayName = salutation+' '+spekerFirstName+' '+spekerLastName;

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {

            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <a href={REACT_APP_HOME_URL+"/speaker-details"} onClick={() => {
            let path_name = window.location.pathname;
            if( window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ) {
                path_name = REACT_APP_HOME_URL+'/speakers';
            }
            ls.set("speakerId",speakerId);
            ls.remove("parent_page");
            ls.set("parent_page", path_name);
        }
        } className={"col d-flex align-items-center mb-10 " + speakerId}>
            <div className="symbol symbol-70px symbol-circle me-5">
                <img src={(speaker.person_image && (null !== constData && constData.BaseUrl)) ? constData.BaseUrl + "/uploads/speaker/" + speaker.person_image : speaker_image} className="" alt={spekerFirstName + ' ' + spekerLastName}/>
            </div>
            <div className="flex-grow-1 speaker-details">
                <span className="text-dark fw-bolder text-hover-primary fs-6" title={displayName}>{manageDisplayTitle(displayName, 15)}</span>
                <span className="text-muted d-block fw-bold" title={speaker.job_title ? speaker.job_title : ''}>{speaker.job_title ? manageDisplayTitle(speaker.job_title, 15) : ''}</span>
                <span className="text-muted d-block fw-bold" title={companyName ? companyName : ''}>{companyName ? manageDisplayTitle(companyName, 15) : ''}</span>
            </div>
        </a>
    );
};

export default SpeakerCard;