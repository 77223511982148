import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ls from 'localstorage-slim';
import AgendaCard from "../template/agenda-card";
import BackButton from "../template/backbutton";
import RelatedSpeakersTemplate from "../template/related-speakers-template";
import RelatedMaterialsTemplate from "../template/related-materials-template";
import PageTitle from "../template/PageTitle";
import GetSponsors from "../api/get-sponsors";

class ScheduleDetails extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)
        var eventId = ls.get("eventId") !== null ? ls.get("eventId") : '';
        var agendaId = ls.get("agendaId") !== null ? ls.get("agendaId") : '';
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var agendaDetail = [];
        var AgendaLocationsIds = [];
        var AgendaLocations = [];
        var AgendaCategoriesIds = [];
        var AgendaCategories = [];
        var EventAgendaPeopleIds = [];
        var EventAgendaPeoples = [];
        var RelatedMaterialsIds = [];
        var RelatedMaterials = [];
        var RelatedSponsorsIds = [];
        var RelatedSponsors = [];
        var event_small_aux = '';
        var event_small_aux_data = '';
        var biodata_id = '';
        var EventAgendaLive = false;
        var EventLiveQaAndPoll = false;
        var biodata = '';
        var aux_fields_ids = [];
        var aux_fields = [];

        if(undefined !== GetEventsUserData && null !== GetEventsUserData){
            GetEventsUserData.some(function(el) {
                if(el.eventId === eventId){
                    event_small_aux = el.event_small_aux;
                    event_small_aux_data = el.event_small_aux_data;

                    if( undefined !== el.EventAgendaLiveSettings && "" !== el.EventAgendaLiveSettings ){
                        el.EventAgendaLiveSettings.map(function(LiveSetting) {
                            if( agendaId === LiveSetting.fk_agenda_id && LiveSetting.status === "A" ){
                                EventAgendaLive = true;
                            }
                        });
                    }

                    if( EventAgendaLive && undefined !== el.LiveSessionPreference && "" !== el.LiveSessionPreference ){
                        el.LiveSessionPreference.map(function(LiveSetting) {
                            if( agendaId === LiveSetting.fk_agenda_id && LiveSetting.open_close.toLowerCase() === "o" && LiveSetting.status === "A" ){
                                EventLiveQaAndPoll = true;
                            }
                        });
                    }

                    if( el.AgendasData !== '' && el.AgendasData !== undefined ){
                        el.AgendasData.some(function(agenda) {
                            if( agendaId !== '' && agendaId !== undefined && agenda.agenda_id === agendaId && agenda.status === 'A' ) {
                                agendaDetail = agenda;
                            }
                        })
                    }

                    if( el.EventAgendaLocations !== '' && el.EventAgendaLocations !== undefined ){
                        AgendaLocationsIds = [];

                        el.EventAgendaLocations.some(function(LocationsIds) {
                            if( agendaId !== '' && agendaId !== undefined && LocationsIds.fk_agenda_id === agendaId && LocationsIds.status === 'A' ) {
                                AgendaLocationsIds.push(LocationsIds.fk_location_id);
                            }
                        })
                        if( el.AgendaLocations !== '' && el.AgendaLocations !== undefined ){
                            AgendaLocations = [];
                            el.AgendaLocations.map(function(LocationsIds) {
                                AgendaLocationsIds.some(function(LocationId) {
                                    if (LocationsIds !== '' && LocationsIds !== undefined && LocationsIds.location_id === LocationId) {
                                        AgendaLocations.push(LocationsIds.location_name);
                                    }
                                })
                                return LocationsIds;
                            })
                        }
                    }

                    if( el.EventAgendaCategories !== '' && el.EventAgendaCategories !== undefined ){
                        AgendaCategoriesIds = [];
                        el.EventAgendaCategories.some(function(CategoriesIds) { if( agendaId !== '' && agendaId !== undefined && CategoriesIds.fk_agenda_id === agendaId && CategoriesIds.status === 'A' ) {
                            AgendaCategoriesIds.push(CategoriesIds.fk_category_id);
                        }})
                        if( el.EventCategories !== '' && el.EventCategories !== undefined ){
                            AgendaCategories = [];
                            el.EventCategories.map(function(CategoriesIds) {
                                AgendaCategoriesIds.some(function(CategoriesId) {
                                    if (CategoriesIds !== '' && CategoriesIds !== undefined && CategoriesIds.category_id === CategoriesId) {
                                        var categoryData = {
                                            categoryId: CategoriesIds.category_id,
                                            categoryName: CategoriesIds.category_name,
                                            categoryColor: CategoriesIds.category_color_code,
                                        }
                                        AgendaCategories.push(categoryData);
                                    }
                                })
                                return CategoriesIds;
                            })
                        }
                    }

                    if( undefined !== event_small_aux && '' !== event_small_aux ){
                        event_small_aux.map(( small_aux ) =>{
                            if( small_aux.fk_event_id === eventId && ( small_aux.fk_feature_id === "1" || small_aux.fk_feature_id === "1001" || small_aux.fk_feature_id === "1002" || small_aux.fk_feature_id === "1003" || small_aux.fk_feature_id === "1004" || small_aux.fk_feature_id === "1005") && small_aux.status === "A" ){
                                if( small_aux.fk_field_type_id === "19" ){
                                    biodata_id = small_aux.small_field_id;
                                } else {
                                    var data = {
                                        small_field_id: small_aux.small_field_id,
                                        field_name: small_aux.field_name,
                                        small_field_type: small_aux.fk_field_type_id
                                    }
                                    aux_fields_ids.push(data);
                                }
                            }
                        });
                    }

                    if( undefined !== event_small_aux_data && '' !== event_small_aux_data ){
                        event_small_aux_data.map( ( aux_data ) => {
                            if( aux_data.fk_record_id === agendaId && aux_data.status === "A" ) {
                                if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === biodata_id) {
                                    biodata = aux_data.value
                                } else if (aux_fields_ids !== undefined && aux_fields_ids.length > 0) {
                                    aux_fields_ids.map((aux_field) => {
                                        if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === aux_field.small_field_id) {
                                            var aux_field_data = {
                                                field_type: aux_field.small_field_type,
                                                field_name: aux_field.field_name,
                                                field_value: aux_data.value
                                            }
                                            aux_fields.push(aux_field_data);
                                        }
                                    });
                                }
                            }
                        });
                    }

                    if( el.EventAgendaPeople !== '' && el.EventAgendaPeople !== undefined ){
                        EventAgendaPeopleIds = [];
                        el.EventAgendaPeople.some(function(PeopleIds) { if( agendaId !== '' && agendaId !== undefined && PeopleIds.fk_agenda_id === agendaId && PeopleIds.status === 'A' ) {
                            EventAgendaPeopleIds.push(PeopleIds.fk_person_id);
                        }})
                        if( el.SpeakersData !== '' && el.SpeakersData !== undefined ){
                            EventAgendaPeoples = [];
                            el.SpeakersData.map(function(Speaker) {
                                EventAgendaPeopleIds.some(function(People) {
                                    if (Speaker !== '' && Speaker !== undefined && Speaker.person_id === People) {
                                        var AgendaSpeakerData = {
                                            speakerId: Speaker.person_id,
                                            first_name: Speaker.first_name,
                                            last_name: Speaker.last_name,
                                            job_title: Speaker.job_title,
                                            person_image: Speaker.person_image,
                                        }
                                        EventAgendaPeoples.push(AgendaSpeakerData);
                                    }
                                })
                                return Speaker;
                            })
                        }
                    }

                    if( el.EventAgendaSponsors !== '' && el.EventAgendaSponsors !== undefined ){
                        RelatedSponsorsIds = [];
                        el.EventAgendaSponsors.some(function(SponsorIds) {
                            if( agendaId !== '' && agendaId !== undefined && SponsorIds.fk_agenda_id === agendaId && SponsorIds.status === 'A' ) {
                                RelatedSponsorsIds.push(SponsorIds.fk_sponsor_id);
                            }
                        })

                        if( el.EventSponsors !== '' && el.EventSponsors !== undefined ){
                            RelatedSponsors = [];
                            el.EventSponsors.map(function(Sponsor) {
                                RelatedSponsorsIds.some(function(SponserId) {
                                    if (Sponsor !== '' && Sponsor !== undefined && Sponsor.sponsor_id === SponserId) {
                                        RelatedSponsors.push(Sponsor);
                                    }
                                })
                                return Sponsor;
                            })
                        }
                    }

                    if( el.EventMaterials !== '' && el.EventMaterials !== undefined ){
                        RelatedMaterialsIds = [];
                        if( undefined !== el.EventMaterialsConnection && null !== el.EventMaterialsConnection ){
                            el.EventMaterialsConnection.some(function(MaterialsIds) { if( agendaId !== '' && agendaId !== undefined && MaterialsIds.row_id === agendaId && MaterialsIds.fk_feature_id === "1" && MaterialsIds.status === 'A' ) {
                                RelatedMaterialsIds.push(MaterialsIds.fk_material_id);
                            }})
                        }
                        if( el.EventMaterials !== '' && el.EventMaterials !== undefined ){
                            RelatedMaterials = [];
                            el.EventMaterials.map(function(Material) {
                                if( undefined !== RelatedMaterialsIds && null !== RelatedMaterialsIds && RelatedMaterialsIds.length > 0 ){
                                    RelatedMaterialsIds.some(function(MaterialId) {
                                        if (Material !== '' && Material !== undefined && Material.material_id === MaterialId ) {
                                            var AgendaMaterialData = {
                                                material_id: Material.material_id,
                                                title: Material.title,
                                                file: Material.file,
                                                type: Material.type,
                                                created: Material.created,
                                                modified: Material.modified,
                                                biodata: biodata,
                                                aux_fields: aux_fields
                                            }
                                            RelatedMaterials.push(AgendaMaterialData);
                                        }
                                    })
                                }
                                return Material;
                            })
                        }
                    }
                }
            })
        }

        this.state = {
            agendaId: agendaId,
            eventId: eventId,
            agendaDetail: agendaDetail,
            AgendaCategories: AgendaCategories,
            AgendaLocations: AgendaLocations,
            RelatedSpeakers: EventAgendaPeoples,
            RelatedMaterials: RelatedMaterials,
            RelatedSponsors: RelatedSponsors,
            biodata: biodata,
            aux_fields: aux_fields,
            EventLiveQaAndPoll: EventLiveQaAndPoll,
            constData: this.props.constData
        }
    }

    render(){
        var AgendaLocations = this.state.AgendaLocations !== null ? this.state.AgendaLocations : '';
        var agendaDetail = this.state.agendaDetail !== null ? this.state.agendaDetail : '';
        var AgendaCategories = this.state.AgendaCategories !== null ? this.state.AgendaCategories : '';
        var RelatedSpeakers = this.state.RelatedSpeakers !== null ? this.state.RelatedSpeakers : '';
        var RelatedMaterials = this.state.RelatedMaterials !== null ? this.state.RelatedMaterials : '';
        var RelatedSponsors = this.state.RelatedSponsors !== null ? this.state.RelatedSponsors : '';

        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className={"wrapper d-flex flex-column flex-row-fluid agenda-"+ this.state.agendaId + " event-"+this.state.eventId} id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Schedule"/></h1>
                                    <span className="fw-bold opacity-50">(session times are in EDT)</span>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <BackButton constData={this.state.constData} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card-body pt-9 pb-0">
                                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                                <div className="flex-grow-1">
                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                        <AgendaCard constData={this.state.constData} EventLiveQaAndPoll={this.state.EventLiveQaAndPoll} biodata={this.state.biodata} aux_fields={this.state.aux_fields} agendaDetail={agendaDetail} AgendaLocations={AgendaLocations} AgendaCategories={AgendaCategories} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    <div className="card card-xl-stretch mb-xl-9">
                                        <div className="card-header">
                                            <h3 className="card-title fw-bolder text-dark">Speakers</h3>
                                        </div>
                                        <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                            <RelatedSpeakersTemplate constData={this.state.constData} RelatedSpeakers={RelatedSpeakers}/>
                                        </div>
                                    </div>
                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                        <div className="card-header cursor-pointer">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">Materials</h3>
                                            </div>
                                        </div>
                                        <div className="row card-body p-9 row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                            <RelatedMaterialsTemplate constData={this.state.constData} RelatedMaterials={RelatedMaterials}/>
                                        </div>
                                    </div>
                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                        <div className="card-header cursor-pointer">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">Sponsors</h3>
                                            </div>
                                        </div>
                                        <div id="sponsors" className="row card-body p-9 row-cols-2 row-cols-sm-3 row-cols-xl-4 gy-0">
                                            <GetSponsors constData={this.state.constData} FeatureId={"3"} RelatedSponsor={true} agendaId={this.state.agendaId} sponsorsLimit={50} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default ScheduleDetails;