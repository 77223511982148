import React from 'react';
import ReactDOM from 'react-dom/client';
import LoadingSpinner from "./components/template/loader";
import App from './App';
import TimeAgo from 'javascript-time-ago';
import en_in from 'javascript-time-ago/locale/en-IN.json';
import './i18nextConf';
import './assets/plugins/global/plugins.bundle.css';
import './assets/css/style.bundle.css';
import './assets/css/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const {REACT_APP_HOME_URL} = process.env;

TimeAgo.addDefaultLocale(en_in);
TimeAgo.setDefaultLocale(en_in.locale);
TimeAgo.addLocale(en_in);

root.render(
    <>
        <LoadingSpinner />
        <div className="d-flex flex-column flex-root">
            <div className={`page d-flex flex-column-fluid ${ window.location.pathname === REACT_APP_HOME_URL+'/login' ? "flex-column" : "flex-row"}`}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </div>
        </div>
    </>
);