import React from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../navbar";

const Footer = (props) => {

    const { t } = useTranslation();

    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-gray-400 fw-bold me-1">{t('Created by')}</span>
                    <a href="https://eventedge.co" target="_blank" rel="noopener noreferrer" className="text-muted text-hover-primary fw-bold me-2 fs-6">{t('EventEdge')}</a>
                </div>
                <Navbar constData={props.constData} />
            </div>
        </div>

    );
};

export default Footer;
