import React, { Component } from 'react';
import axios from 'axios';
import SignUpScreen from "../template/SignUpScreen";
import LoginVerificationScreen from "../template/LoginVerificationScreen";
import ls from 'localstorage-slim';
import {getRandomNumber} from '../getRandomNumber';

const {
    PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC
} = process.env;

class SendUserVerificationCode extends Component {

    constructor(props) {

        super(props);

        const {constData} = props;

        this.state = {
            data: [],
            code: 400,
            message: "",
            LoginSession: false,
            constData: constData
        }
    }

    async componentDidMount() {

        let constData = this.state.constData;

        if( null !== constData && '' !== constData ) {

            ls.config.encrypt = true;
            var eventId = ls.get("eventId");
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            var UserEmail = this.props.userEmail;
            if(constData.LoginType !== undefined && 'P' === constData.LoginType ){
                eventId = "";
                ls.set("userEmail", UserEmail);
            } else {
                var event_data = {"eventId": eventId, "UserEmail": UserEmail};
                if (GetEventsUserData !== null && GetEventsUserData.some(function (el) {
                    return el.eventId === eventId;
                })) {
                    GetEventsUserData.map(function (el) {
                        if (el.eventId === eventId) {
                            return el.UserEmail = UserEmail;
                        }
                        return el;
                    });

                } else if (GetEventsUserData !== null && GetEventsUserData.some(function (el) {
                    return el.eventId !== eventId;
                })) {
                    GetEventsUserData = GetEventsUserData.concat(event_data);
                } else {
                    GetEventsUserData = [event_data];
                }

                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
            }

            const data = {
                url: constData.ApiBaseUrl+'/post/index.php',
                method: "POST",
                post_fields: {
                    "id":getRandomNumber,
                    "method": "signUp",
                    "params": {
                        "email": UserEmail,
                        "app_version": REACT_APP_VERSION,
                        "pkg_nm": REACT_APP_PKG_NAME,
                        "lang_id": REACT_APP_LANG_ID,
                        "event_id":eventId
                    },
                    "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {
                let result = res.data !== undefined ? res.data : "";

                if(result.hasOwnProperty('error')){
                    this.setState({
                        message:result.error.message
                    });
                }else if (undefined !== result && "" !== result && result.hasOwnProperty('result') && result.result.code === 200) {
                    this.setState({
                        LoginSession:true,
                        AccessToken: result.result.access_token,
                    });
                }

            }).catch(error => {
                this.setState({
                    message:error
                });
            });
        }
    }

    render(){
        return (
             this.state.LoginSession ? <LoginVerificationScreen
                 CreateFlag={this.props.CreateFlag}
                 VerifyLogin={this.props.VerifyLogin}
                 handlePinChange={this.props.handlePinChange}
                 UserEmail={this.props.userEmail}
                 loginText={this.props.loginText}
                 pinText={this.props.pinText}
                 eventLogo={this.props.eventLogo}
                 constData={this.state.constData}
                 /> :
                 <SignUpScreen
                     SendVerificationCode={this.props.SendVerificationCode}
                     handleEmailChange={this.props.handleEmailChange}
                     UserEmail={undefined !== this.props.userEmail ? this.props.userEmail : ''}
                     errorMessage={this.state.message}
                     loginText={this.props.loginText}
                     pinText={this.props.pinText}
                     eventLogo={this.props.eventLogo}
                     constData={this.state.constData}
                 />
        )
    }
}
export default SendUserVerificationCode;