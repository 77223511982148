import React from 'react';
import ls from 'localstorage-slim';
import sponsor from '../../assets/media/sponsor.png';

const SponsorCard = (props) => {
    ls.config.encrypt = true;

    const {constData} = props;

    var baseUrl = (null !== constData ) ? constData.BaseUrl + '/uploads/sponsor/Thumbs/' : "";
    const {Sponsor} = props;
    var SponsorName = Sponsor.sponsor_name ? Sponsor.sponsor_name : '';
    var SponsorPhone = Sponsor.sponsor_phone ? Sponsor.sponsor_phone : '';
    var SponsorEmail = Sponsor.sponsor_email ? Sponsor.sponsor_email : '';
    var SponsorLink = Sponsor.sponsor_link ? Sponsor.sponsor_link : '';
    var SponsorDescription = Sponsor.sponsor_description ? Sponsor.sponsor_description : '';
    var SponsorImage = (Sponsor.sponsor_logo && baseUrl) ? baseUrl + Sponsor.sponsor_logo : sponsor;

    SponsorPhone = SponsorPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (SponsorPhone) {
        SponsorPhone = SponsorPhone[1] + '-' + SponsorPhone[2] + '-' + SponsorPhone[3];
    }

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {

            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <div key={Sponsor.sponsor_id} className={"col text-center mb-9 sponsor-"+Sponsor.sponsor_id+ " " + Sponsor.sponsor_order}>
            <a
                href="#modal"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
                data-url={encodeURI(SponsorLink)}
                data-description={encodeURI(SponsorDescription)}
                data-sponsor={encodeURI(SponsorName)}
                data-number={SponsorPhone}
                data-email={SponsorEmail}
                data-image={SponsorImage}
                className="text-dark fw-bolder open-sponsor-modal text-hover-primary fs-5"
            >
                <div className="d-flex flex-center mx-auto mb-2 sponsor-logo border">
                    <img src={SponsorImage} alt={SponsorName} />
                </div>

                <div className="mb-0">
                    {SponsorName !== "" ? <div className={"sponsor-name"} title={SponsorName}>{manageDisplayTitle(SponsorName, 50)}</div> : ""}
                    {SponsorPhone !== "" ? <div className="text-gray-600 fs-6 fw-bold">{SponsorPhone}</div> : ""}
                    {SponsorEmail !== "" ?
                        <div className="text-muted fs-6 fw-bold">
                            <a href={"mailto:"+SponsorEmail}>{SponsorEmail}</a>
                        </div> : ""}
                </div>
            </a>
        </div>
    );

};

export default SponsorCard;