import React from 'react';
import {useTranslation} from "react-i18next";
import blank from '../../assets/media/avatars/blank.png';
import {format} from "date-fns";
import ls from 'localstorage-slim';
import AuxFields from "../template/aux-field";
const {REACT_APP_HOME_URL} = process.env;

const AgendaCard = (props) => {
    ls.config.encrypt = true;
    const { t } = useTranslation();
    const {agendaDetail, AgendaCategories, AgendaLocations, biodata, aux_fields, EventLiveQaAndPoll, constData} = props;
    var agendaId = '';
    var AgendaDate = '';
    var agendaFeatured = blank;
    if(agendaDetail !== undefined){
        agendaId = agendaDetail.agenda_id !== undefined ? agendaDetail.agenda_id : '';
        agendaFeatured = agendaDetail.featured !== undefined && agendaDetail.featured !== '' ? agendaDetail.featured : '';
        AgendaDate = agendaDetail.start_time !== undefined ? format(new Date(agendaDetail.start_time.replace(/\s/, 'T')), 'MMM dd, yyyy') : '';
    }

    const agendaLocation = () => {
        var count = 1;
        if(AgendaLocations !== undefined && AgendaLocations !== null && Number( AgendaLocations.length ) > Number( 0 ) ){
            return(
                AgendaLocations.map( (Category)=> {
                    if( Category !== undefined && Category !== [] && Category !== null ){
                        if( count > 1 ) {
                            return ', ' + Category;
                        }
                        return Category;
                    }
                    return '';
                })
            );
        }
    }


    const agendaCategory = () => {
        if(AgendaCategories !== undefined && AgendaCategories !== null){
            return(
                AgendaCategories.map( (Category)=> {
                    return (
                        <div key={Category.categoryId} className="border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3">
                            <div className="fw-bold fs-6 text-gray-600">{Category.categoryName} </div>
                        </div>
                    )
                })
            );
        }
    }

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {

            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <div className={"d-flex flex-column agenda-" + agendaId}>
            <div className="d-flex align-items-center mb-2">
                <h2 title={agendaDetail.agenda_title !== undefined ? agendaDetail.agenda_title : ''}>{agendaDetail.agenda_title !== undefined ? manageDisplayTitle(agendaDetail.agenda_title, 50) : ''}</h2>
            </div>
            <div className="d-flex flex-column fw-bold fs-6 mb-2 pe-2 agenda-card-details">
                <span href="#" className="d-flex align-items-center text-gray-600 me-5 mb-2">
                    <i className="bi bi-calendar-week-fill"></i>{AgendaDate}</span>
                <span href="#" className="d-flex align-items-center text-gray-600 me-5 mb-2">
                    <i className="bi bi-alarm-fill"></i>{agendaDetail.start_time !== undefined ? format(new Date(agendaDetail.start_time.replace(/\s/, 'T')), 'hh:mm a') : ''} - {agendaDetail.end_time !== undefined ? format(new Date(agendaDetail.end_time), 'hh:mm a') : ''}</span>
                {Number( AgendaLocations.length ) > Number( 0 ) ? <span href="#" className="d-flex align-items-center text-gray-600 me-5 mb-2">
                    <i className="bi bi-geo-alt-fill"></i>{ agendaLocation() } </span> : ""}
                <div>
                    {agendaFeatured === 'Y' ? <span className="badge badge-info fs-8 fw-bolder my-2 me-2">{t('FEATURED')}</span> : ''}
                    {agendaDetail.show_broadcast === "1" && agendaDetail.broadcast_view_link !== undefined && agendaDetail.broadcast_view_link !== '' ?
                    <a href={REACT_APP_HOME_URL+"/schedule-watch-live"}>
                        <span className="badge badge-danger fs-8 fw-bolder my-2 me-2">
                            <i className="bi bi-play-circle-fill blink"></i> {("WATCH LIVE")}</span>
                    </a> : ""}
                    {EventLiveQaAndPoll ? <a href={REACT_APP_HOME_URL+"/live-qa-and-poll"}>
                        <span className="badge badge-danger fs-8 fw-bolder my-2 me-2">{("Live QA & poll")}</span>
                    </a> : "" }

                </div>
            </div>
            <div className="d-flex mb-3 flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                    <div className="d-flex flex-wrap">
                        {AgendaCategories.length > Number( 0 ) ? agendaCategory() : ""}
                    </div>
                </div>
            </div>
            <p className="fs-5 text-gray-900">{biodata}</p>
            <p><AuxFields constData={constData} aux_fields={aux_fields} /></p>
        </div>
    );
};

export default AgendaCard;