import React, { Component } from 'react';
import UpcomingEvents from '../template/UpcomingEvents';
import PastEvents from '../template/PastEvents';
import EventMenu from "../template/event-menu";
import Footer from "../template/footer";
import ls from "localstorage-slim";

const {REACT_APP_HOME_URL} = process.env;

class EventList extends Component {

    constructor(props){
        super(props);

        const {constData} = props;

        this.state = {
            activeTab: 'upcomingEvent',
            display_content: false,
            constData: constData,
            content: <UpcomingEvents constData={constData} />,
        }
    }

    handlePastEvents = () => {
        this.setState({
            content : <PastEvents constData={this.state.constData} />,
            activeTab: 'pastEvent'
        })
    }

    handleUpcomingEvents = () => {
        this.setState({
            content : <UpcomingEvents constData={this.state.constData}/>,
            activeTab: 'upcomingEvent'
        })
    }

    render() {

        ls.config.encrypt = true;
        let constData = this.state.constData;
        if( null !== constData && "" !== constData ) {

            const PreLoginSession = ls.get("PreLoginSession");
            document.body.classList.remove('bg-body');

            setTimeout(() => {

                if( ( constData.LoginType !== undefined && 'O' !== constData.LoginType && (PreLoginSession === undefined || PreLoginSession === null || PreLoginSession === '')) || ( undefined !== constData.Event_ID && "" !== constData.Event_ID ) ) {

                    var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
                    var EventLoginType = '';
                    var EventCount = 0;

                    if( ( undefined === GetEventsUserData || null === GetEventsUserData || GetEventsUserData.length === EventCount ) || ( constData.LoginType !== undefined && 'P' === constData.LoginType && ( PreLoginSession === null || PreLoginSession === '' ) ) || ( undefined !== constData.Event_ID && "" !== constData.Event_ID ) ){

                        var redirect_location = '/login';
                        EventLoginType = ls.get("eventType");
                        if( undefined !== EventLoginType && Number(2) === Number(EventLoginType) ){
                            redirect_location = '/dashboard';
                        }
                        window.location.href = REACT_APP_HOME_URL + redirect_location;
                    }
                }
            }, 1000);
        }

        return (
            <div className="d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="container-xxl" id="kt_content_container">
                        <div className="card mb-6">
                            <div className="card-body pt-9 pb-0">
                                <EventMenu
                                    ActiveTab={this.state.activeTab}
                                    handlePastEvents={this.handlePastEvents}
                                    handleUpcomingEvents={this.handleUpcomingEvents}
                                    constData={constData}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-wrap flex-stack mb-6">
                            <h3 className="fw-bolder my-2">Events</h3>
                        </div>
                        <div className="tab-content">
                            {this.state.content}
                        </div>
                    </div>
                </div>
                <Footer constData={constData}/>
            </div>
        )
    }
}

export default EventList;