import React, { Component }  from 'react';
import ls from 'localstorage-slim';
import { withTranslation } from 'react-i18next';
import Header from "../template/header";
import SideMenu from "../template/sideMenu";
import Footer from "../template/footer";
import BackButton from "../template/backbutton";
import axios from "axios";
import {getRandomNumber} from "../getRandomNumber";
import blank from "../../assets/media/avatars/blank.png";
import PageTitle from "../template/PageTitle";

const {
    PUBLIC_URL,
    REACT_APP_HOME_URL,
    REACT_APP_VERSION,
    REACT_APP_PF,
    REACT_APP_DEVICE_KEY,
    REACT_APP_JSON_RPC,
    REACT_APP_PKG_NAME,
    REACT_APP_DEVICE_ID,
    REACT_APP_URL,
    REACT_APP_API_URL
} = process.env;

class EditProfilePage extends Component {

    constructor(props){

        super(props)

        const {constData} = props;

        ls.config.encrypt = true;
        var eventId = ls.get("eventId");
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var UserLoginSession = '';
        var userEmail = '';
        var UserData = [];
        if(GetEventsUserData !== undefined && null !== GetEventsUserData &&  '' !== GetEventsUserData){
            var SpeakersData = [];
            GetEventsUserData.map(function (el) {
                if( Number( el.eventId ) === Number( eventId ) && el.UserProfile !== undefined){
                    UserLoginSession = el.UserLoginSession;
                    if( userEmail === undefined || userEmail === '' ){
                        userEmail = el.userEmail;
                    }
                    return UserData = el.UserProfile;
                }
                return el;
            });
        }

        let BaseUrl = REACT_APP_URL+REACT_APP_API_URL+'/';
        var userFirstName= UserData.fname !== undefined ? UserData.fname : '';
        var userLastName= UserData.lname !== undefined ? UserData.lname : '';
        var user_image = UserData.pic ? BaseUrl + "uploads/account/profile/Thumbs/" + UserData.pic : blank;

        this.state = {
            user: UserData,
            eventId: eventId,
            userEmail: userEmail,
            userFirstName: userFirstName !== undefined ? userFirstName : '',
            userLastName: userLastName !== undefined ? userLastName : '',
            salut: UserData.salut !== undefined ? UserData.salut : '',
            title: UserData.title !== undefined ? UserData.title : '',
            company: UserData.company !== undefined ? UserData.company : '',
            email: userEmail !== undefined ? userEmail : '',
            nname: UserData.nname !== undefined ? UserData.nname : '',
            phone: UserData.phone !== undefined ? UserData.phone : '',
            avatar: UserData.pic !== undefined ? UserData.pic : '',
            base64image: '',
            UserLoginSession : UserLoginSession,
            updateMessage: '',
            MessageColor: 'red',
            constData: props.constData,
            user_image: (UserData.pic && constData ) ? constData.BaseUrl + "uploads/account/profile/Thumbs/" + UserData.pic : blank,
        }
    }

    checkFieldRequired = () => {

        const eventId = ls.get("eventId");
        const GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        var fnameRequired = false;
        var lnameRequired = false;
        var salutationRequired = false;
        var nnameRequired = false;
        var jobtitleRequired = false;
        var companyRequired = false;
        var emailRequired = false;
        var phoneRequired = false;

        if(GetEventsUserData !== undefined && null !== GetEventsUserData && '' !== GetEventsUserData ) {
            GetEventsUserData.map(function (el) {
                if (Number(el.eventId) === Number(eventId) && el.acnt_profile_fields_settings !== undefined) {

                    el.acnt_profile_fields_settings.map( function (fields) {
                        if( fields.status === 'A' && fields.field_label.toLowerCase() === "First Name".toLowerCase() && fields.is_req === 'Y'  ) {
                            fnameRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Last Name".toLowerCase() && fields.is_req === 'Y' ) {
                            lnameRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Salutation".toLowerCase() && fields.is_req === 'Y' ) {
                            salutationRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Nick Name".toLowerCase() && fields.is_req === 'Y' ) {
                            nnameRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Job Title".toLowerCase() && fields.is_req === 'Y' ) {
                            jobtitleRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Company Name".toLowerCase() && fields.is_req === 'Y' ) {
                            companyRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Email".toLowerCase() && fields.is_req === 'Y' ) {
                            emailRequired = true;
                        } else if( fields.status === 'A' && fields.field_label.toLowerCase() === "Phone".toLowerCase() && fields.is_req === 'Y' ) {
                            phoneRequired = true;
                        }

                        return fields;
                    })
                }
                return el;
            });
        }

        this.setState({
            firstname_req: fnameRequired,
            lastname_req: lnameRequired,
            salutation_req: salutationRequired,
            nickname_req: nnameRequired,
            jobtitle_req: jobtitleRequired,
            company_req: companyRequired,
            email_req: emailRequired,
            phone_req: phoneRequired,
            field_check: true
         });
    }

    DisCard = () => {

        let constData = this.state.constData;

        var UserData = this.state.user;
        var userEmail = this.state.userEmail;
        var userFirstName = UserData.fname !== undefined ? UserData.fname : '';
        var userLastName= UserData.lname !== undefined ? UserData.lname : '';
        var user_image = (UserData.pic && constData ) ? constData.BaseUrl + "/uploads/speaker/" + UserData.pic : blank;


        this.setState({
            userFirstName: userFirstName !== undefined ? userFirstName : '',
            userLastName: userLastName !== undefined ? userLastName : '',
            salut: UserData.salut !== undefined ? UserData.salut : '',
            title: UserData.title !== undefined ? UserData.title : '',
            company: UserData.company !== undefined ? UserData.company : '',
            email: userEmail !== undefined ? userEmail : '',
            nname: UserData.nname !== undefined ? UserData.nname : '',
            phone: UserData.phone !== undefined ? UserData.phone : '',
            user_image: user_image,
            pic: UserData.pic !== undefined ? UserData.pic : '',
        })
    }

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    getImageDimension = (image) => {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader()
                fileReader.onload = () => {
                    const img = new Image()
                    img.onload = () => {
                        resolve({ width: img.width, height: img.height })
                    }
                    img.src = fileReader.result
                }
                fileReader.readAsDataURL(image)
            } catch (e) {
                reject(e)
            }
        })
    };

    ImageUpload = async (image) => {

        let constData = this.state.constData;
        if(null !== constData ) {

            const base64Image = await this.convertToBase64(image);

            var UserData = this.state.user;

            if( base64Image !== undefined ){

                var imageData = base64Image.replace("data:image/png;base64,", "");
                imageData = imageData.replace("data:image/jpeg;base64,", "");
                imageData = imageData.replace("data:image/jpg;base64,", "");

                const data = {
                    url: constData.ApiBaseUrl+'/account/imageupload.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "imageUpload",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "pf": REACT_APP_PF,
                            "device_key": REACT_APP_DEVICE_KEY,
                            "event_id": this.state.eventId,
                            "profile_id": UserData.profile_id,
                            "is_profile_id_encrypted": 0,
                            "pic": imageData,
                        }, "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "content-type": "application/json"
                    },
                }).then(res => {
                    if( res.data.result !== undefined && res.data.result !== '' ){
                        return this.ProfileUpdate(res.data.result)
                    }
                }).catch(error => {
                    this.setState({
                        updateMessage: "<strong>"+error.code+"</strong> "+error.message+". Profile not updated successfully.",
                        MessageColor: 'red'
                    });
                    setTimeout(this.DiscardMessage, 7000);
                });
            }
        }

        return "";
    }

    DiscardMessage = () => {
        this.setState({
            updateMessage: ''
        })
   }

    ProfileUpdate = async (e) => {

        let constData = this.state.constData;
        if(!constData) {
            this.setState({
                updateMessage: "Something went wrong. Please try again.",
                MessageColor: 'red'
            });
            return;
        }

        ls.config.encrypt = true;
        var eventId = this.state.eventId;
        var UserData = this.state.user;
        var UserLoginSession = this.state.UserLoginSession;
        var UserImageName = UserData.pic;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        UserImageName = e !== undefined ? e : UserImageName;

        const is_valid = [];
        if( this.state.firstname_req && this.state.userFirstName === ""  ) {
            is_valid.push('First name');
        }

        if( this.state.lastname_req && this.state.userLastName === ""  ) {
            is_valid.push('Last name');
        }

        if( this.state.salutation_req && this.state.salut === ""  ) {
            is_valid.push('Salutation');
        }

        if( this.state.nickname_req && this.state.nname === ""  ) {
            is_valid.push('Nick name');
        }

        if( this.state.jobtitle_req && this.state.title === ""  ) {
            is_valid.push('Job Title');
        }

        if( this.state.company_req && this.state.company === ""  ) {
            is_valid.push('Company');
        }

        if( this.state.email_req && this.state.email === ""  ) {
            is_valid.push('Email');
        }

        if( this.state.phone_req && this.state.phone === ""  ) {
            is_valid.push('Phone');
        }

        if( is_valid.length === 0 ) {
            const data = {
                url: constData.ApiBaseUrl+'/post/index.php',
                method: "POST",
                post_fields: {
                    "id": getRandomNumber,
                    "method": "manageProfile",
                    "params": {
                        "app_version": REACT_APP_VERSION,
                        "event_id": eventId,
                        "access_token": UserLoginSession,
                        "pkg_nm": REACT_APP_PKG_NAME,
                        "app_device_id": REACT_APP_DEVICE_ID,
                        "device_key": REACT_APP_DEVICE_KEY,
                        "profile": {
                            "fname": this.state.userFirstName,
                            "lname": this.state.userLastName,
                            "salut": this.state.salut,
                            "nname": this.state.nname,
                            "title": this.state.title,
                            "company": this.state.company,
                            "email": this.state.email,
                            "phone": this.state.phone,
                            "pic": UserImageName,
                            "web": "",
                        }
                    }, "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {

                let result = res.data !== undefined ? res.data : "";
                if(result.hasOwnProperty('error')){
                    this.setState({
                        updateMessage: result.error.message,
                        MessageColor: 'red'
                    });
                    setTimeout(this.DiscardMessage, 5000);
                } else if (result.hasOwnProperty('result') && result.result.code === 200) {
                    this.setState({
                        updateMessage: "Profile updated successfully.",
                        MessageColor: 'green'
                    });
                    setTimeout( this.DiscardMessage, 5000);

                    let userProfile = result.result.profile;

                    ls.set("PreLoginUserProfile", userProfile);

                    if( userProfile.status === 'A' ) {
                        ls.set('login_status', 'success');
                        ls.set('user_login_status', 'success');
                    }

                    GetEventsUserData = GetEventsUserData.map(function (el) {
                        if (el.eventId === eventId) {
                            el.UserProfile = result.result.profile;
                            UserData = result.result.profile;
                        }
                        return el;
                    })
                }
                ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
            }).catch(error => {
                this.setState({
                    updateMessage: error,
                    MessageColor: 'red'
                });
                setTimeout( this.DiscardMessage, 5000);
            });
            this.setState({
                user: UserData
            })
        } else {
            if( is_valid.length > 0 ) {
                const errorMessage = is_valid.join(', ')+' is Required';
                this.setState({
                    updateMessage: errorMessage,
                    MessageColor: 'red'
                });
            }
        }
    }

    SubmitProfile = async (e) => {
        e.preventDefault();
        ls.config.encrypt = true;

        var UserProfileImage = this.state.avatar;

        if( UserProfileImage !== undefined && UserProfileImage !== "" && typeof UserProfileImage !== 'string' ){
            await this.ImageUpload(UserProfileImage);
        }else{
            await this.ProfileUpdate(this.state.avatar);
        }
    }

    AvatarImage = async (e) => {
        var uploadImage = e.target.files[0];
        var base64Image = await this.convertToBase64(uploadImage);

        this.setState({
            avatar: uploadImage,
            base64image: base64Image,
            updateMessage: ''
        });
        var x = document.getElementById('removeImage');
        x.style.display = "block";
    }

    RemoveButton = () => {
        var x = document.getElementById('removeImage');
        x.style.display = "none";
    }

    RemoveAvatarImage = () => {

        this.setState({
            avatar: '',
            base64image: '',
            user_image: ''
        });
        this.RemoveButton();
    }

    render(){
        const { t } = this.props;
        var user = this.state.user;
        var user_image = this.state.user_image;
        user_image = user_image ? user_image : blank;
        user_image = this.state.base64image !== "" ? this.state.base64image : user_image;
        var displayNone = user.pic ? "block" : "none";

        if( !this.state.field_check ) {
            this.checkFieldRequired();
        }

        const firstname = this.state.firstname_req;
        const lastname = this.state.lastname_req;
        const Salutation = this.state.salutation_req;
        const nickname = this.state.nickname_req;
        const jobtitle = this.state.jobtitle_req;
        const company = this.state.company_req;
        const email = this.state.email_req;
        const phone = this.state.phone_req;

        return (
            <>
                <SideMenu constData={this.state.constData}/>
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle={t("My Profile")}/></h1>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <BackButton constData={this.state.constData} />
                                </div>
                            </div>

                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body">
                                            <div className="d-flex flex-center flex-column py-5">
                                                <div  className="image-input image-input-outline"
                                                     data-kt-image-input="true"
                                                     style={{backgroundImage: "url(" + blank + ")"}}>
                                                    <div  className="image-input-wrapper w-150px h-150px" style={{backgroundImage: "url(" + user_image + ")"}}></div>
                                                    <label
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
                                                        <i className="bi bi-pencil-fill fs-7"></i>
                                                        <input type="file" onChange={ this.AvatarImage } value={""} name="avatar" accept=".png, .jpg, .jpeg" />
                                                        <input value="1" type="hidden" name="avatar_remove"/>
                                                    </label>
                                                    <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel" data-bs-toggle="tooltip"
                                                        title="Cancel avatar">
                                                        <i className="bi bi-x fs-2"></i>
                                                    </span>
                                                    <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="remove" id={"removeImage"} data-bs-toggle="tooltip" onClick={ this.RemoveAvatarImage }
                                                        title="Remove avatar" style={{display: displayNone}}>
                                                        <i className="bi bi-x fs-2"></i>
                                                    </span>
                                                </div>
                                                <div className="form-text mb-5">Allowed file types: png, jpg, jpeg.</div>
                                                <a href={REACT_APP_HOME_URL+"/my-profile"} className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-2">{this.state.salut} {this.state.userFirstName} {this.state.userLastName}</a>
                                                {this.state.title ? <p className="fs-5 text-gray-600 fw-bold mb-3">{this.state.title}</p> : ''}
                                                {this.state.company !== undefined ?
                                                    <div className="mb-6">
                                                        <div className="badge badge-lg badge-light-primary d-inline-block">{this.state.company}</div>
                                                    </div> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="kt_user_view_overview_tab"
                                             role="tabpanel">
                                            <div className="card mb-6 mb-xl-9">
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title flex-column">
                                                        <h2 className="mb-1">Profile Details</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body p-9 pb-2">
                                                    <div id="kt_user_view_details" className="collapse show">
                                                        <form id="kt_account_profile_details_form" className="form">
                                                            <div className="row mb-6">
                                                                <label
                                                                    className={`col-xl-3 col-form-label fw-bold fs-6 ${ firstname ? 'required' : ''}`}>Full
                                                                    Name</label>
                                                                <div className="col-xl-9">
                                                                    <div className="row">
                                                                        <div className="col-md-6 fv-row">
                                                                            <input type="text" name="fname" onChange={(element) => { this.setState({'userFirstName': element.target.value})}}
                                                                                   className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                                   placeholder="First name"
                                                                                   value={this.state.userFirstName}
                                                                                   required={firstname}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-6 fv-row">
                                                                            <input type="text" name="lname" onChange={(element) => { this.setState({'userLastName': element.target.value})}}
                                                                                   className="form-control form-control-lg form-control-solid"
                                                                                   placeholder="Last name"
                                                                                   value={this.state.userLastName}
                                                                                   required={lastname}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label
                                                                    className={`col-xl-3 col-form-label fw-bold fs-6 ${ Salutation ? 'required' : ''}`}>Salutation</label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="text" name="solutation" onChange={(element) => { this.setState({'salut': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Salutation" value={this.state.salut}
                                                                           required={Salutation}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label
                                                                    className={`col-xl-3 col-form-label fw-bold fs-6 ${ nickname ? 'required' : ''}`}>Nick
                                                                    Name</label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="text" name="nickname" onChange={(element) => { this.setState({'nname': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Nick Name" value={this.state.nname}
                                                                           required={nickname}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label
                                                                    className={`col-xl-3 col-form-label fw-bold fs-6 ${ jobtitle ? 'required' : ''}`}>Job
                                                                    Title</label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="text" name="jobtitle" onChange={(element) => { this.setState({'title': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Job Title" value={this.state.title}
                                                                           required={jobtitle}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label
                                                                    className={`col-xl-3 col-form-label fw-bold fs-6 ${ company ? 'required' : ''}`}>Company</label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="text" name="company" onChange={(element) => { this.setState({'company': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Company name" value={this.state.company}
                                                                           required={company}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label className={`col-xl-3 col-form-label fw-bold fs-6 ${ email ? 'required' : ''}`}>Email</label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="email" name="email" onChange={(element) => { this.setState({'email': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Email" value={this.state.email}
                                                                           required={email}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <label className={`col-xl-3 col-form-label fw-bold fs-6 ${ phone ? 'required' : ''}`}>Phone<i className="fas fa-exclamation-circle ms-1 fs-7"
                                                                       data-bs-toggle="tooltip"
                                                                       title="Phone number must be active"></i>
                                                                </label>
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="tel" name="phone" onChange={(element) => { this.setState({'phone': element.target.value})}}
                                                                           className="form-control form-control-lg form-control-solid"
                                                                           placeholder="Phone number" value={this.state.phone}
                                                                           required={phone}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mb-6">
                                                                <span className={"success-message"} style={{color: this.state.MessageColor}} dangerouslySetInnerHTML={{__html: this.state.updateMessage}} />
                                                            </div>
                                                            <div
                                                                className="card-footer d-flex justify-content-end py-6 px-9">
                                                                <button type="reset" onClick={this.DisCard}
                                                                        className="btn btn-light btn-active-light-primary me-2">Discard
                                                                </button>
                                                                <button type="submit" onClick={this.SubmitProfile} className="btn btn-primary"
                                                                        id="kt_account_profile_details_submit">Save
                                                                    Changes
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData}/>
                </div>
            </>
        )
    }
}

export default withTranslation()(EditProfilePage);
