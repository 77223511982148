import React from 'react';
import ls from 'localstorage-slim';
import SpeakerCard from "./speaker-card";

const RelatedSpeakersTemplate = (props) => {
    ls.config.encrypt = true;
    const {RelatedSpeakers, constData} = props;

    const checkSpeakerStatus = (speakerID) => {

        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var eventId = ls.get("eventId");

        var speakerStatus = '';
        if( '' !== GetEventsUserData && undefined !== GetEventsUserData ) {
            GetEventsUserData.map(function (el) {
                if( Number( el.eventId ) === Number( eventId ) && el.SpeakersData !== undefined){

                    let SpeakersData = el.SpeakersData;

                    SpeakersData.map(function (speaker) {
                        if(Number(speakerID) === Number(speaker.person_id)) {
                            speakerStatus = speaker.status;
                        }
                    });
                }
                return el;
            });
        }

        return speakerStatus;
    }

    const GetRelatedSpeakers = () => {
        if(RelatedSpeakers !== undefined && RelatedSpeakers !== null && RelatedSpeakers.length > 0 ) {
            return(
                RelatedSpeakers.map((Speaker)=> {
                    let getStatus = checkSpeakerStatus(Speaker.speakerId);

                    if( getStatus === 'A' ) {
                        return (
                            <SpeakerCard constData={constData} key={Speaker.speakerId + "1"} speaker={Speaker} />
                        )
                    }
                    return '';
                })
            );
        } else {
            return "Speakers not available";
        }
    }

    return (
        GetRelatedSpeakers()
    );
};

export default RelatedSpeakersTemplate;