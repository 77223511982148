import React, { Component } from 'react';
import ls from 'localstorage-slim';

class PageTitle extends Component {

    constructor(props) {

        super(props);

        this.state = {
            getPageTitle: '',
            constData: props.constData,
            ProfileId: '',
        }
    }

    componentDidMount() {
        ls.config.encrypt = true;

        this.managePageTitle();
    }

    managePageTitle = () => {

        let constData = this.state.constData;

        if(constData) {
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

            var ProfileId = '';
            if( constData.LoginType !== undefined && 'P' === constData.LoginType ) {
                let UserData = ls.get("PreLoginUserProfile");
                ProfileId = (undefined !== UserData && '' !== UserData && null !== UserData) ? UserData.profile_id : '';
            } else {
                if(GetEventsUserData !== undefined && GetEventsUserData !== null) {
                    GetEventsUserData.map(function (el) {
                        if( Number( el.eventId ) === Number( ls.get('eventId') ) && el.UserProfile !== undefined){
                            let UserData = el.UserProfile;
                            ProfileId = (undefined !== UserData && '' !== UserData && null !== UserData) ? UserData.profile_id : '';
                        }
                        return el;
                    });
                }
            }

            var getPageTitle = '';
            if( GetEventsUserData !== undefined && GetEventsUserData !== null ) {
                var eventId = ls.get('eventId');
                GetEventsUserData.map( ( el ) => {
                    if( el.eventId === eventId && el.event_home_page !== undefined && constData.AvailableSideMenu !== undefined) {
                        el.event_home_page.map(function (data) {
                            constData.AvailableSideMenu.map(function (Menu) {
                                if ( Number(data.fk_feature_id) === Number(Menu.id) && window.location.pathname === Menu.url ) {
                                    getPageTitle = (data.listing_menu_title !== '' && undefined !== data.listing_menu_title ) ? data.listing_menu_title : data.menu_name;
                                }
                                return Menu;
                            })
                            return data;
                        });
                    }
                    return el;
                });
            }

            this.setState({
                ProfileId: ProfileId,
                getPageTitle: getPageTitle
            });
        }
    }

    render() {

        return (
            <span data-profile={this.state.ProfileId}>{ (this.state.getPageTitle) ? this.state.getPageTitle : this.props.DefaultTitle }</span>
        );
    }
}

export default PageTitle;