import React from "react";

const Pagination = (props) => {

    const {TatalPost, PaginateSpeakers, ActivePage,perPage, constData} = props;

    var showTo = perPage * ActivePage;
    showTo = showTo > TatalPost ? TatalPost : showTo;
    var showFrom = ( perPage * ActivePage ) - perPage + 1;
    const pages = Math.ceil(TatalPost / perPage);

    const PagineteLink = () => {

        const items = [];
        for(var i = 1; i <= pages; i++) {
            var ActiveClass = i === ActivePage ? " active" : "";
            items.push(
                <li key={i} className={"page-item " + ActiveClass}>
                    <a href="#pagelink" onClick={PaginateSpeakers.bind(this, i )} className={ "page-link"} >{i}</a>
                </li>
            );
        }
        return items;
    }

    return (
        <>
            <div className="fs-6 fw-bold text-gray-700">Showing {showFrom} to {showTo} of {props.TatalPost} entries</div>
            { pages > 1 ?
                <ul className="pagination">
                    { ActivePage > 1 ?
                        <li className="page-item previous">
                            <a href="#previous" onClick={PaginateSpeakers.bind(this, ActivePage - 1 )} className="page-link">
                                <i className="previous"></i>
                            </a>
                        </li> : ""
                    }
                    <PagineteLink />
                    { ActivePage < pages ?
                        <li className="page-item next">
                            <a href="#next" onClick={PaginateSpeakers.bind(this, ActivePage + 1 )} className="page-link">
                                <i className="next"></i>
                            </a>
                        </li> : ""
                    }
                </ul> : ""
            }
        </>
    );
};

export default Pagination;
