import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ls from 'localstorage-slim';
import GetMaterials from "../api/get-materials";
import PageTitle from "../template/PageTitle";

class MaterialsList extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)

        this.state = {
            constData: this.props.constData
        }
    }

    render(){
        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Materials 2"/></h1>
                                </div>
                            </div>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="row card-body p-9 row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                    <GetMaterials constData={this.state.constData} FeatureId={"11002"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default MaterialsList;