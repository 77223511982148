import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import blank from '../../assets/media/avatars/blank.png';
import ls from 'localstorage-slim';
import BackButton from "../template/backbutton";
import GetMaterials from "../api/get-materials";
import AgendaData from "../api/get-agenda";
import AuxFields from "../template/aux-field";
import PageTitle from "../template/PageTitle";

class SpeakersDetail extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)

        this.state = {
            constData: props.constData,
            speakerId: ls.get("speakerId") !== null ? ls.get("speakerId") : '',
            eventId: ls.get("eventId"),
            biodata: ""
        }
    }

    componentDidMount() {
        ls.config.encrypt = true;

        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var speakerId = ls.get("speakerId") !== null ? ls.get("speakerId") : '';
        var SpeakersData = [];
        var RelatedMaterialsIds = [];
        var RelatedMaterials = [];
        var CurrentSpeaker = '';
        var event_small_aux = '';
        var event_small_aux_data = '';
        var biodata_id = '';
        var biodata = '';
        var aux_fields_ids = [];
        var aux_fields = [];

        if( GetEventsUserData === null || GetEventsUserData.some(function(el) {return el.eventId === eventId && el.SpeakersData !== null;}) ) {
            GetEventsUserData.map(function (el) {
                if(el.eventId === eventId ){
                    SpeakersData = el.SpeakersData;
                    event_small_aux = el.event_small_aux;
                    event_small_aux_data = el.event_small_aux_data;

                    if( el.EventMaterials !== '' && el.EventMaterials !== undefined && el.EventMaterialsConnection !== '' && el.EventMaterialsConnection !== undefined ){
                        RelatedMaterialsIds = [];
                        el.EventMaterialsConnection.some(function(MaterialsIds) { if( speakerId !== '' && speakerId !== undefined && MaterialsIds.row_id === speakerId && MaterialsIds.fk_feature_id === "4" && MaterialsIds.status === 'A' ) {
                            RelatedMaterialsIds.push(MaterialsIds.fk_material_id);
                        }})
                        if( el.EventMaterials !== '' && el.EventMaterials !== undefined ){
                            RelatedMaterials = [];
                            el.EventMaterials.map(function(Material) {
                                RelatedMaterialsIds.some(function(MaterialId) {
                                    if (Material !== '' && Material !== undefined && Material.material_id === MaterialId ) {
                                        var AgendaMaterialData = {
                                            material_id: Material.material_id,
                                            title: Material.title,
                                            file: Material.file,
                                            type: Material.type,
                                            created: Material.created,
                                            modified: Material.modified,
                                            aux_field: biodata,
                                        }
                                        RelatedMaterials.push(AgendaMaterialData);
                                    }
                                })
                            })
                        }
                    }
                }

                return el;
            });

            if( SpeakersData !== '' && SpeakersData !== undefined ){
                SpeakersData.map((speaker, index) => {
                    if( speaker.person_id === this.state.speakerId &&  ( CurrentSpeaker === undefined || CurrentSpeaker === null || CurrentSpeaker === '' ) ){
                        CurrentSpeaker = speaker;
                    }
                })
            }
            if( event_small_aux !== '' && event_small_aux !== undefined ){
                event_small_aux.map(( small_aux ) =>{
                    if( small_aux.fk_event_id === eventId && ( small_aux.fk_feature_id === "4" || small_aux.fk_feature_id === "4001" || small_aux.fk_feature_id === "4002" || small_aux.fk_feature_id === "4003" || small_aux.fk_feature_id === "4004" || small_aux.fk_feature_id === "4005") && small_aux.status === "A" ){
                        if( small_aux.fk_field_type_id === "19" ){
                            biodata_id = small_aux.small_field_id;
                        } else {
                            var data = {
                                small_field_id: small_aux.small_field_id,
                                field_name: small_aux.field_name,
                                small_field_type: small_aux.fk_field_type_id
                            }
                            aux_fields_ids.push(data);
                        }
                    }
                });
            }
            if( event_small_aux_data !== '' && event_small_aux_data !== undefined ){
                event_small_aux_data.map( ( aux_data ) => {
                    if( aux_data.fk_record_id === this.state.speakerId && aux_data.status === "A") {
                        if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === biodata_id) {
                            biodata = aux_data.value
                        } else if (aux_fields_ids !== undefined && aux_fields_ids.length > 0) {
                            aux_fields_ids.map((aux_field) => {
                                if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === aux_field.small_field_id ) {
                                    aux_data = {
                                        field_type: aux_field.small_field_type,
                                        field_name: aux_field.field_name,
                                        field_value: aux_data.value
                                    }
                                    aux_fields.push(aux_data);
                                }
                            });
                        }
                    }
                });
            }

            this.setState({
                SpeakersData: SpeakersData,
                CurrentSpeaker: CurrentSpeaker,
                biodata: biodata,
                aux_fields: aux_fields
            });
        }
    }

    render(){

        let constData = this.state.constData;
        var speaker = this.state.CurrentSpeaker !== null ? this.state.CurrentSpeaker : '';
        var speakerId = this.state.speakerId;
        var SpeakerFirstName = '';
        var SpeakerLastName = '';
        var salutation = '';
        var SpeakerJobTitle = '';
        var SpeakerCompanyName = '';
        var SpeakerPersonImage = blank;
        if(speaker !== undefined){
            SpeakerFirstName = speaker.first_name !== undefined ? speaker.first_name : '';
            SpeakerLastName = speaker.last_name !== undefined ? speaker.last_name : '';
            salutation = speaker.salutation ? speaker.salutation : '';
            SpeakerJobTitle = speaker.job_title !== undefined ? speaker.job_title : '';
            SpeakerCompanyName = speaker.company_name !== undefined ? speaker.company_name : '';
            SpeakerPersonImage = (speaker.person_image !== undefined && speaker.person_image !== '' && constData) ? constData.BaseUrl + '/uploads/speaker/' + speaker.person_image : blank;
        }

        let displayName = salutation+' '+SpeakerFirstName+' '+SpeakerLastName;

        const manageDisplayTitle = ( title, length = 25 ) => {

            if( '' !== title ) {

                title = title.trim();
                if( title.length >= length) {
                    return title.slice(0, length) + '...';
                }
            }
            return title;
        }

        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Speakers"/></h1>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <BackButton constData={this.state.constData}/>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body">
                                            <div className="d-flex flex-center flex-column pt-5">
                                                <div className="symbol symbol-160px symbol-circle mb-7">
                                                    <img src={SpeakerPersonImage} alt={SpeakerFirstName} />
                                                </div>
                                                <a href="#SpeakerName" className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-1">
                                                    <p className="fs-5 text-gray-600 fw-bold mb-3" title={displayName}>{manageDisplayTitle(displayName)}</p>
                                                </a>
                                                <p className="fs-5 text-gray-600 fw-bold mb-3" title={SpeakerJobTitle}>{manageDisplayTitle(SpeakerJobTitle)}</p>
                                                <div className="mb-6">
                                                    <div
                                                        className="badge badge-lg badge-light-primary d-inline-block" title={SpeakerCompanyName}>{manageDisplayTitle(SpeakerCompanyName)}
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="mb-4 fs-5">Biography</h4>
                                            <p className="fs-5 text-gray-900">{this.state.biodata}</p>
                                            <p>{ undefined !== this.state.aux_fields && null !== this.state.aux_fields ? <AuxFields constData={this.state.constData} aux_fields={this.state.aux_fields} /> : "" }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="kt_user_view_overview_tab"
                                             role="tabpanel">
                                            <div className="card mb-6 mb-xl-9">
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title flex-column">
                                                        <h2 className="mb-1">Sessions</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body p-9">
                                                    <AgendaData constData={this.state.constData} FeatureId={"4"} fk_sponsor_id={speakerId} speakerId={this.state.speakerId} />
                                                </div>
                                            </div>
                                            <div className="card mb-6 mb-xl-9">
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title flex-column">
                                                        <h2 className="mb-1">Materials</h2>
                                                    </div>
                                                </div>
                                                <div className="row card-body p-9 row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                                    <GetMaterials constData={this.state.constData} FeatureId={"4"} speakerId={this.state.speakerId} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="kt_user_view_overview_security"
                                             role="tabpanel">
                                            <div className="card pt-4 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title">
                                                        <h2>Profile</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0 pb-5">
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-row-dashed gy-5"
                                                               id="kt_table_users_login_session">
                                                            <tbody className="fs-6 fw-bold text-gray-600">
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>e.smith@kpmg.com.au</td>
                                                                <td className="text-end">
                                                                    <button type="button"
                                                                            className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#kt_modal_update_email">
                                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3"
                                                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                  fill="black"></path>
                                                            <path
                                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                fill="black"></path>
                                                            </svg>
                                                        </span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Password</td>
                                                                <td>******</td>
                                                                <td className="text-end">
                                                                    <button type="button"
                                                                            className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#kt_modal_update_password">
                                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3"
                                                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                  fill="black"></path>
                                                            <path
                                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                fill="black"></path>
                                                            </svg>
                                                        </span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Role</td>
                                                                <td>Administrator</td>
                                                                <td className="text-end">
                                                                    <button type="button"
                                                                            className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#kt_modal_update_role">
                                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                 height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3"
                                                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                                  fill="black"></path>
                                                            <path
                                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                                fill="black"></path>
                                                            </svg>
                                                        </span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card pt-4 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title flex-column">
                                                        <h2 className="mb-1">Two Step Authentication</h2>
                                                        <div className="fs-6 fw-bold text-muted">Keep your account extra
                                                            secure with a second authentication step.
                                                        </div>
                                                    </div>
                                                    <div className="card-toolbar">
                                                        <button type="button" className="btn btn-light-primary btn-sm"
                                                                data-kt-menu-trigger="click"
                                                                data-kt-menu-placement="bottom-end">
                                                            <span className="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M21 10.7192H3C2.4 10.7192 2 11.1192 2 11.7192C2 12.3192 2.4 12.7192 3 12.7192H6V14.7192C6 18.0192 8.7 20.7192 12 20.7192C15.3 20.7192 18 18.0192 18 14.7192V12.7192H21C21.6 12.7192 22 12.3192 22 11.7192C22 11.1192 21.6 10.7192 21 10.7192Z"
                                                  fill="black"></path>
                                            <path
                                                d="M11.6 21.9192C11.4 21.9192 11.2 21.8192 11 21.7192C10.6 21.4192 10.5 20.7191 10.8 20.3191C11.7 19.1191 12.3 17.8191 12.7 16.3191C12.8 15.8191 13.4 15.4192 13.9 15.6192C14.4 15.7192 14.8 16.3191 14.6 16.8191C14.2 18.5191 13.4 20.1192 12.4 21.5192C12.2 21.7192 11.9 21.9192 11.6 21.9192ZM8.7 19.7192C10.2 18.1192 11 15.9192 11 13.7192V8.71917C11 8.11917 11.4 7.71917 12 7.71917C12.6 7.71917 13 8.11917 13 8.71917V13.0192C13 13.6192 13.4 14.0192 14 14.0192C14.6 14.0192 15 13.6192 15 13.0192V8.71917C15 7.01917 13.7 5.71917 12 5.71917C10.3 5.71917 9 7.01917 9 8.71917V13.7192C9 15.4192 8.4 17.1191 7.2 18.3191C6.8 18.7191 6.9 19.3192 7.3 19.7192C7.5 19.9192 7.7 20.0192 8 20.0192C8.3 20.0192 8.5 19.9192 8.7 19.7192ZM6 16.7192C6.5 16.7192 7 16.2192 7 15.7192V8.71917C7 8.11917 7.1 7.51918 7.3 6.91918C7.5 6.41918 7.2 5.8192 6.7 5.6192C6.2 5.4192 5.59999 5.71917 5.39999 6.21917C5.09999 7.01917 5 7.81917 5 8.71917V15.7192V15.8191C5 16.3191 5.5 16.7192 6 16.7192ZM9 4.71917C9.5 4.31917 10.1 4.11918 10.7 3.91918C11.2 3.81918 11.5 3.21917 11.4 2.71917C11.3 2.21917 10.7 1.91916 10.2 2.01916C9.4 2.21916 8.59999 2.6192 7.89999 3.1192C7.49999 3.4192 7.4 4.11916 7.7 4.51916C7.9 4.81916 8.2 4.91918 8.5 4.91918C8.6 4.91918 8.8 4.81917 9 4.71917ZM18.2 18.9192C18.7 17.2192 19 15.5192 19 13.7192V8.71917C19 5.71917 17.1 3.1192 14.3 2.1192C13.8 1.9192 13.2 2.21917 13 2.71917C12.8 3.21917 13.1 3.81916 13.6 4.01916C15.6 4.71916 17 6.61917 17 8.71917V13.7192C17 15.3192 16.8 16.8191 16.3 18.3191C16.1 18.8191 16.4 19.4192 16.9 19.6192C17 19.6192 17.1 19.6192 17.2 19.6192C17.7 19.6192 18 19.3192 18.2 18.9192Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>
                                                            Add Authentication Step
                                                        </button>
                                                        <div
                                                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-6 w-200px py-4"
                                                            data-kt-menu="true">
                                                            <div className="menu-item px-3">
                                                                <a href="#modal" className="menu-link px-3"
                                                                   data-bs-toggle="modal"
                                                                   data-bs-target="#kt_modal_add_auth_app">Use authenticator app</a>
                                                            </div>
                                                            <div className="menu-item px-3">
                                                                <a href="#modal" className="menu-link px-3"
                                                                   data-bs-toggle="modal"
                                                                   data-bs-target="#kt_modal_add_one_time_password">Enable
                                                                    one-time password</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body pb-5">
                                                    <div className="d-flex flex-stack">
                                                        <div className="d-flex flex-column">
                                                            <span>SMS</span>
                                                            <span className="text-muted fs-6">+61 412 345 678</span>
                                                        </div>


                                                        <div className="d-flex justify-content-end align-items-center">

                                                            <button type="button"
                                                                    className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto me-5"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#kt_modal_add_one_time_password">

                                                                <span className="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3"
                                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                      fill="black"></path>
                                                <path
                                                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                    fill="black"></path>
                                                </svg>
                                            </span>

                                                            </button>


                                                            <button type="button"
                                                                    className="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                                                    id="kt_users_delete_two_step">

                                                                <span className="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                    fill="black"></path>
                                                <path opacity="0.5"
                                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                      fill="black"></path>
                                                <path opacity="0.5"
                                                      d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                      fill="black"></path>
                                                </svg>
                                            </span>

                                                            </button>

                                                        </div>

                                                    </div>
                                                    <div className="separator separator-dashed my-5"></div>
                                                    <div className="text-gray-600">If you lose your mobile device or security key, you can
                                                        <a href="#signin" className="me-1">generate a backup code</a>to sign in to your account.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card pt-4 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title flex-column">
                                                        <h2>Email Notifications</h2>
                                                        <div className="fs-6 fw-bold text-muted">Choose what messages you�d like to receive for each of your accounts.</div>
                                                    </div>
                                                </div>


                                                <div className="card-body">

                                                    <form className="form" id="kt_users_email_notification_form">

                                                        <div className="d-flex">

                                                            <div
                                                                className="form-check form-check-custom form-check-solid">

                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_0" type="checkbox"
                                                                       value="0"
                                                                       id="kt_modal_update_email_notification_0"/>
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_0">
                                                                    <div className="fw-bolder">Successful Payments
                                                                    </div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification for every successful payment.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3" name="email_notification_1" type="checkbox" value="1" id="kt_modal_update_email_notification_1" />
                                                                <label className="form-check-label" htmlFor="kt_modal_update_email_notification_1">
                                                                    <div className="fw-bolder">Payouts</div>
                                                                    <div className="text-gray-600">Receive a notification for every initiated payout.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_2" type="checkbox"
                                                                       value="2"
                                                                       id="kt_modal_update_email_notification_2" />
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_2">
                                                                    <div className="fw-bolder">Application fees
                                                                    </div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification each time you collect a fee
                                                                        from an account.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_3" type="checkbox"
                                                                       value="3"
                                                                       id="kt_modal_update_email_notification_3"/>
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_3">
                                                                    <div className="fw-bolder">Disputes</div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification if a payment is disputed by a
                                                                        customer and for dispute resolutions.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_4" type="checkbox"
                                                                       value="4"
                                                                       id="kt_modal_update_email_notification_4"/>
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_4">
                                                                    <div className="fw-bolder">Payment reviews</div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification if a payment is marked as an
                                                                        elevated risk.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_5" type="checkbox"
                                                                       value="5"
                                                                       id="kt_modal_update_email_notification_5" />
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_5">
                                                                    <div className="fw-bolder">Mentions</div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification if a teammate mentions you in a
                                                                        note.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_6" type="checkbox"
                                                                       value="6"
                                                                       id="kt_modal_update_email_notification_6" />
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_6">
                                                                    <div className="fw-bolder">Invoice Mispayments
                                                                    </div>
                                                                    <div className="text-gray-600">Receive a
                                                                        notification if a customer sends an
                                                                        incorrect amount to pay their invoice.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_7" type="checkbox"
                                                                       value="7"
                                                                       id="kt_modal_update_email_notification_7" />
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_7">
                                                                    <div className="fw-bolder">Webhooks</div>
                                                                    <div className="text-gray-600">Receive
                                                                        notifications about consistently failing
                                                                        webhook endpoints.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="separator separator-dashed my-5"></div>
                                                        <div className="d-flex">
                                                            <div
                                                                className="form-check form-check-custom form-check-solid">
                                                                <input className="form-check-input me-3"
                                                                       name="email_notification_8" type="checkbox"
                                                                       value="8"
                                                                       id="kt_modal_update_email_notification_8" />
                                                                <label className="form-check-label"
                                                                       htmlFor="kt_modal_update_email_notification_8">
                                                                    <div className="fw-bolder">Trial</div>
                                                                    <div className="text-gray-600">Receive helpful
                                                                        tips when you try out our products.
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="d-flex justify-content-end align-items-center mt-12">
                                                            <button type="button" className="btn btn-light me-5"
                                                                    id="kt_users_email_notification_cancel">Cancel
                                                            </button>
                                                            <button type="button" className="btn btn-primary"
                                                                    id="kt_users_email_notification_submit">
                                                                <span className="indicator-label">Save</span>
                                                                <span className="indicator-progress">Please wait...
                                                <span
                                                    className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="kt_user_view_overview_events_and_logs_tab"
                                             role="tabpanel">
                                            <div className="card pt-4 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title">
                                                        <h2>Login Sessions</h2>
                                                    </div>
                                                    <div className="card-toolbar">
                                                        <button type="button"
                                                                className="btn btn-sm btn-flex btn-light-primary"
                                                                id="kt_modal_sign_out_sesions">
                                                            <span className="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="4" y="11" width="12" height="2" rx="1"
                                                  fill="black"></rect>
                                            <path
                                                d="M5.86875 11.6927L7.62435 10.2297C8.09457 9.83785 8.12683 9.12683 7.69401 8.69401C7.3043 8.3043 6.67836 8.28591 6.26643 8.65206L3.34084 11.2526C2.89332 11.6504 2.89332 12.3496 3.34084 12.7474L6.26643 15.3479C6.67836 15.7141 7.3043 15.6957 7.69401 15.306C8.12683 14.8732 8.09458 14.1621 7.62435 13.7703L5.86875 12.3073C5.67684 12.1474 5.67684 11.8526 5.86875 11.6927Z"
                                                fill="black"></path>
                                            <path
                                                d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                                                fill="#C4C4C4"></path>
                                            </svg>
                                        </span>Sign out all sessions
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0 pb-5">
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-row-dashed gy-5"
                                                               id="kt_table_users_login_session">
                                                            <thead
                                                                className="border-bottom border-gray-200 fs-7 fw-bolder">
                                                            <tr className="text-start text-muted text-uppercase gs-0">
                                                                <th className="min-w-100px">Location</th>
                                                                <th>Device</th>
                                                                <th>IP Address</th>
                                                                <th className="min-w-125px">Time</th>
                                                                <th className="min-w-70px">Actions</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="fs-6 fw-bold text-gray-600">
                                                            <tr>
                                                                <td>Australia</td>
                                                                <td>Chome - Windows</td>
                                                                <td>207.15.10.26</td>
                                                                <td>23 seconds ago</td>
                                                                <td>Current session</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Australia</td>
                                                                <td>Safari - iOS</td>
                                                                <td>207.18.35.398</td>
                                                                <td>3 days ago</td>
                                                                <td>
                                                                    <a href="#signout" data-kt-users-sign-out="single_user">Sign out</a>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>Australia</td>


                                                                <td>Chrome - Windows</td>


                                                                <td>207.39.41.167</td>


                                                                <td>last week</td>


                                                                <td>Expired</td>

                                                            </tr>
                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>

                                            </div>


                                            <div className="card pt-4 mb-6 mb-xl-9">

                                                <div className="card-header border-0">

                                                    <div className="card-title">
                                                        <h2>Logs</h2>
                                                    </div>


                                                    <div className="card-toolbar">

                                                        <button type="button" className="btn btn-sm btn-light-primary">

                                                            <span className="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                                                  fill="black"></path>
                                            <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                                                  fill="black"></path>
                                            <path opacity="0.3"
                                                  d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                                                  fill="black"></path>
                                            </svg>
                                        </span>
                                                            Download Report
                                                        </button>

                                                    </div>

                                                </div>


                                                <div className="card-body py-0">

                                                    <div className="table-responsive">

                                                        <table
                                                            className="table align-middle table-row-dashed fw-bold text-gray-600 fs-6 gy-5"
                                                            id="kt_table_users_logs">

                                                            <tbody>

                                                            <tr>

                                                                <td className="min-w-70px">
                                                                    <div className="badge badge-light-danger">500 ERR
                                                                    </div>
                                                                </td>


                                                                <td>POST /v1/invoice/in_4814_5425/invalid</td>


                                                                <td className="pe-0 text-end min-w-200px">24 Jun 2021,
                                                                    2:40 pm
                                                                </td>

                                                            </tr>


                                                            <tr>

                                                                <td className="min-w-70px">
                                                                    <div className="badge badge-light-success">200 OK
                                                                    </div>
                                                                </td>


                                                                <td>POST /v1/invoices/in_1599_5220/payment</td>


                                                                <td className="pe-0 text-end min-w-200px">10 Mar 2021,
                                                                    2:40 pm
                                                                </td>

                                                            </tr>


                                                            <tr>

                                                                <td className="min-w-70px">
                                                                    <div className="badge badge-light-danger">500 ERR
                                                                    </div>
                                                                </td>


                                                                <td>POST /v1/invoice/in_1810_5846/invalid</td>


                                                                <td className="pe-0 text-end min-w-200px">22 Sep 2021,
                                                                    5:30 pm
                                                                </td>

                                                            </tr>


                                                            <tr>

                                                                <td className="min-w-70px">
                                                                    <div className="badge badge-light-success">200 OK
                                                                    </div>
                                                                </td>


                                                                <td>POST /v1/invoices/in_3589_8317/payment</td>


                                                                <td className="pe-0 text-end min-w-200px">10 Mar 2021,
                                                                    6:43 am
                                                                </td>

                                                            </tr>


                                                            <tr>

                                                                <td className="min-w-70px">
                                                                    <div className="badge badge-light-danger">500 ERR
                                                                    </div>
                                                                </td>


                                                                <td>POST /v1/invoice/in_6648_9440/invalid</td>


                                                                <td className="pe-0 text-end min-w-200px">25 Jul 2021,
                                                                    9:23 pm
                                                                </td>

                                                            </tr>

                                                            </tbody>

                                                        </table>

                                                    </div>

                                                </div>

                                            </div>


                                            <div className="card pt-4 mb-6 mb-xl-9">

                                                <div className="card-header border-0">

                                                    <div className="card-title">
                                                        <h2>Events</h2>
                                                    </div>


                                                    <div className="card-toolbar">

                                                        <button type="button" className="btn btn-sm btn-light-primary">

                                                            <span className="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M19 15C20.7 15 22 13.7 22 12C22 10.3 20.7 9 19 9C18.9 9 18.9 9 18.8 9C18.9 8.7 19 8.3 19 8C19 6.3 17.7 5 16 5C15.4 5 14.8 5.2 14.3 5.5C13.4 4 11.8 3 10 3C7.2 3 5 5.2 5 8C5 8.3 5 8.7 5.1 9H5C3.3 9 2 10.3 2 12C2 13.7 3.3 15 5 15H19Z"
                                                  fill="black"></path>
                                            <path d="M13 17.4V12C13 11.4 12.6 11 12 11C11.4 11 11 11.4 11 12V17.4H13Z"
                                                  fill="black"></path>
                                            <path opacity="0.3"
                                                  d="M8 17.4H16L12.7 20.7C12.3 21.1 11.7 21.1 11.3 20.7L8 17.4Z"
                                                  fill="black"></path>
                                            </svg>
                                        </span>
                                                            Download Report
                                                        </button>

                                                    </div>

                                                </div>


                                                <div className="card-body py-0">

                                                    <table
                                                        className="table align-middle table-row-dashed fs-6 text-gray-600 fw-bold gy-5"
                                                        id="kt_table_customers_events">

                                                        <tbody>

                                                        <tr>

                                                            <td className="min-w-400px">
                                                                <a href="#payment" className="text-gray-600 text-hover-primary me-1">Sean Bean</a>has made payment to
                                                                <a href="#paymentcode" className="fw-bolder text-gray-900 text-hover-primary">#XRS-45670</a>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">10
                                                                Mar 2021, 6:43 am
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">Invoice
                                                                <a href="#declined" className="fw-bolder text-gray-900 text-hover-primary me-1">#LOP-45640</a>has been
                                                                <span className="badge badge-light-danger">Declined</span>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">20 Dec 2021, 11:30 am</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">Invoice
                                                                <a href="#status" className="fw-bolder text-gray-900 text-hover-primary me-1">#SEP-45656</a>status has changed from
                                                                <span className="badge badge-light-warning me-1">Pending</span>to
                                                                <span className="badge badge-light-info">In Progress</span>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">20 Dec 2021, 9:23 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">
                                                                <a href="#payment" className="text-gray-600 text-hover-primary me-1">Sean Bean</a>has made payment to
                                                                <a href="#payment_code" className="fw-bolder text-gray-900 text-hover-primary">#XRS-45670</a>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">10 Nov 2021, 8:43 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">
                                                                <a href="#payment" className="text-gray-600 text-hover-primary me-1">Brian Cox</a>has made payment to
                                                                <a href="#payment_code" className="fw-bolder text-gray-900 text-hover-primary">#OLP-45690</a>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">22 Sep 2021, 5:20 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">Invoice
                                                                <a href="#InProgress" className="fw-bolder text-gray-900 text-hover-primary me-1">#WER-45670</a>is
                                                                <span className="badge badge-light-info">In Progress</span>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">21 Feb 2021, 6:05 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">
                                                                <a href="#payment" className="text-gray-600 text-hover-primary me-1">Brian Cox</a>has made payment to
                                                                <a href="#payment_code" className="fw-bolder text-gray-900 text-hover-primary">#OLP-45690</a>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">20 Dec 2021, 6:05 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">Invoice
                                                                <a href="#status" className="fw-bolder text-gray-900 text-hover-primary me-1">#DER-45645</a>status has changed from
                                                                <span className="badge badge-light-info me-1">In Progress</span>to
                                                                <span className="badge badge-light-primary">In Transit</span>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">10 Nov 2021, 9:23 pm</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">
                                                                <a href="#payment" className="text-gray-600 text-hover-primary me-1">Brian Cox</a>has made payment to
                                                                <a href="#payment_code" className="fw-bolder text-gray-900 text-hover-primary">#OLP-45690</a>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">10 Mar 2021, 11:30 am</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="min-w-400px">Invoice
                                                                <a href="#Declined" className="fw-bolder text-gray-900 text-hover-primary me-1">#LOP-45640</a>has been
                                                                <span className="badge badge-light-danger">Declined</span>
                                                            </td>
                                                            <td className="pe-0 text-gray-600 text-end min-w-200px">15 Apr 2021, 5:30 pm</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="kt_activities" className="bg-body drawer drawer-end" data-kt-drawer="true"
                                 data-kt-drawer-name="activities" data-kt-drawer-activate="true"
                                 data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
                                 data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle"
                                 data-kt-drawer-close="#kt_activities_close" style={{width: "900"}}>
                                <div className="card shadow-none rounded-0">

                                    <div className="card-header" id="kt_activities_header">
                                        <h3 className="card-title fw-bolder text-dark">Activity Logs</h3>
                                        <div className="card-toolbar">
                                            <button type="button"
                                                    className="btn btn-sm btn-icon btn-active-light-primary me-n5"
                                                    id="kt_activities_close">

                                                <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                      transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                                      fill="black"></rect>
                                </svg>
                            </span>

                                            </button>
                                        </div>
                                    </div>


                                    <div className="card-body position-relative" id="kt_activities_body">

                                        <div id="kt_activities_scroll" className="position-relative scroll-y me-n5 pe-5"
                                             data-kt-scroll="true" data-kt-scroll-height="auto"
                                             data-kt-scroll-wrappers="#kt_activities_body"
                                             data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
                                             data-kt-scroll-offset="5px" style={{height: 181}}>

                                            <div className="timeline">

                                                <div className="timeline-item">

                                                    <div className="timeline-line w-40px"></div>


                                                    <div
                                                        className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                                        <div className="symbol-label bg-light">

                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                                                  fill="black"></path>
                                            <path
                                                d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>

                                                        </div>
                                                    </div>


                                                    <div className="timeline-content mb-10 mt-n1">

                                                        <div className="pe-3 mb-5">

                                                            <div className="fs-5 fw-bold mb-2">There are 2 new tasks for
                                                                you in �AirPlus Mobile APp� project:
                                                            </div>


                                                            <div className="d-flex align-items-center mt-1 fs-6">

                                                                <div className="text-muted me-2 fs-7">Added at 4:23 PM
                                                                    by
                                                                </div>


                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Nina Nilson">
                                                                    <img src="assets/media/avatars/150-11.jpg"
                                                                         alt="img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div
                                                                className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                                                                <a href="#Meeting" className="fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px">Meeting with customer</a>
                                                                <div className="min-w-175px pe-2">
                                                                    <span className="badge badge-light text-muted">Application Design</span>
                                                                </div>
                                                                <div
                                                                    className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                                                                    <div className="symbol symbol-circle symbol-25px">
                                                                        <img src="assets/media/avatars/150-3.jpg"
                                                                             alt="img" />
                                                                    </div>
                                                                    <div className="symbol symbol-circle symbol-25px">
                                                                        <img src="assets/media/avatars/150-11.jpg"
                                                                             alt="img" />
                                                                    </div>
                                                                    <div className="symbol symbol-circle symbol-25px">
                                                                        <div
                                                                            className="symbol-label fs-8 fw-bold bg-primary text-inverse-primary">A
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="min-w-125px pe-2">
                                                                    <span className="badge badge-light-primary">In Progress</span>
                                                                </div>
                                                                <a href="#View" className="btn btn-sm btn-light btn-active-light-primary">View</a>
                                                            </div>
                                                            <div
                                                                className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                                                                <a href="#Project" className="fs-5 text-dark text-hover-primary fw-bold w-375px min-w-200px">Project Delivery Preparation</a>
                                                                <div className="min-w-175px">
                                                                    <span className="badge badge-light text-muted">CRM System Development</span>
                                                                </div>
                                                                <div
                                                                    className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                                                                    <div className="symbol symbol-circle symbol-25px">
                                                                        <img src="assets/media/avatars/150-5.jpg"
                                                                             alt="img" />
                                                                    </div>
                                                                    <div className="symbol symbol-circle symbol-25px">
                                                                        <div
                                                                            className="symbol-label fs-8 fw-bold bg-success text-inverse-primary">B
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="min-w-125px">
                                                                    <span
                                                                        className="badge badge-light-success">Completed</span>
                                                                </div>
                                                                <a href="#View" className="btn btn-sm btn-light btn-active-light-primary">View</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M5.78001 21.115L3.28001 21.949C3.10897 22.0059 2.92548 22.0141 2.75004 21.9727C2.57461 21.9312 2.41416 21.8418 2.28669 21.7144C2.15923 21.5869 2.06975 21.4264 2.0283 21.251C1.98685 21.0755 1.99507 20.892 2.05201 20.7209L2.886 18.2209L7.22801 13.879L10.128 16.774L5.78001 21.115Z"
                                                  fill="black"></path>
                                            <path
                                                d="M21.7 8.08899L15.911 2.30005C15.8161 2.2049 15.7033 2.12939 15.5792 2.07788C15.455 2.02637 15.3219 1.99988 15.1875 1.99988C15.0531 1.99988 14.92 2.02637 14.7958 2.07788C14.6717 2.12939 14.5589 2.2049 14.464 2.30005L13.74 3.02295C13.548 3.21498 13.4402 3.4754 13.4402 3.74695C13.4402 4.01849 13.548 4.27892 13.74 4.47095L14.464 5.19397L11.303 8.35498C10.1615 7.80702 8.87825 7.62639 7.62985 7.83789C6.38145 8.04939 5.2293 8.64265 4.332 9.53601C4.14026 9.72817 4.03256 9.98855 4.03256 10.26C4.03256 10.5315 4.14026 10.7918 4.332 10.984L13.016 19.667C13.208 19.859 13.4684 19.9668 13.74 19.9668C14.0115 19.9668 14.272 19.859 14.464 19.667C15.3575 18.77 15.9509 17.618 16.1624 16.3698C16.374 15.1215 16.1932 13.8383 15.645 12.697L18.806 9.53601L19.529 10.26C19.721 10.452 19.9814 10.5598 20.253 10.5598C20.5245 10.5598 20.785 10.452 20.977 10.26L21.7 9.53601C21.7952 9.44108 21.8706 9.32825 21.9221 9.2041C21.9737 9.07995 22.0002 8.94691 22.0002 8.8125C22.0002 8.67809 21.9737 8.54505 21.9221 8.4209C21.8706 8.29675 21.7952 8.18392 21.7 8.08899Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n2">
                                                        <div className="overflow-auto pe-3">
                                                            <div className="fs-5 fw-bold mb-2">Invitation for crafting
                                                                engaging designs that speak human workshop
                                                            </div>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Sent at 4:23 PM
                                                                    by
                                                                </div>
                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Alan Nilson">
                                                                    <img src="assets/media/avatars/150-2.jpg" alt="img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z"
                                                fill="black"></path>
                                            <path opacity="0.3"
                                                  d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z"
                                                  fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n1">
                                                        <div className="mb-5 pe-3">
                                                            <a href="#newProject" className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Sent at 10:30 PM
                                                                    by
                                                                </div>
                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Jan Hummer">
                                                                    <img src="assets/media/avatars/150-6.jpg" alt="img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div
                                                                className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                                                                <div
                                                                    className="d-flex flex-aligns-center pe-10 pe-lg-20">
                                                                    <img alt="" className="w-30px me-3"
                                                                         src="assets/media/svg/files/pdf.svg" />
                                                                    <div className="ms-1 fw-bold">
                                                                        <a href="#FinanceGuidelines" className="fs-6 text-hover-primary fw-bolder">Finance KPI App Guidelines</a>
                                                                        <div className="text-gray-400">1.9mb</div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="d-flex flex-aligns-center pe-10 pe-lg-20">
                                                                    <img alt="" className="w-30px me-3"
                                                                         src="assets/media/svg/files/doc.svg" />
                                                                    <div className="ms-1 fw-bold">
                                                                        <a href="#ClientUAT" className="fs-6 text-hover-primary fw-bolder">Client UAT Testing Results</a>
                                                                        <div className="text-gray-400">18kb</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-aligns-center">
                                                                    <img alt="" className="w-30px me-3"
                                                                         src="assets/media/svg/files/css.svg" />
                                                                    <div className="ms-1 fw-bold">
                                                                        <a href="#FinanceReports" className="fs-6 text-hover-primary fw-bolder">Finance Reports</a>
                                                                        <div className="text-gray-400">20mb</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                  fill="black"></path>
                                            <path
                                                d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n1">
                                                        <div className="pe-3 mb-5">
                                                            <div className="fs-5 fw-bold mb-2">Task
                                                                <a href="#Task" className="text-primary fw-bolder me-1">#45890</a>merged with
                                                                <a href="#Dashboard" className="text-primary fw-bolder me-1">#45890</a>in �Ads Pro Admin Dashboard project:
                                                            </div>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Nina Nilson">
                                                                    <img src="assets/media/avatars/150-11.jpg" alt="img" />
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="timeline-item">

                                                    <div className="timeline-line w-40px"></div>


                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">

                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                  fill="black"></path>
                                            <path
                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n1">
                                                        <div className="pe-3 mb-5">
                                                            <div className="fs-5 fw-bold mb-2">3 new application design
                                                                concepts added:
                                                            </div>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Created at 4:23 PM
                                                                    by
                                                                </div>
                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Marcus Dotson">
                                                                    <img src="assets/media/avatars/150-3.jpg" alt="img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div
                                                                className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                                                <div className="overlay me-10">
                                                                    <div className="overlay-wrapper">
                                                                        <img alt="img" className="rounded w-150px"
                                                                             src="assets/media/stock/300x270/9.jpg" />
                                                                    </div>
                                                                    <div
                                                                        className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                        <a href="#Explore" className="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                                    </div>
                                                                </div>
                                                                <div className="overlay me-10">
                                                                    <div className="overlay-wrapper">
                                                                        <img alt="img" className="rounded w-150px"
                                                                             src="assets/media/stock/300x270/11.jpg" />
                                                                    </div>
                                                                    <div
                                                                        className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                        <a href="#Explore" className="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                                    </div>
                                                                </div>
                                                                <div className="overlay">
                                                                    <div className="overlay-wrapper">
                                                                        <img alt="img" className="rounded w-150px"
                                                                             src="assets/media/stock/300x270/6.jpg" />
                                                                    </div>
                                                                    <div
                                                                        className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                        <a href="#Explore"
                                                                           className="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                     viewBox="0 0 24 24" fill="none">
                                                                <path
                                                                    d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z"
                                                                    fill="black"></path>
                                                                <path opacity="0.3"
                                                                      d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z"
                                                                      fill="black"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n1">
                                                        <div className="pe-3 mb-5">
                                                            <div className="fs-5 fw-bold mb-2">New case
                                                                <a href="#NewCase"
                                                                   className="text-primary fw-bolder me-1">#67890</a>is
                                                                assigned to you in Multi-platform Database Design
                                                                project
                                                            </div>
                                                            <div className="overflow-auto pb-5">
                                                                <div className="d-flex align-items-center mt-1 fs-6">
                                                                    <div className="text-muted me-2 fs-7">Added at 4:23
                                                                        PM by
                                                                    </div>
                                                                    <a href="#AliceTan" className="text-primary fw-bolder me-1">Alice Tan</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3"
                                                  d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                  fill="black"></path>
                                            <path
                                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mb-10 mt-n1">
                                                        <div className="pe-3 mb-5">
                                                            <div className="fs-5 fw-bold mb-2">You have received a new
                                                                order:
                                                            </div>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Placed at 5:05 AM
                                                                    by
                                                                </div>
                                                                <div className="symbol symbol-circle symbol-25px"
                                                                     data-bs-toggle="tooltip" data-bs-boundary="window"
                                                                     data-bs-placement="top" title=""
                                                                     data-bs-original-title="Robert Rich">
                                                                    <img src="assets/media/avatars/150-14.jpg"
                                                                         alt="img" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="overflow-auto pb-5">
                                                            <div
                                                                className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                                                <span
                                                                    className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3"
                                                      d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z"
                                                      fill="black"></path>
                                                <path
                                                    d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z"
                                                    fill="black"></path>
                                                </svg>
                                            </span>
                                                                <div
                                                                    className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                                    <div className="mb-3 mb-md-0 fw-bold">
                                                                        <h4 className="text-gray-900 fw-bolder">Database
                                                                            Backup Process Completed!</h4>
                                                                        <div className="fs-6 text-gray-700 pe-7">Login
                                                                            into Metronic Admin Dashboard to make sure
                                                                            the data integrity is OK
                                                                        </div>
                                                                    </div>
                                                                    <a href="#Proceed" className="btn btn-primary px-6 align-self-center text-nowrap">Proceed</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="timeline-item">
                                                    <div className="timeline-line w-40px"></div>
                                                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                                                        <div className="symbol-label bg-light">
                                                            <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z"
                                                fill="black"></path>
                                            <path opacity="0.3"
                                                  d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z"
                                                  fill="black"></path>
                                            <path opacity="0.3"
                                                  d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z"
                                                  fill="black"></path>
                                            </svg>
                                        </span>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-content mt-n1">
                                                        <div className="pe-3 mb-5">
                                                            <div className="fs-5 fw-bold mb-2">New order
                                                                <a href="#NewOrder" className="text-primary fw-bolder me-1">#67890</a>is placed for Workshow Planning & Budget Estimation
                                                            </div>
                                                            <div className="d-flex align-items-center mt-1 fs-6">
                                                                <div className="text-muted me-2 fs-7">Placed at 4:23 PM
                                                                    by
                                                                </div>
                                                                <a href="#title" className="text-primary fw-bolder me-1">Jimmy Bold</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer py-5 text-center" id="kt_activities_footer">
                                        <a href="../../demo4/dist/pages/profile/activity.html"
                                           className="btn btn-bg-body text-primary">View All Activities
                                            <span className="svg-icon svg-icon-3 svg-icon-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1"
                                  transform="rotate(-180 18 13)" fill="black"></rect>
                            <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="black"></path>
                            </svg>
                        </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default SpeakersDetail;