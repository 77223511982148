import React from 'react';
import EventData from "../api/get-events";

const UpcomingEvents = (props) => {

    const {constData} = props;

    return (
        <div className="tab-pane fade active show" role="tabpanel" id="upcoming-events">
            <div className="row g-6 g-xl-9">
                <EventData eventType={"upcoming"} constData={constData}/>
            </div>
        </div>
    );
};

export default UpcomingEvents;