import React, {useEffect,useState} from 'react';
import {NavLink} from "./SignUpScreenStyle";
import {useTranslation} from "react-i18next";
import ls from 'localstorage-slim';
const {REACT_APP_HOME_URL} = process.env;

const SignUpScreen = (props) => {

    const { t } = useTranslation();

    const {SendVerificationCode, handleEmailChange, errorMessage, loginText, constData} = props;

    const [LoginTypeState, setLoginTypeState] = useState("O");

    var eventId = ls.get("eventId");
    var eventType = ls.get("eventType");
    var PreLoginSession = ls.get("PreLoginSession");
    var showButton = false;
    var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

    useEffect(() => {

        if( null != constData ) {

            setLoginTypeState( constData.LoginType );

            if (undefined !== constData.LoginType && 'P' === constData.LoginType && PreLoginSession !== undefined && PreLoginSession !== null && PreLoginSession !== '') {
                showButton = false;
                window.location.href = REACT_APP_HOME_URL + '/';
            } else if ((undefined !== constData.LoginType && 'P' !== constData.LoginType) || undefined === constData.LoginType) {
                showButton = true;
                if (GetEventsUserData !== undefined && GetEventsUserData !== null) {
                    GetEventsUserData.map(function (el) {
                        if (el.eventId === eventId && el.UserLoginSession !== null && el.UserLoginSession !== undefined && el.UserLoginSession !== "") {
                            window.location.href = REACT_APP_HOME_URL + '/dashboard';
                        }
                        return el;
                    })
                }
            }
        }
    }, []);

    const ChangeEvent = () => {
        if( LoginTypeState === "O" ){
            return <div className="text-gray-600 text-center fw-bold fs-4">{t("To change event,")} <NavLink to={`${REACT_APP_HOME_URL}/`}>{t("click here")}</NavLink> {t("to go back to the Events List page")}</div>
        }
    }

    const manageOnSkip = () => {
        ls.config.encrypt = true;
        ls.set('login_status','success');
        window.location.href = REACT_APP_HOME_URL+'/dashboard';
    }

    const SkipLogin = () => {
        if( LoginTypeState !== "P" && eventType !== undefined && Number( eventType ) !== 1 ){
            return <a className={"indicator-label"} onClick={manageOnSkip} href="#skip"><div className="btn btn-lg btn-secondary w-100 mb-5"><span className="indicator-label">{t("Skip")}</span></div></a>
        }
    }

    return(
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form className="form w-100" onSubmit={SendVerificationCode} noValidate="novalidate" id="kt_sign_in_form" action="#">
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">{loginText}</h1>
                    <div className="text-gray-600 fw-bold d-none fs-4">{t("Enter your email address below to get started.")}</div>
                </div>
                <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">{t("Email")}</label>
                    {( undefined !== props.UserEmail && '' !== props.UserEmail ) ? <input className="form-control form-control-lg form-control-solid" value={props.UserEmail} onChange={handleEmailChange} type="text" name="email" autoComplete={"off"} /> : <input className="form-control form-control-lg form-control-solid" onChange={handleEmailChange} type="text" name="email" autoComplete={"off"} />}

                    <span className={"error-message"} style={{color: 'red'}}>{errorMessage}</span>
                </div>
                <div className="text-center">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" data-button-show={showButton}>
                        <span className="indicator-label">{t("Get Started")}</span>
                        <span className="indicator-progress">{t("Please wait...")}<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    </button>
                    <SkipLogin />
                </div>
                <ChangeEvent />
            </form>
        </div>
    );
}

export default SignUpScreen;