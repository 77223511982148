import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ls from 'localstorage-slim';
import AgendaCard from "../template/agenda-card";
import BackButton from "../template/backbutton";
import PageTitle from "../template/PageTitle";


class ScheduleWatchLive extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)
        var eventId = ls.get("eventId") !== null ? ls.get("eventId") : '';
        var agendaId = ls.get("agendaId") !== null ? ls.get("agendaId") : '';
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var agendaDetail = [];
        var ProfileId = '';
        var AgendaLocationsIds = [];
        var AgendaLocations = [];
        var AgendaCategoriesIds = [];
        var AgendaCategories = [];
        var EventAgendaLive = false;
        var biodata_id = '';
        var biodata = '';
        var event_small_aux = '';
        var event_small_aux_data = '';
        var EventLiveQaAndPoll = false;
        var aux_fields_ids = [];
        var aux_fields = [];

        if( GetEventsUserData !== null && GetEventsUserData !== undefined){
            GetEventsUserData.some(function(el) {
                if( Number(el.eventId ) ===  Number( eventId ) ){
                    var UserData = el.UserProfile;

                    event_small_aux = el.event_small_aux;
                    event_small_aux_data = el.event_small_aux_data;

                    if( event_small_aux !== '' && event_small_aux !== undefined ){
                        event_small_aux.map(( small_aux ) =>{
                            if( small_aux.fk_event_id === eventId && ( small_aux.fk_feature_id === "1" || small_aux.fk_feature_id === "1001" || small_aux.fk_feature_id === "1002" || small_aux.fk_feature_id === "1003" || small_aux.fk_feature_id === "1004" || small_aux.fk_feature_id === "1005") && small_aux.status === "A" ){
                                if( small_aux.fk_field_type_id === "19" ){
                                    biodata_id = small_aux.small_field_id;
                                } else {
                                    var data = {
                                        small_field_id: small_aux.small_field_id,
                                        field_name: small_aux.field_name,
                                        small_field_type: small_aux.fk_field_type_id
                                    }
                                    aux_fields_ids.push(data);
                                }
                            }
                        });
                    }

                    if( event_small_aux_data !== '' && event_small_aux_data !== undefined ){
                        event_small_aux_data.map( ( aux_data ) => {
                            if( aux_data.fk_record_id === agendaId && aux_data.status === "A" ) {
                                if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === biodata_id) {
                                    biodata = aux_data.value
                                } else if (aux_fields_ids !== undefined && aux_fields_ids.length > 0) {
                                    aux_fields_ids.map((aux_field) => {
                                        if (aux_data.fk_small_field_id && aux_data.fk_small_field_id === aux_field.small_field_id) {
                                            aux_data = {
                                                field_type: aux_field.small_field_type,
                                                field_name: aux_field.field_name,
                                                field_value: aux_data.value
                                            }
                                            aux_fields.push(aux_data);
                                        }
                                    });
                                }
                            }
                        });
                    }

                    if( undefined !== el.EventAgendaLiveSettings && "" !== el.EventAgendaLiveSettings ){
                        el.EventAgendaLiveSettings.map(function(LiveSetting) {
                            if( agendaId === LiveSetting.fk_agenda_id && LiveSetting.status === "A" ){
                                EventAgendaLive = true;
                            }
                        });
                    }

                    if( EventAgendaLive && undefined !== el.LiveSessionPreference && "" !== el.LiveSessionPreference ){
                        el.LiveSessionPreference.map(function(LiveSetting) {
                            if( agendaId === LiveSetting.fk_agenda_id && LiveSetting.open_close.toLowerCase() === "o" && LiveSetting.status === "A" ){
                                EventLiveQaAndPoll = true;
                            }
                        });
                    }

                    if( UserData !== undefined && ProfileId === "" ){
                        ProfileId = UserData.profile_id;
                    }
                    if( el.AgendasData !== '' && el.AgendasData !== undefined ){
                        el.AgendasData.some(function(agenda) {if( agendaId !== '' && agendaId !== undefined &&  Number(agenda.agenda_id ) ===  Number(agendaId ) && agenda.status === 'A' ) {
                            agendaDetail = agenda;
                        }})
                    }
                    if( el.EventAgendaLocations !== '' && el.EventAgendaLocations !== undefined ){
                        AgendaLocationsIds = [];
                        el.EventAgendaLocations.some(function(LocationsIds) { if( agendaId !== '' && agendaId !== undefined && Number( LocationsIds.fk_agenda_id ) === Number( agendaId ) && LocationsIds.status === 'A' ) {
                            AgendaLocationsIds.push(LocationsIds.fk_location_id);
                        }})
                        if( el.AgendaLocations !== '' && el.AgendaLocations !== undefined ){
                            AgendaLocations = [];
                            el.AgendaLocations.map(function(LocationsIds) {
                                AgendaLocationsIds.some(function(LocationId) {
                                    if (LocationsIds !== '' && LocationsIds !== undefined && Number( LocationsIds.location_id ) === Number( LocationId ) ) {
                                        AgendaLocations.push(LocationsIds.location_name);
                                    }
                                })
                                return LocationsIds;
                            })
                        }
                    }
                    if( el.EventAgendaCategories !== '' && el.EventAgendaCategories !== undefined ){
                        AgendaCategoriesIds = [];
                        el.EventAgendaCategories.some(function(CategoriesIds) { if( agendaId !== '' && agendaId !== undefined && Number( CategoriesIds.fk_agenda_id ) === Number( agendaId ) && CategoriesIds.status === 'A' ) {
                            AgendaCategoriesIds.push(CategoriesIds.fk_category_id);
                        }})
                        if( el.EventCategories !== '' && el.EventCategories !== undefined ){
                            AgendaCategories = [];
                            el.EventCategories.map(function(CategoriesIds) {
                                AgendaCategoriesIds.some(function(CategoriesId) {
                                    if (CategoriesIds !== '' && CategoriesIds !== undefined && CategoriesIds.category_id === CategoriesId) {
                                        var categoryData = {
                                            categoryId: CategoriesIds.category_id,
                                            categoryName: CategoriesIds.category_name,
                                            categoryColor: CategoriesIds.category_color_code,
                                        }
                                        AgendaCategories.push(categoryData);
                                    }
                                })
                                return CategoriesIds;
                            })
                        }
                    }
                }
            })
        }

        this.state = {
            agendaId: agendaId,
            eventId: eventId,
            agendaDetail: agendaDetail,
            AgendaCategories: AgendaCategories,
            AgendaLocations: AgendaLocations,
            ProfileId: ProfileId,
            biodata: biodata,
            aux_fields: aux_fields,
            EventLiveQaAndPoll: EventLiveQaAndPoll,
            constData: this.props.constData
        }
    }

    getId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    render(){
        var md5 = require('md5');
        var agendaDetail = this.state.agendaDetail !== null ? this.state.agendaDetail : '';
        var agendaId = this.state.agendaId !== null ? this.state.agendaId : '';
        var ProfileId = this.state.ProfileId !== null ? this.state.ProfileId : '';
        var ZoomLink = agendaDetail.broadcast_view_link !== null && agendaDetail.broadcast_platform.toLowerCase() === 'z' ? agendaDetail.broadcast_view_link : '';
        var VideoPlatform = agendaDetail.broadcast_platform !== undefined ? agendaDetail.broadcast_platform.toLowerCase() : '';
        var videoId = this.getId(agendaDetail.broadcast_view_link, agendaDetail.broadcast_platform, agendaDetail.broadcast_zoom_info);
        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Schedule"/></h1>
                                    <span className="fw-bold opacity-50">(session times are in EDT)</span>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <BackButton constData={this.state.constData} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card-body pt-9 pb-0">
                                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                                <div className="flex-grow-1">
                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                        <AgendaCard constData={this.state.constData} EventLiveQaAndPoll={this.state.EventLiveQaAndPoll} aux_field={this.state.biodata} aux_fields={this.state.aux_fields} agendaDetail={this.state.agendaDetail} AgendaLocations={this.state.AgendaLocations} AgendaCategories={this.state.AgendaCategories} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    {agendaDetail.broadcast_view_link !== undefined && agendaDetail.broadcast_view_link !== "" ? <div className="card card-xl-stretch mb-xl-9">
                                        <div className="card-header">
                                            <h3 className="card-title fw-bolder text-dark">Broadcast Demo Session</h3>
                                        </div>
                                        <div className="row card-body">
                                            <div className="col-12 ratio ratio-16x9">
                                                { videoId !== '' && videoId !== null ?
                                                <iframe src={"//www.youtube.com/embed/" + videoId} title="YouTube video" allowFullScreen></iframe> : ""}
                                                { VideoPlatform !== "" && VideoPlatform === "f" ? <iframe src={ "https://www.facebook.com/plugins/video.php?height=314&href=" + agendaDetail.broadcast_view_link + "&show_text=false&width=560&t=0"} border="0" width="100%" height="500"></iframe> : ""}
                                                    </div>
                                        </div>
                                    </div> : ''}
                                    {ZoomLink ? <div className="card card-xl-stretch mb-xl-9">
                                        <div className="card-header">
                                            <h3 className="card-title fw-bolder text-dark">Broadcast Demo Session</h3>
                                        </div>
                                        <div className="row card-body">
                                            <div className="col-12 ratio ratio-16x9">
                                                <iframe src={"https://zoom.us/wc/join/99652950380?wpk=wcpk54ab258edba74aa9bafedb938aff5e7b"}
                                                        title="YouTube video" allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </div> : ""}
                                    {agendaDetail.broadcast_qa_link !== undefined && agendaDetail.broadcast_qa_link !== '' ? <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                        <div className="card-header cursor-pointer">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">LIVE Questions & Polls</h3>
                                            </div>
                                        </div>
                                        <div className="row card-body p-0">
                                            <iframe src={agendaDetail.broadcast_qa_link} border="0" width="100%"
                                                    height="500"></iframe>
                                        </div>
                                    </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default ScheduleWatchLive;