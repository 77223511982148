import React, { Component } from 'react';
import axios from 'axios';
import ls from 'localstorage-slim';
import {getRandomNumber} from "../getRandomNumber";

const {
    PUBLIC_URL,
    REACT_APP_LOCAL_ACCESS,
    REACT_APP_HOME_URL,
    REACT_APP_JSON_RPC,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_VERSION,
} = process.env;

class GetSidebarMenu extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        const pathname = window.location.pathname;
        super(props)
        var UserData = [];
        var ProfileId = '';
        var DataFetch = true;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        const {constData} = props;

        this.state = {
            eventId: ls.get('eventId'),
            current_path: pathname,
            UserData: UserData,
            ProfileId: ProfileId,
            eeuserdata: GetEventsUserData,
            DataFetch: DataFetch,
            constData: props.constData,
        }

        var current_path = window.location.pathname;

        if (null !== constData || undefined !== constData ) {

            var login_status = ls.get("login_status");

            if( login_status !== 'success' ) {
                if(
                    constData.LoginType !== undefined &&
                    'P' === constData.LoginType
                ) {
                    UserData = ls.get("PreLoginUserProfile");
                    ProfileId = (undefined !== UserData && '' !== UserData && null !== UserData) ? UserData.profile_id : '';
                    if( UserData === '' || null === UserData ) {
                        window.location.href = REACT_APP_HOME_URL+'/login';
                    } else if( '' !== UserData && UserData.status === 'R' && current_path !== REACT_APP_HOME_URL + '/my-profile-edit' ) {
                        window.location.href =REACT_APP_HOME_URL + '/my-profile-edit';
                    }
                } else {
                    var eventType =  ls.get('eventType');
                    if(GetEventsUserData !== undefined && GetEventsUserData !== null ){

                        let getSelectedEventId = ls.get('eventId');
                        GetEventsUserData.map(function (el) {

                            let userProfileStatus = (undefined !== el.UserProfile && null !== el.UserProfile && '' !== el.UserProfile) ? el.UserProfile.status : '';
                            if(
                                undefined !== getSelectedEventId &&
                                undefined !== el.eventId &&
                                Number( el.eventId ) === Number( getSelectedEventId ) &&
                                undefined !== el.UserProfile &&
                                null !== el.UserProfile &&
                                userProfileStatus === 'R'
                            ) {
                                if(
                                    current_path !== REACT_APP_HOME_URL + '/login' &&
                                    current_path !== REACT_APP_HOME_URL + '/' &&
                                    current_path !== REACT_APP_HOME_URL + '/my-profile-edit'
                                ) {
                                    window.location.href =REACT_APP_HOME_URL+"/my-profile-edit";
                                }
                            } else {

                                if( undefined !== getSelectedEventId &&
                                    undefined !== el.eventId &&
                                    Number( el.eventId ) === Number( getSelectedEventId ) &&
                                    undefined !== el.UserProfile
                                ) {
                                    UserData = el.UserProfile;
                                    ProfileId = UserData.profile_id;
                                    if( undefined !== el.event_home_page &&
                                        undefined !== el.event_features &&
                                        undefined !== el.feature_masters &&
                                        undefined !== el.app_group_connections &&
                                        undefined !== el.app_groups &&
                                        undefined !== el.app_group_users &&
                                        undefined !== el.event_small_aux &&
                                        undefined !== el.event_small_aux_data
                                    ){
                                        DataFetch = false;
                                    }

                                    if( (
                                            el.UserLoginSession === null ||
                                            el.UserLoginSession === undefined ||
                                            el.UserLoginSession === ""
                                        ) &&
                                        eventType !== Number(2) &&
                                        eventType !== Number( 0 )
                                    ) {
                                        window.location.href = REACT_APP_HOME_URL;
                                    }
                                } else if (
                                    (
                                        el.UserLoginSession === null ||
                                        el.UserLoginSession === undefined ||
                                        el.UserLoginSession === ""
                                    ) &&
                                    eventType !== Number(2) &&
                                    eventType !== Number( 0 ) &&
                                    ( undefined === ls.get('eventId') || null === ls.get('eventId') )
                                ) {
                                    window.location.href = REACT_APP_HOME_URL;
                                }
                            }
                            return el;
                        });
                    } else {
                        var user_login_status = ls.get('user_login_status');
                        if(( user_login_status === null || user_login_status === undefined || user_login_status === "" ) && eventType !== Number(2) && eventType !== Number( 0 ) && ( undefined === ls.get('eventId') || null === ls.get('eventId') ) ){
                            window.location.href = REACT_APP_HOME_URL;
                        }
                    }
                }
            }

            this.setState({
                eventId: ls.get('eventId'),
                current_path: pathname,
                UserData: UserData,
                ProfileId: ProfileId,
                eeuserdata: GetEventsUserData,
                DataFetch: DataFetch
            })
        } else {
            window.location.href =REACT_APP_HOME_URL+'/login';
        }
    }

    componentDidMount() {

        let constData = this.state.constData

        if(constData) {
            this.GetUpdates();
        }
    }

    GetUpdates () {

        let constData = this.state.constData

        if(constData) {

            const isLocalAccess = REACT_APP_LOCAL_ACCESS;
            ls.config.encrypt = true;
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            var UserData = [];
            var ProfileId = '';
            var DataFetch = true;
            var MenuExists = false;
            var event_type = ls.get('eventType');

            if(GetEventsUserData !== undefined && GetEventsUserData !== null ){
                GetEventsUserData.map(function (el) {
                    if( Number( el.eventId ) === Number( ls.get('eventId') ) && ( undefined !== el.UserProfile || ( undefined !== event_type && Number(1) !== Number(event_type) ) ) ){
                        if( undefined !== el.UserProfile && null !== el.UserProfile && "" !== el.UserProfile ) {
                            UserData = el.UserProfile;
                            ProfileId = UserData.profile_id;
                        }
                        if( undefined !== el.event_home_page && undefined !== el.event_features && undefined !== el.feature_masters ){
                            DataFetch = false;
                        }
                    }
                    return el;
                });
            }

            if( ( GetEventsUserData === null && Number(isLocalAccess) === 0 ) || DataFetch ) {
                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "getUpdates",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_small_aux": "",
                            "profile_id": this.state.ProfileId,
                            "event_small_aux_data": "",
                            "event_settings": "",
                            "app_groups": "",
                            "app_group_connections": "",
                            "app_group_users": "",
                            "event_home_page": "",
                            "event_features": "",
                            "feature_masters": "",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "event_id": ls.get('eventId')
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL + '/functions.php?data=' + JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {
                    var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
                    var eventId = ls.get('eventId');
                    var result = res.data !== undefined ? res.data[0].result : "";

                    if (undefined !== result && "" !== result && "undefined" !== result.code && Number(result.code) === 200) {
                        if ( undefined !== GetEventsUserData && null !== GetEventsUserData) {
                            GetEventsUserData = GetEventsUserData.map(function (el) {
                                if (Number(el.eventId) === Number(eventId)) {
                                    MenuExists = true;
                                    el.event_home_page = result.event_home_page;
                                    el.event_features = result.event_features;
                                    el.feature_masters = result.feature_masters;
                                    el.app_group_connections = result.app_group_connections;
                                    el.app_groups = result.app_groups;
                                    el.app_group_users = result.app_group_users;
                                    el.event_small_aux = result.event_small_aux;
                                    el.event_small_aux_data = result.event_small_aux_data;
                                }
                                return el;
                            })
                            if (!MenuExists) {
                                var GeneralSettings = {
                                    eventId: eventId,
                                    event_home_page: result.event_home_page,
                                    event_features: result.event_features,
                                    feature_masters: result.feature_masters,
                                    app_group_connections: result.app_group_connections,
                                    app_groups: result.app_groups,
                                    app_group_users: result.app_group_users,
                                    event_small_aux: result.event_small_aux,
                                    event_small_aux_data: result.event_small_aux_data,
                                }
                                GetEventsUserData.push(GeneralSettings);
                            }
                        } else {
                            GetEventsUserData = [{
                                eventId: eventId,
                                event_home_page: result.event_home_page,
                                event_features: result.event_features,
                                feature_masters: result.feature_masters,
                                app_group_connections: result.app_group_connections,
                                app_groups: result.app_groups,
                                app_group_users: result.app_group_users,
                                event_small_aux: result.event_small_aux,
                                event_small_aux_data: result.event_small_aux_data,
                            }]
                        }
                        ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
                        this.setState({eeuserdata: GetEventsUserData});
                    }
                }).catch(error => {});
            }
        }
    }

    GlobalMenu = () => {

        let constData = this.state.constData;

        if(!constData) {
            return "";
        }

        ls.config.encrypt = true;
        var UserData = [];
        var ProfileId = '';
        var GetEventsUserData = this.state.eeuserdata;

        if( constData.LoginType !== undefined && 'P' === constData.LoginType ) {
            UserData = ls.get("PreLoginUserProfile");
            ProfileId = (undefined !== UserData && '' !== UserData && null !== UserData) ? UserData.profile_id : '';
        } else {
            if(GetEventsUserData !== undefined && GetEventsUserData !== null){
                GetEventsUserData.map(function (el) {
                    if( Number( el.eventId ) === Number( ls.get('eventId') ) && el.UserProfile !== undefined){
                        UserData = el.UserProfile;
                        ProfileId = UserData.profile_id;
                        //dataexists = false
                    }
                    return el;
                });
            }
        }

        var MenuItem = [];
        var appGroupUsers = null;
        var app_group_connections = null;
        if( GetEventsUserData !== undefined && GetEventsUserData !== null ){
            var eventId = ls.get('eventId');
            GetEventsUserData.map( ( el ) => {
                if( el.eventId === eventId && el.event_home_page !== undefined && constData.AvailableSideMenu !== undefined) {
                    el.event_home_page.map(function (data) {
                        constData.AvailableSideMenu.map(function (Menu) {
                            if ( Number(data.fk_feature_id) === Number(Menu.id) ) {
                                let MenuData = {
                                    ...data,
                                    ...{menuIcon: Menu.icon},
                                    ...{menuUrl: Menu.url}
                                };
                                MenuItem.push(MenuData);
                            }
                            return Menu;
                        });
                        return data;
                    });
                }

                if( Number( el.eventId ) === Number( eventId ) && el.UserProfile !== undefined){
                    var UserData = el.UserProfile;
                    ProfileId = (undefined !== UserData) ?  UserData.profile_id : '';
                }
                if( Number( el.eventId ) === Number( eventId ) ){
                    appGroupUsers = el.app_group_users;
                    app_group_connections = el.app_group_connections;
                }

                return el;
            });
        }

        MenuItem.sort((a, b) => (Number(a.sort_order) > Number(b.sort_order)) ? 1 : -1);
        MenuItem = [...new Set(MenuItem)];
        var uniqueueMenu = [];
        MenuItem.map( (Menu) => {
            if( uniqueueMenu.length === 0 ){
                uniqueueMenu.push(Menu);
            }else{
                var exists = true;
                uniqueueMenu.map( (id) => {
                    if( id.menu_id === Menu.menu_id){
                        exists = false
                    }
                });
                if( exists ){
                    uniqueueMenu.push(Menu);
                }
            }
            return Menu;
        });

        return (
            uniqueueMenu.map( (Menu) => {

                if ( Number(Menu.is_private) === Number( 1 ) && app_group_connections !== undefined && app_group_connections !== null && appGroupUsers !== undefined && appGroupUsers !== null ) {
                    var display = false;
                    app_group_connections.map((connection) => {
                        if ( Number( connection.fk_feature_id ) === Number( Menu.fk_feature_id ) && connection.status === "A" ) {
                            appGroupUsers.map((group) => {
                                if ( Number( group.fk_user_id ) === Number( ProfileId ) && Number( group.fk_group_id ) === Number( connection.fk_group_id )  ) {
                                    display = true;
                                }

                                return group;
                            });
                        }
                        return connection;
                    })
                }

                var getPageTitle = Menu.menu_name;
                constData.AvailableSideMenu.map(function (AvailMenu) {
                    if ( Number(Menu.fk_feature_id) === Number(AvailMenu.id) && Menu.menuUrl === AvailMenu.url ) {
                        getPageTitle = (Menu.listing_menu_title !== '' && undefined !== Menu.listing_menu_title ) ? Menu.listing_menu_title : Menu.menu_name;
                    }
                    return Menu;
                })

                if ( Number(Menu.is_private) !== Number(1) || display) {
                    var active = "";
                    const parent_page = ls.get("parent_page");
                    if(
                        Menu.menuUrl === parent_page &&
                        ( this.state.current_path === REACT_APP_HOME_URL+'/schedule-details' ||
                        this.state.current_path === REACT_APP_HOME_URL+'/attendees-details' ||
                        this.state.current_path === REACT_APP_HOME_URL+'/speaker-details' )
                    ) {
                        active = "active";
                    } else if( this.state.current_path === Menu.menuUrl ) {
                        active = "active";
                    }

                    return (
                        <div key={Menu.fk_feature_id} className={"menu-item py-3 menu-item-" + Menu.fk_feature_id}>
                            <a href={Menu.menuUrl}
                               className={`menu-link menu-center ` + active}
                               title={getPageTitle} data-bs-toggle="tooltip" data-bs-trigger="hover"
                               data-bs-dismiss="click" data-bs-placement="right">
                                <span className="menu-icon me-0">
                                    <i className={"bi fs-1 " + Menu.menuIcon}></i>
                                </span>
                            </a>
                        </div>
                    );
                }

                return '';
            })
        )
    }

    render() {
        return this.GlobalMenu();
    }
}
export default GetSidebarMenu;