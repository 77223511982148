import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
    text-decoration: none;
    border-bottom-width: 2px;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: color .2s ease,background-color .2s ease;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active {
        color: #4d4dff;
    }
`;