import React from 'react';
import blank from '../../assets/media/avatars/blank.png';
import ls from 'localstorage-slim';
const {REACT_APP_HOME_URL, REACT_APP_URL, REACT_APP_API_URL} = process.env;

const ProfileCard = (props) => {
    ls.config.encrypt = true;
    const {user, constData} = props;

    var userSalut = user.salut !== undefined ? user.salut : '';
    var userFirstName = user.fname !== undefined ? user.fname : '';
    var userLastName = user.lname !== undefined ? user.lname : '';
    var user_image = blank;

    let baseURL = ( null != constData  ) ? constData.BaseUrl: REACT_APP_URL+REACT_APP_API_URL+'/';

    return (
        <div className="card mb-5 mb-xl-8">
            <div className="card-body">
                <div className="d-flex flex-center flex-column py-5">
                    <div className="symbol symbol-160px symbol-circle mb-7">
                        <img src={user.pic ? baseURL + "uploads/account/profile/Thumbs/" + user.pic : user_image} alt={userFirstName} />
                    </div>
                    <a href={REACT_APP_HOME_URL+"/my-profile"} className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-2">{userSalut} {userFirstName} {userLastName}</a>
                    <p className="fs-5 text-gray-600 fw-bold mb-3">{user.title ? user.title : ''}</p>
                    <div className="mb-6">
                        <div className="badge badge-lg badge-light-primary d-inline-block">{user.company ? user.company : ""}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;