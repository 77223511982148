import React, {useState} from 'react';
import Header from "../template/header";
import SideMenu from "../template/sideMenu";
import Footer from "../template/footer";
import {useTranslation} from "react-i18next";
import SpeakersData from "../api/getSpeakers";
import {format} from "date-fns";
import AgendaData from "../api/get-agenda";
import manageAPICall from "../manageAPICall";

const {REACT_APP_HOME_URL} = process.env;

const Dashboard = (props) => {
    const { t } = useTranslation();
    const [displaySpeaker, setDisplaySpeaker] = useState(false);
    var speakersLimit = 24;
    var date = new Date();
    date = date !== undefined ? format(date, 'MMM dd, yyyy') : '';

    const {constData} = props;

    setTimeout(()=>{
        new manageAPICall().getAllAPIsync(false, true, true ,constData);

        if(!displaySpeaker){
            setDisplaySpeaker(this);
        }
    },1000);

    return (
        <>
            <SideMenu constData={constData}/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <Header constData={constData}/>
                <div className="card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover" style={{backgroundColor: "rgb(29,93,175,0.6)",backgroundSize: "auto 100%"}}>
                    <div className="card-body container-xxl pt-10 pb-8">
                        <div className="col-md-12">
                            <h1 className="fw-bolder mb-3 text-white">{t('Welcome')}</h1>
                            <p className="fw-bold text-white">{t('We build EventEdge apps with both organizers and attendees in mind. We make management effortless and focus on a great experience for attendees. Learn more about how the app works for attendee and organizers. We build every app custom for each client. You can pick your features, designs and add your own custom features. Our app matches your event, not the other way around.')}</p>
                        </div>
                    </div>
                </div>
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="container-xxl" id="kt_content_container">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-6 col-xl-6">
                                <div className="card bgi-no-repeat card-xl-stretch mb-xl-8" style={{backgroundPosition:"right top", backgroundSize: "30% auto", backgroundImage:"url(assets/media/svg/shapes//abstract-4.svg)"}}>
                                    <div className="card-body">
                                        <a href={REACT_APP_HOME_URL+"/attendees"} className="card-title fw-bolder text-hover-primary text-gray-900 fs-3">{t('Attendees')}</a>
                                        <p className="text-dark-75 fw-bold fs-5 m-0 mt-3">{t('Create a headline that is informative')}
                                            <br />{t('and will capture readers')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6">
                                <div className="card bgi-no-repeat card-xl-stretch mb-5 mb-xl-8" style={{backgroundPosition: "right top", backgroundSize: "30% auto", backgroundImage: "url(assets/media/svg/shapes//abstract-1.svg)"}}>
                                    <div className="card-body">
                                        <a href={REACT_APP_HOME_URL+"/speakers"} className="card-title fw-bolder text-hover-primary text-gray-900 fs-3">{t('Speakers')}</a>
                                        <p className="text-dark-75 fw-bold fs-5 m-0 mt-3">{t('AirWays - A Front-end solution for airlines build with ReactJS')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-5 g-xl-8">
                            <div className="col-xxl-4">
                                <div className="card card-xxl-stretch">
                                    <div className="card-header border-0 py-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bolder fs-3 mb-1">{t('Today Schedule')}</span>
                                            <span className="text-muted fw-bold fs-7">{date}</span>
                                        </h3>

                                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Full Schedule">
                                            <a href="schedule" className="btn btn-sm btn-light btn-active-primary">{t('Full Schedule')}</a>
                                        </div>
                                    </div>
                                    <div className="card-body pt-6">
                                        <AgendaData constData={constData} Dashboard={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-8">
                                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                    <SpeakersData constData={constData} Pagination={false} RelatedSpeakers={true} agendaId={0} speakersLimit={speakersLimit} activePage={1} perPage={12} FeatureId={4} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer constData={constData} />
            </div>
        </>
    );
};

export default Dashboard;