import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ProfileDetails from "../template/profile-details";
import ProfileCard from "../template/profile-card";
import ls from 'localstorage-slim';
import PageTitle from "../template/PageTitle";
import {withTranslation} from "react-i18next";
const {REACT_APP_HOME_URL} = process.env;

class ProfilePage extends Component {

    constructor(props){
        super(props)
        ls.config.encrypt = true;

        var eventId = ls.get("eventId");
        var userEmail = '';
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var UserData = [];
        if(GetEventsUserData !== undefined){
            var SpeakersData = [];
            GetEventsUserData.map(function (el) {
                if(el.eventId === eventId && el.UserProfile !== undefined){
                    if( userEmail === undefined || userEmail === '' ){
                        userEmail = el.userEmail;
                    }
                    return UserData = el.UserProfile;
                }
                return el;
            });
        }
        this.state = {
            user: UserData,
            eventId: eventId,
            userEmail: userEmail,
            constData: props.constData
        }
    }

    render(){
        const { t } = this.props;
        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle={t("My Profile")}/></h1>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <a href={REACT_APP_HOME_URL+"/my-profile-edit"} className="btn btn-primary align-self-center">Edit Profile</a>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <ProfileCard constData={this.state.constData} user={this.state.user} />
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    <ProfileDetails constData={this.state.constData} user={this.state.user} userEmail={this.state.userEmail} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default withTranslation()(ProfilePage);