import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import SpeakersData from "../api/getSpeakers";
import ls from 'localstorage-slim';
import PageTitle from "../template/PageTitle";

class SpeakersList1 extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)
        var data = JSON.parse(ls.get("eeuserdata"));
        var speakerCount = 0;
        if( data !== null ){
            data.map((speakers)=>{
                if(speakers.eventId === ls.get("eventId") && speakers.SpeakersData !== null) {
                    speakerCount = speakers.SpeakersData.length;
                }
                return speakers;
            });
        }

        this.state = {
            constData: props.constData,
            content: <SpeakersData constData={props.constData} update={true} FeatureId={"4001"} activePage={1} perPage={'24'} PaginateSpeakers={this.handlePaginateSpeakersList} />,
            ActivePage: 1,
            perPage: 24,
            totalPage: speakerCount,
            search : '',
        }
    }

    handleSearchSpeakersList = (e) => {
        var SearchSpeaker = e.target.value;
        SearchSpeaker = SearchSpeaker.replace(/[^a-zA-Z0-9\-\.\@\s]/, "");
        this.setState({
            search: SearchSpeaker,
            ActivePage: 1,
            content : this.renderSpeakersData(SearchSpeaker),
        })
    }

    renderSpeakersData( search, ActivePage= 1 ){
        var SearchSpeaker = search ? search : '';
        return (
            <SpeakersData constData={this.state.constData} update={true} FeatureId={"4001"} activePage={ActivePage} totalPost={this.props.totalPage}  perPage={this.state.perPage} searchSpeaker={SearchSpeaker} PaginateSpeakers={this.handlePaginateSpeakersList} />
        )
    }

    handlePaginateSpeakersList = (e) => {
        if( e !== undefined){
            this.setState({
                ActivePage: e,
                content : this.renderSpeakersData(this.state.search,e),
            })
        }else{
            this.setState({
                ActivePage: 1,
                content : this.renderSpeakersData(this.state.search,1),
            })
        }
    }

    render(){
        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-3 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Speakers 1"/></h1>
                                </div>
                                <div className="card-toolbar my-1 d-flex flex-wrap">
                                    <div className="d-flex align-items-center position-relative my-1 me-4">
                                        <span className="svg-icon svg-icon-3 position-absolute ms-3"><i className="bi bi-search"></i></span>
                                        <input type={"text"} id={"kt_filter_search"} value={this.state.search} onChange={this.handleSearchSpeakersList} className={"form-control form-select-sm w-250px ps-9"} placeholder={"Search Speakers"} style={{border: "none"}}/>
                                    </div>
                                </div>
                            </div>
                            {this.state.content}
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default SpeakersList1;