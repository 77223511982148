import React from 'react';
import ls from 'localstorage-slim';
import {format} from "date-fns";
const {REACT_APP_HOME_URL} = process.env;

const AgendaItem = (props) => {
    ls.config.encrypt = true;
    const {EventAgendaCategories,EventAgendaLocations,EventCategories,AgendaLocations,agendaItem,t} = props;

    var start_time = props.agendaItem.start_time !== null ? props.agendaItem.start_time : "";
    var end_time = props.agendaItem.end_time !== null ? props.agendaItem.end_time : "";

    var StartDateString = start_time !== undefined ? new Date(start_time.replace(/\s/, 'T')) : '';
    var EndDateString = end_time !== undefined ? new Date(end_time.replace(/\s/, 'T')) : '';
    start_time = StartDateString !== undefined ? format(StartDateString, 'hh:mm a') : '';
    end_time = EndDateString !== undefined ? format(EndDateString, 'hh:mm a') : '';
    var agenda_title = props.agendaItem.agenda_title !== null ? props.agendaItem.agenda_title : "";
    var agendaId = props.agendaItem.agenda_id !== null ? props.agendaItem.agenda_id : "";
    var WatchLive = false;
    var AgendaLocationsIds = [];
    var AgendaLocationsData = [];
    if( EventAgendaLocations !== '' && EventAgendaLocations !== undefined ){
        AgendaLocationsIds = [];
        EventAgendaLocations.some(function(LocationsIds) {
            if( agendaId !== '' && agendaId !== undefined && LocationsIds.fk_agenda_id === agendaId && LocationsIds.status === 'A' ) {
                AgendaLocationsIds.push(LocationsIds.fk_location_id);
            }
        })

        if( AgendaLocations !== '' && AgendaLocations !== undefined ){
            AgendaLocationsData = [];
            AgendaLocations.map(function(LocationsIds) {
                AgendaLocationsIds.some(function(LocationId) {
                    if (LocationsIds !== '' && LocationsIds !== undefined && LocationsIds.location_id === LocationId) {
                        AgendaLocationsData.push(LocationsIds.location_name);
                    }
                })
                return LocationsIds;
            })
        }
    }
    var AgendaCategoriesIds = [];
    var AgendaCategoriesColor = [];
    var AgendaCategories = [];
    if( EventAgendaCategories !== '' && EventAgendaCategories !== undefined ){
        AgendaCategoriesIds = [];
        EventAgendaCategories.some(function(CategoriesIds) {
            if( agendaId !== '' && agendaId !== undefined && CategoriesIds.fk_agenda_id === agendaId && CategoriesIds.status === 'A' ) {
                AgendaCategoriesIds.push(CategoriesIds.fk_category_id);
            }
        })
        if( EventCategories !== '' && EventCategories !== undefined ){
            AgendaCategoriesColor = '';
            EventCategories.map(function(CategoriesIds) {
                AgendaCategoriesIds.some(function(CategoriesId) {
                    if (CategoriesIds !== '' && CategoriesIds !== undefined && CategoriesIds.category_id === CategoriesId) {

                        var categoryData = {
                            categoryId: CategoriesIds.category_id,
                            categoryName: CategoriesIds.category_name,
                            categoryColor: CategoriesIds.category_color_code,
                        }
                        AgendaCategories.push(categoryData);

                        AgendaCategoriesColor = CategoriesIds.category_color_code;
                    }
                })
                return CategoriesIds;
            })
        }
    }

    const agendaLocation = () => {
        if(AgendaLocationsData !== undefined && AgendaLocationsData !== null && Number( AgendaLocationsData.length ) > Number( 0 ) ){
            return(
                AgendaLocationsData.map( (Category)=> {
                    if( Category !== undefined && Category !== [] && Category !== null ){
                        return (
                            <>
                                <i className="bi bi-geo-alt-fill"></i>&nbsp; {Category}
                            </>
                        )
                    }
                    return '';
                })
            );
        }
    }

    AgendaCategoriesColor = AgendaCategoriesColor !== '' ? AgendaCategoriesColor : '#00d9d9';
    if( props.agendaItem.show_broadcast === "1" && ( props.agendaItem.broadcast_view_link !== "" || props.agendaItem.broadcast_qa_link !== "" ) ){
        WatchLive = true;
    }
    let path_name = window.location.pathname;

    const manageDisplayTitle = ( title, length = 25 ) => {

        if( '' !== title ) {

            title = title.trim();
            if( title.length >= length) {
                return title.slice(0, length) + '...';
            }
        }
        return title;
    }

    return (
        <div key={props.agendaItem.agenda_id} className={"timeline-item " + props.agendaItem.agenda_id }>
            <div className="timeline-label fw-bolder text-gray-800 fs-6">{start_time} <br />{end_time}</div>
            <div className="timeline-badge">
                <i className="fa fa-genderless text-success fs-1" ref={el => {
                    if (el) {
                        el.style.setProperty('color', AgendaCategoriesColor , 'important');
                    }
                }} />
            </div>
            <div className="fw-mormal timeline-content text-gray-600 ps-6">
                <a href={REACT_APP_HOME_URL+"/schedule-details"} onClick={() => {
                    let path_name = window.location.pathname;
                    if( window.location.pathname === REACT_APP_HOME_URL+'/dashboard' ) {
                        path_name = REACT_APP_HOME_URL+'/schedule';
                    }
                    ls.set('agendaId',props.agendaItem.agenda_id );
                    ls.remove("parent_page");
                    ls.set("parent_page", path_name);
                }
                }><h5 title={agenda_title}>{manageDisplayTitle(agenda_title, 40)}</h5></a>
                 { agendaLocation() }
                <br />
                { agendaItem.featured === 'Y' ? <span className="badge badge-info fs-8 fw-bolder my-2 me-2">{t("FEATURED")}</span> : ""}
                { WatchLive ? <a href={REACT_APP_HOME_URL+"/schedule-watch-live"} onClick={() => {
                    path_name = REACT_APP_HOME_URL + '/virtual-sessions';
                    ls.set('agendaId', props.agendaItem.agenda_id);
                    ls.remove("parent_page");
                    ls.set("parent_page", path_name);
                }}><span className="badge badge-danger fs-8 fw-bolder my-2 me-2"><i
                    className="bi bi-play-circle-fill blink"></i> {t("WATCH LIVE")}</span></a> : ""}
            </div>
            { ! props.Dashboard ? <a href={REACT_APP_HOME_URL+"/schedule-details"} onClick={() => {
                ls.set('agendaId',props.agendaItem.agenda_id );
                ls.remove("parent_page");
                ls.set("parent_page", path_name);
            }} className="btn btn-light bnt-active-light-primary btn-sm d-none d-sm-block">{t("View")}</a> : ""}
        </div>
    );
};

export default AgendaItem;