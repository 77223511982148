import React, { Component } from 'react';
import axios from 'axios';
import {getRandomNumber} from '../getRandomNumber';
import AttendeeCard from "../template/attendee-card";
import ls from 'localstorage-slim';
import Pagination from "../template/pagination";
const {
    PUBLIC_URL,
    REACT_APP_JSON_RPC,
    REACT_APP_LANG_ID,
    REACT_APP_VERSION,
    REACT_APP_DEVICE_ID,
    REACT_APP_TEST_DATE,
    REACT_APP_DEVICE_KEY,
    REACT_APP_PF
} = process.env;

class AttendeeData extends Component {

    constructor(props) {

        ls.config.encrypt = true;
        super(props)

        this.state = {
            data: [],
            code: 400,
            eventId: ls.get('eventId'),
            activePage: this.props.activePage !== undefined ? this.props.activePage : 1,
            attendeeLimit: this.props.attendeeLimit !== undefined ? this.props.attendeeLimit : '',
            searchAttendee: this.props.searchAttendee !== undefined ? this.props.searchAttendee : '',
            displayPagination: this.props.displayPagination !== undefined ? this.props.displayPagination : '',
            allDataLoad: false,
            count: 0,
            DataFetch: this.props.fetch !== undefined ? this.props.fetch : '',
            TotalEvents: 0,
            AllAttendees: [],
            ProfileId: '',
            constData: props.constData,
        }
    }

    componentDidMount() {
        this.GetAttendeeLists('');
    }

    GetAttendeeLists = ( date ) => {

        let constData = this.state.constData;

        ls.config.encrypt = true;
        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var AttendeeData = [];
        var UserData = [];
        var ProfileId = '';
        var Dataexists = false;
        var event_type = ls.get('eventType');

        if(GetEventsUserData !== undefined && GetEventsUserData !== null){
            GetEventsUserData.map(function (el) {
                if( Number( el.eventId ) === Number( eventId ) && ( el.UserProfile !== undefined || ( undefined !== event_type && Number(1) !== Number(event_type) ) ) ){
                    if( undefined !== el.UserProfile && null !== el.UserProfile && "" !== el.UserProfile ){
                        UserData = el.UserProfile;
                        ProfileId = UserData.profile_id;
                    }
                    if( undefined !== el.AttendeeData && "" !== el.AttendeeData ){
                        Dataexists = true;
                    }
                    return el;
                }
                return el;
            });
        }

        if( ( GetEventsUserData === null || !Dataexists ) ) {

            if( "" !== constData && null !== constData ) {

                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "syncAllProfileInfo",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "pf": REACT_APP_PF,
                            "app_device_id": REACT_APP_DEVICE_ID,
                            "event_id": eventId,
                            "profile_id": ProfileId,
                            "device_key": REACT_APP_DEVICE_KEY,
                            "profile": date,
                            "acnt_profile_admin_fields_options": REACT_APP_TEST_DATE,
                            "contacts": REACT_APP_TEST_DATE,
                            "admin_fields_data": REACT_APP_TEST_DATE,
                            "acnt_profile_fields_settings": REACT_APP_TEST_DATE,
                            "my_attendee_groups": REACT_APP_TEST_DATE,
                            "app_group_users": "",
                            "lang_id": REACT_APP_LANG_ID,
                            "event_chat_messages": REACT_APP_TEST_DATE,
                            "acnt_scanned_users": REACT_APP_TEST_DATE,
                            "event_notes": REACT_APP_TEST_DATE
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {

                    let result = undefined !== res.data ? res.data[0].result : "";
                    if (undefined !== result && "" !== result && undefined !== result.code && result.code === 200) {

                        let getAllData = this.state.AllAttendees;

                        let fetchedData = result.profile !== undefined ? result.profile : [];
                        let MergedData = this.state.AllAttendees;

                        fetchedData = fetchedData.filter((Sponsor) =>{
                            if( Sponsor.status === 'A' ){
                                return Sponsor;
                            }
                            return false;
                        });

                        if( undefined !== fetchedData && fetchedData.length > 0 ){
                            MergedData = [...getAllData, ...fetchedData];
                        }

                        this.setState({
                            AllAttendees: MergedData
                        });

                        if( result.has_more_data > 0 && result.profile !== '' && result.profile.length > 0 ){
                            const dataProfile = result.profile[result.profile.length - 1];
                            const LastProfileDate = ( dataProfile.modified ) ? dataProfile.modified : '';
                            this.GetAttendeeLists(LastProfileDate);
                        } else {
                            this.setState({allDataLoad: true});
                        }
                        var SpeakerExists = false;

                        if( GetEventsUserData !== null ){
                            GetEventsUserData = GetEventsUserData.map(function(el) {
                                if( Number(el.eventId ) === Number(eventId) ) {
                                    SpeakerExists = true;
                                    el.AttendeeData = MergedData;
                                    el.app_group_users = result.app_group_users;
                                    el.acnt_profile_fields_settings = result.acnt_profile_fields_settings;
                                }
                                return el;
                            });

                            if( !SpeakerExists ) {
                                var speakersLists = {
                                    eventId:eventId,
                                    AttendeeData: MergedData,
                                    app_group_users: result.app_group_users,
                                    acnt_profile_fields_settings: result.acnt_profile_fields_settings
                                }
                                GetEventsUserData.push(speakersLists);
                            }
                        } else {
                            GetEventsUserData = [{
                                eventId:eventId,
                                AttendeeData: MergedData,
                                app_group_users: result.app_group_users,
                                acnt_profile_fields_settings: result.acnt_profile_fields_settings
                            }]
                        }
                        ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                    }
                }).catch(error => {});
            }
        } else {
            if(undefined !== GetEventsUserData){
                GetEventsUserData.map(function(el) {
                    if( el.eventId === eventId && undefined !== el.AttendeeData){
                        AttendeeData = el.AttendeeData;
                    }
                    return el;
                })
                this.setState({
                    AllAttendees: AttendeeData,
                    allDataLoad: true
                })
            }
        }
    }

    AttendeeStructure = () => {

        var limit = this.props.attendeeLimit !== null ? this.props.attendeeLimit : 50;
        var startFrom = ( limit * this.props.activePage ) - limit;
        var displayTo = startFrom + limit;
        var count = 0;
        var AllAttendees = this.state.AllAttendees;
        AllAttendees.sort((a, b) => a.fname.localeCompare(b.fname));

        if( this.state.allDataLoad && AllAttendees !== '' ) {
            var filteredAttendees = AllAttendees.filter((attendee) =>{
                if( attendee.status === "A" ) {
                    var spekerFirstName = attendee.fname ? attendee.fname.toLowerCase() : '';
                    var spekerLastName = attendee.lname ? attendee.lname.toLowerCase() : '';
                    var salutation = attendee.salut ? attendee.salut.toLowerCase() : '';
                    var spekerFullName = salutation + spekerFirstName + ' ' + spekerLastName;
                    if( this.props.searchAttendee !== undefined && this.props.searchAttendee !== '' ){
                        if(spekerFullName.search(this.props.searchAttendee.toLowerCase()) !== -1) {
                            return attendee;
                        }
                    }else{
                        return attendee;
                    }
                }
                return false
            });
            const totalCounts = ( filteredAttendees.length > 0 ) ? filteredAttendees.length : 0;
            var ActivePage = Number(this.props.activePage) > 0 ? Number(this.props.activePage) : 1 ;

            if (this.props.searchAttendee !== undefined && this.props.searchAttendee !== '') {
                return (
                    <>
                        <div className="card card-xl-stretch mb-8">
                            <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">
                                { undefined !== filteredAttendees && filteredAttendees.length > 0 ? filteredAttendees.map((attendee, index) => {
                                    if( count < limit ){
                                        count++;
                                        return (
                                            <AttendeeCard constData={this.state.constData} key={attendee.profile_id} attendee={attendee} />
                                        )
                                    }

                                    if( AllAttendees.length === ( index + 1 ) && count === 0 ){
                                        return "No result found.";
                                    }

                                    return '';
                                }) : "No result found."}
                            </div>
                        </div>
                        {this.state.displayPagination && Number(totalCounts) > 0 ? <div className="d-flex flex-stack flex-wrap">
                            <Pagination constData={this.state.constData} TatalPost={totalCounts} PaginateSpeakers={this.props.handlePaginateSpeakersList} ActivePage={ActivePage} perPage={limit} />
                        </div> : ""}
                    </>
                );
            } else {
                return (
                    <>
                        <div className="card card-xl-stretch mb-8">
                            <div className="row card-body row-cols-1 row-cols-sm-2 row-cols-xl-4">
                                {
                                    undefined !== filteredAttendees && filteredAttendees.length > 0 ? filteredAttendees.map((attendee, index) => {
                                        if( index >= startFrom && index < displayTo ){
                                            return (
                                                <AttendeeCard constData={this.state.constData} key={attendee.profile_id} attendee={attendee} />
                                            )
                                        }
                                        return '';
                                    }) : "No result found."
                                }
                            </div>
                        </div>
                        {this.state.displayPagination && Number(totalCounts) > 0 ? <div className="d-flex flex-stack flex-wrap">
                            <Pagination constData={this.state.constData} TatalPost={totalCounts} PaginateSpeakers={this.props.handlePaginateSpeakersList} ActivePage={ActivePage} perPage={limit} />
                        </div> : ""}
                    </>
                );

            }
        } else {
            return "No result found.";
        }
    }

    render() {

        return (
            this.AttendeeStructure()
        );
    }

}
export default AttendeeData;