import React, {useEffect, useState} from 'react';
import './App.css';
import axios from "axios";
import ls from "localstorage-slim";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {getRandomNumber} from "./components/getRandomNumber";
import {constantSetup} from "./constant-setup";
import EventList from './components/pages/event-list';
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import PageNotFound from "./components/pages/page-not-found";
import SponsorList from './components/pages/sponsors';
import SponsorList1 from "./components/pages/sponsors1";
import SponsorList2 from "./components/pages/sponsors2";
import SponsorList3 from "./components/pages/sponsors3";
import SponsorList4 from "./components/pages/sponsors4";
import SponsorList5 from "./components/pages/sponsors5";
import SpeakersList from './components/pages/speakers';
import SpeakersList1 from './components/pages/speakers1';
import SpeakersList2 from './components/pages/speakers2';
import SpeakersList3 from './components/pages/speakers3';
import SpeakersList4 from './components/pages/speakers4';
import SpeakersList5 from './components/pages/speakers5';
import SpeakersDetail from './components/pages/speaker-details';
import ScheduleList from './components/pages/schedule';
import ProfilePage from './components/pages/my-profile';
import EditProfilePage from './components/pages/my-profile-edit';
import ScheduleDetails from './components/pages/schedule-details';
import ScheduleWatchLive from './components/pages/schedule-watch-live';
import AboutList from "./components/pages/about";
import MaterialsList from "./components/pages/material";
import MaterialsList1 from "./components/pages/material1";
import MaterialsList2 from "./components/pages/material2";
import MaterialsList3 from "./components/pages/material3";
import MaterialsList4 from "./components/pages/material4";
import MaterialsList5 from "./components/pages/material5";
import VirtualSession from "./components/pages/virtual-sessions";
import AttendeesList from "./components/pages/attendees";
import AttendeesDetails from "./components/pages/attendees-details";
import ScheduleLiveQaAndPoll from "./components/pages/live-qa-and-poll";

const {
    REACT_APP_HOME_URL,
    REACT_APP_VERSION,
    REACT_APP_TEST_DATE,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_EVENT_SORT_ORDER,
    REACT_APP_JSON_RPC,
    PUBLIC_URL
} = process.env;

function App() {

    const [constData, setData] = useState(null);
    const [routesAllPages, setRoutesAllPages] = useState(null);

    useEffect(() => {
        const triggerConstant = async () => {
            const fetchConstant = await constantSetup();
            setData(fetchConstant);
        };

        triggerConstant().then(response => {return response;});
    }, []);

    if( null != constData ) {
        ls.config.encrypt = true;

        var Login_type = ls.get("LoginType");

        if( undefined !== Login_type && null !== Login_type && "" !== Login_type && constData.LoginType !== Login_type ) {
            ls.set("LoginType",constData.LoginType);
        }

        if( constData.Event_ID ) {
            ls.set("Event_ID", constData.Event_ID);
        }

        const PreLoginSession = ls.get("PreLoginSession");
        const PreLoginUserProfile = ls.get("PreLoginUserProfile");
        const isLogin = ls.get('login_status');
        const currentEventId = ls.get('eventId');
        const Event_ID = ls.get('Event_ID');

        var event_type = ls.get('eventType');
        var login_status = ls.get('user_login_status');
        var event_title = ls.get('eventTitle');
        var server_event_id = ls.get('Event_ID');
        var current_path = window.location.pathname;

        if( !routesAllPages && undefined !== isLogin && 'success' === isLogin && undefined !== currentEventId && Number(currentEventId) > 0 && undefined !== event_type ){

            if( undefined !== Login_type && 'P' === Login_type && undefined !== PreLoginSession && null !== PreLoginSession && '' !== PreLoginSession ){
                setRoutesAllPages(true);
            } else {
                const GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
                if( undefined !== GetEventsUserData && null !== GetEventsUserData ){
                    GetEventsUserData.map(function (el) {
                        if (el.eventId === currentEventId && ( ( undefined !== el.UserLoginSession && null !== el.UserLoginSession && "" !== el.UserLoginSession ) || Number(event_type) !== 1 ) ) {
                            setRoutesAllPages(true);
                        }
                        return el;
                    });
                }
            }
        }

        if(
            undefined !== Login_type &&
            'O' === Login_type &&
            (login_status === undefined || login_status === null || login_status === '') ) {

            ls.config.encrypt = true;

            var userEmail = ls.get("userEmail");

            if(
                undefined !== server_event_id &&
                "" !== server_event_id &&
                (undefined === event_type || null === event_type || "" === event_type) &&
                (undefined === event_title || null === event_title || "" === event_title)
            ) {
                const data = {
                    url: `${constData.ApiBaseUrl}/post/index.php`,
                    method: "POST",
                    post_fields: {
                        'id': getRandomNumber,
                        "method": "getUpdatesBeforeAppStarts",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_master": REACT_APP_TEST_DATE,
                            "email": undefined !== userEmail ? userEmail : "",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "app_strings": REACT_APP_TEST_DATE,
                            "eventlist_sort_order": REACT_APP_EVENT_SORT_ORDER
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {

                    let result = res.data !== undefined ? res.data.result : "";

                    if (result.code !== undefined && result.code === 200) {

                        if ( typeof result.event_master !== "undefined" && result.event_master !== null) {

                            result.event_master.map(function (el) {

                                if ( el.event_id === constData.Event_ID ) {

                                    ls.set('single_event_login', '1');
                                    ls.set('eventId', constData.Event_ID);
                                    ls.set('eventTitle', el.event_name);
                                    ls.set('eventType', el.login_type);

                                    setTimeout(() => {
                                        if( current_path === REACT_APP_HOME_URL + '/login' && ( undefined === constData.Event_ID || null === constData.Event_ID || "" === constData.Event_ID ) && ( undefined === currentEventId || null === currentEventId || "" === currentEventId ) && ( undefined === Event_ID || null === Event_ID || "" === Event_ID ) ){
                                            window.location.href = REACT_APP_HOME_URL;
                                        }
                                        if (
                                            undefined !== el.login_type &&
                                            Number(0) === Number(el.login_type) &&
                                            (current_path === REACT_APP_HOME_URL + '/login' || current_path === REACT_APP_HOME_URL + '/')
                                        ) {
                                            window.location.href =REACT_APP_HOME_URL + '/dashboard';
                                        } else if (
                                            current_path !== REACT_APP_HOME_URL + '/login' &&
                                            login_status !== 'success' &&
                                            undefined !== event_type
                                            && ( Number(1) === Number(event_type) || Number(2) === Number(event_type) )
                                        ) {
                                            window.location.href = REACT_APP_HOME_URL + '/login';
                                        }
                                    }, 800);
                                }
                                return el;
                            })
                        }
                    }
                }).catch(error => {});

            } else {
                if( current_path === REACT_APP_HOME_URL + '/login' && ( undefined === currentEventId || null === currentEventId || "" === currentEventId ) && ( undefined === Event_ID || null === Event_ID || "" === Event_ID ) ){
                    window.location.href = REACT_APP_HOME_URL;
                }
                if(
                    undefined !== event_type &&
                    Number(0) === Number(event_type) &&
                    ( current_path === REACT_APP_HOME_URL+'/login' || current_path === REACT_APP_HOME_URL+'/' ) &&
                    undefined !== constData.Event_ID &&
                    "" !== constData.Event_ID
                ){
                    window.location.href = REACT_APP_HOME_URL + '/dashboard';
                } else if(
                    current_path !== REACT_APP_HOME_URL+'/login' && current_path !== REACT_APP_HOME_URL+'/' &&
                    login_status !== 'success' &&
                    undefined !== event_type &&
                    Number(1) === Number(event_type) &&
                    ( ( undefined === constData.Event_ID || null === constData.Event_ID || "" === constData.Event_ID ) && (undefined))
                ) {
                    window.location.href = REACT_APP_HOME_URL + '/login';
                }
            }
        } else if ( Login_type !== undefined &&
            'P' === Login_type &&
            isLogin !== 'success' &&
            current_path !== REACT_APP_HOME_URL+'/login'
        ) {

            if((PreLoginUserProfile && PreLoginUserProfile.status !== 'R') || null === isLogin ) {
                window.location.href = REACT_APP_HOME_URL + '/login';
            }
        }

        return (
            <Router>
                { routesAllPages ? <Routes>
                    <Route path={REACT_APP_HOME_URL+'/'} element={<EventList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers'} element={<SpeakersList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers1'} element={<SpeakersList1 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers2'} element={<SpeakersList2 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers3'} element={<SpeakersList3 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers4'} element={<SpeakersList4 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speakers5'} element={<SpeakersList5 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/about'} element={<AboutList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials'} element={<MaterialsList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials1'} element={<MaterialsList1 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials2'} element={<MaterialsList2 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials3'} element={<MaterialsList3 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials4'} element={<MaterialsList4 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/materials5'} element={<MaterialsList5 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/speaker-details'} element={<SpeakersDetail constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors'} element={<SponsorList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors1'} element={<SponsorList1 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors2'} element={<SponsorList2 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors3'} element={<SponsorList3 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors4'} element={<SponsorList4 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/sponsors5'} element={<SponsorList5 constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/schedule'} element={<ScheduleList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/schedule-details'} element={<ScheduleDetails constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/my-profile'} element={<ProfilePage constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/my-profile-edit'} element={<EditProfilePage constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/schedule-watch-live'} element={<ScheduleWatchLive constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/virtual-sessions'} element={<VirtualSession constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/attendees'} element={<AttendeesList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/attendees-details'} element={<AttendeesDetails constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/live-qa-and-poll'} element={<ScheduleLiveQaAndPoll constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/login'} element={<Login constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/dashboard'} element={<Dashboard constData={constData}/>} />
                    <Route path='*' element={<PageNotFound redirect={false} constData={constData}/>} />
                </Routes> : <Routes>
                    <Route path={REACT_APP_HOME_URL+'/'} element={<EventList constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/login'} element={<Login constData={constData}/>} />
                    <Route path={REACT_APP_HOME_URL+'/dashboard'} element={<Dashboard constData={constData}/>} />
                    <Route path='*' element={<PageNotFound redirect={true} constData={constData}/>} />
                </Routes> }
            </Router>
        );
    } else {
        return null;
    }
}

export default App;