import React from 'react';
import EventData from "../api/get-events";

const PastEvents = (props) => {

    const {constData} = props;

    return (
        <div className="row g-6 g-xl-9">
            <EventData eventType={"past"} constData={constData}/>
        </div>
    );
}

export default PastEvents;