import React from 'react';
import ls from 'localstorage-slim';
const {
    REACT_APP_URL,
    REACT_APP_API_URL
} = process.env;

const AuxFields = (props) => {
    ls.config.encrypt = true;
    const { aux_fields, constData } = props;

    const AuxField = () => {

        let BaseUrl = (null !== constData) ? constData.BaseUrl : REACT_APP_URL+REACT_APP_API_URL+'/';

        if(aux_fields !== undefined && aux_fields !== null){
            return(
                aux_fields.map( (aux_field)=> {
                    if( aux_field.field_type === '1' || aux_field.field_type === '6' || aux_field.field_type === '7' || aux_field.field_type === '8' || aux_field.field_type === '9' || aux_field.field_type === '19' ){
                        return (
                            <p>{aux_field.field_name} : {aux_field.field_value}</p>
                        )
                    } else if ( aux_field.field_type === '2' ) {
                        return (
                            <p>{aux_field.field_name} : <a className={"mail id"} href={"mailto:"+aux_field.field_value} >{aux_field.field_value}</a></p>
                        )
                    } else if ( aux_field.field_type === '3' ) {
                        var mobile = aux_field.field_value;
                        mobile = mobile.replaceAll('-','');
                        return (
                            <p>{aux_field.field_name} : <a href={"tel:"+mobile} >{aux_field.field_value}</a></p>
                        )
                    } else if ( aux_field.field_type === '4' ) {
                        return (
                            <p>{aux_field.field_name} : <a href={aux_field.field_value} >{aux_field.field_value}</a></p>
                        )
                    } else if ( aux_field.field_type === '10' ) {
                        return (
                            <p>{aux_field.field_name} : <img src={BaseUrl + '/uploads/auxi/Thumbs/' + aux_field.field_value}  width={"100%"} alt=""/></p>
                        )
                    } else if ( aux_field.field_type === '15' ) {
                        return (
                            <p>{aux_field.field_name} : <a href={aux_field.field_value} >{aux_field.field_value}</a></p>
                        )
                    } else if ( aux_field.field_type === '16' ) {
                        return (
                            <p>{aux_field.field_name} : <a href={aux_field.field_value} >{aux_field.field_value}</a></p>
                        )
                    }

                    return false;
                })
            );
        }
    }

    return (
        AuxField()
    );
};

export default AuxFields;

