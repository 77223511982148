import React from "react";
import { useTranslation } from "react-i18next";
import ls from "localstorage-slim";
import blank from "../../assets/media/avatars/blank.png";
import SyncIcon from "../../assets/media/svg/files/sync.svg";
import { useNavigate } from "react-router-dom";
import manageAPICall from "../manageAPICall";

const {REACT_APP_HOME_URL, REACT_APP_SYNC_EVENT_TIME, REACT_APP_URL} = process.env;

const Header = (props) => {

    const {constData} = props;

    const navigate = useNavigate();

    const SignOut = () => {

        if( null !== constData ) {

            ls.config.encrypt = true;


            var eventId = ls.get('eventId');
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            if ( GetEventsUserData !== undefined && GetEventsUserData !== null) {
                GetEventsUserData = GetEventsUserData.map(function (el) {
                    if (el.eventId === eventId) {
                        delete el.UserProfile;
                        delete el.userEmail;
                        delete el.UserLoginSession;
                    }
                    return el;
                });

                ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
            }

            var redirect_location = '/';
            var PreLoginSession = ls.get("PreLoginSession");

            if( constData.LoginType !== undefined && 'O' === constData.LoginType && ( PreLoginSession === null || PreLoginSession === '' ) ){
                var EventLoginType = ls.get("eventType");
                if( undefined !== EventLoginType && Number(0) === Number(EventLoginType) ){
                    redirect_location = '/dashboard';
                }
            } else if( constData.LoginType !== undefined && 'P' === constData.LoginType ) {
                redirect_location = '/login';
            }

            ls.remove("PreLoginSession");
            ls.remove("PreLoginUserProfile");
            ls.remove("user_login_status");
            ls.remove("login_status");
            ls.remove("eventId");
            ls.remove("eventTitle");
            ls.remove("eventType");
            ls.remove("userEmail");
            ls.remove("Event_ID");

            setTimeout(function () {
                navigate(REACT_APP_HOME_URL + redirect_location);
            }, 1000);
        }
    }

    const { t } = useTranslation();

    const manageEventSync = (event) => {
        event.preventDefault();
        document.getElementById('sync_menu_item').classList.add('sync-active');
        ls.set('login_status', 'success');
        new manageAPICall().getAllAPIsync(true, true, false, constData);
    }

    const syncEventData = () => {

        const getTimeInterval = Number(REACT_APP_SYNC_EVENT_TIME) * ( 1000 * 60 );
        setTimeout(()=>{
            ls.set('login_status', 'success');
            new manageAPICall().getAllAPIsync(false, true, false, constData);
        },getTimeInterval);

        return(
        <div className="d-flex align-items-center ms-1 ms-lg-3">
            <button id="sync_menu_item" onClick={manageEventSync} title={t("Sync Event Data")} className='sync-menu-item px-5'>
                <img alt={t("Sync Data")} src={ SyncIcon } />
            </button>
        </div>
        );
    }

    const PopupMenu = () => {

        if( null !== constData ) {

            var user_login = false;
            ls.config.encrypt = true;
            var eventId = ls.get('eventId');
            var userEmail = "";

            var EventTitle = "";
            var UserProfile = [];
            var single_event_login = ls.get("single_event_login");

            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

            if( typeof GetEventsUserData !== "undefined" && GetEventsUserData !== null){

                GetEventsUserData.map(function(el) {
                    if( el.eventId === eventId){
                        if(  !user_login ){
                            user_login = typeof el.UserLoginSession !== "undefined" ? el.UserLoginSession : false;
                        }

                        if( undefined !== el.EventTitle ){
                            EventTitle = el.EventTitle;
                        }
                        if( undefined !== el.UserProfile ){
                            UserProfile = el.UserProfile;
                        }
                        if( undefined !== UserProfile.email ){
                            userEmail = UserProfile.email;
                        }
                    }
                    return el;
                });
            }
            var login_type = ls.get('LoginType');
            if( null === login_type || undefined === login_type ){
                login_type = constData.LoginType;
            }

            if( user_login !== false ){
                var FirstName = UserProfile.fname !== undefined ? UserProfile.fname : "";
                var UserAvatar = UserProfile.pic !== undefined && UserProfile.pic !== "" ? constData.BaseUrl + "uploads/account/profile/Thumbs/" + UserProfile.pic : "";
                var LastName = UserProfile.lname !== undefined ? UserProfile.lname : "";
                var FullName = FirstName + " " + LastName;
                const PreLoginSession = ls.get("PreLoginSession");
                return(
                    <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px"
                             data-kt-menu-trigger="click"
                             data-kt-menu-attach="parent"
                             data-kt-menu-placement="bottom-end"
                        >
                            <img src={ UserAvatar !== "" ? UserAvatar : blank} alt="user"/>
                        </div>
                        <div
                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px"
                            data-kt-menu="true"
                            data-current-event={EventTitle}
                        >
                            <div className="menu-item px-3">
                                <div className="menu-content d-flex align-items-center px-3">
                                    <div className="symbol symbol-50px me-5">
                                        <img alt="Logo" src={UserAvatar !== "" ? UserAvatar : blank}/>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="fw-bolder d-flex align-items-center fs-5">{FullName}</div>
                                        <a href="#useremail"
                                           className="fw-bold text-muted text-hover-primary fs-7"
                                        >{userEmail}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-2"></div>
                            <div className="menu-item px-5">
                                <a href={REACT_APP_HOME_URL+"/my-profile"} className="menu-link px-5">{t("My Profile")}</a>
                            </div>
                            <div className="menu-item px-5">
                                <a href={REACT_APP_HOME_URL+"/"} className="menu-link px-5">
                                    <span className="menu-text">{t("My Events")}</span>
                                </a>
                            </div>
                            <div className="menu-item px-5 my-1">
                                <a href={REACT_APP_HOME_URL+"/my-profile-edit"} className="menu-link px-5">{t("Account Settings")}</a>
                            </div>
                            { undefined === PreLoginSession || '' === PreLoginSession  || null === PreLoginSession ? <><div className="separator my-2"></div><div className="menu-item px-5"><a href="#signout"  onClick={SignOut} className="menu-link px-5">{t("Sign Out")}</a></div></> : ''}
                        </div>
                    </div>
                );

            }else{
                return(
                    <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                        <div className="cursor-pointer symbol symbol-30px symbol-md-40px"
                             data-kt-menu-trigger="click"
                             data-kt-menu-attach="parent"
                             data-kt-menu-placement="bottom-end"
                        >
                            <img src={blank} alt="user"/>
                        </div>
                        <div
                            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px"
                            data-kt-menu="true">
                            <div className="menu-item px-3">
                                <div className="menu-content d-flex align-items-center px-3">
                                    <div className="symbol symbol-50px me-5">
                                        <img alt="Logo" src={blank}/>
                                    </div>
                                </div>
                            </div>
                            <div className="separator my-2"></div>
                            <div className="menu-item px-5">
                                <a href={REACT_APP_HOME_URL+"/"} className="menu-link px-5">
                                    <span className="menu-text">{t("My Events")}</span>
                                </a>
                            </div>
                            <div className="separator my-2"></div>
                            { Number( ls.get('eventType') ) !== Number( 0 ) ?
                                <div className="menu-item px-5">
                                    <a href={REACT_APP_HOME_URL+"/login"} className="menu-link px-5">{t("Login")}</a>
                                </div> : "" }
                        </div>
                    </div>
                );
            }

        } else {
            return "";
        }
    }

    const EventTitle = () => {

        ls.config.encrypt = true;
        var eventTitle = ls.get('eventTitle');
        eventTitle = eventTitle !== undefined ? eventTitle : '';
        return(
            <h1 className="text-dark fw-bolder my-1 lh-1">{eventTitle}</h1>
        );
    }

    let LogoUrl = ( null !== constData ) ? constData.LogoUrl : REACT_APP_URL+'/webapp_new/img/ee_blue.png';

    return (
        <div id="kt_header" className="header bg-white align-items-stretch">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                    <div className="btn btn-icon btn-active-color-primary w-40px h-40px toggle-menu" id="kt_aside_toggle">
                        <span className="svg-icon svg-icon-2x mt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none">
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="black"/>
                                    <path opacity="0.3"
                                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                          fill="black"/>
                                    </svg>
                                </span>
                    </div>
                </div>
                <div id="mobile-logo"
                     className="d-flex align-items-center  flex-grow-1 flex-sm-grow-0 flex-lg-grow-0 me-4">
                    <a href="#logourl" className="d-lg-none">
                        <img alt="Logo" src={LogoUrl}
                             className="h-40px border"/>
                    </a>
                </div>
                <div className="d-flex align-items-center d-none d-sm-flex flex-sm-grow-1 flex-lg-grow-1"
                     id="kt_header_wrapper">
                    <div
                        className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-2 pb-lg-0">
                        {EventTitle()}
                    </div>
                </div>
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-0">
                    <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
                        <div className="d-flex align-items-stretch flex-shrink-0">
                            <div className="d-flex align-items-center ms-1 ms-lg-3 d-none">
                                <div
                                    className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
                                    data-kt-menu-trigger="click" data-kt-menu-attach="parent"
                                    data-kt-menu-placement="bottom-end">
                                    <i className="bi bi-bell fs-2"></i>
                                    <span
                                        className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                </div>
                                <div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                                     data-kt-menu="true">
                                    <div className="d-flex flex-column bgi-no-repeat rounded-top bg-primary">
                                        <h3 className="text-white fw-bold px-9 mt-10 mb-6">{t("Notifications")}</h3>
                                    </div>
                                    <div className="tab-content">
                                        <div id="kt_topbar_notifications_1">
                                            <div className="scroll-y mh-325px my-5 px-8">
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#firestonelogo"
                                                           className="text-gray-800 text-hover-primary fw-bolder fs-6"
                                                        >{t("CreateFireStone Logo")}</a>
                                                        <span className="text-muted fw-bold d-block">{t("Due in 2 Days")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#projectalice"
                                                           className="text-gray-800 text-hover-primary fw-bolder fs-6"
                                                        >{t("Project Alice")}</a>
                                                        <span className="text-muted fw-bold d-block">{t("Phase 1 development")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#hrconfidential"
                                                           className="text-gray-800 text-hover-primary fw-bolder fs-6"
                                                        >{t("HR Confidential")}</a>
                                                        <span className="text-muted fw-bold d-block">{t("Confidential staff documents")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#fireStonelogo" className="text-gray-800 text-hover-primary fw-bolder fs-6">{t("Create FireStone Logo")}</a>
                                                        <span className="text-muted fw-bold d-block">{t("Due in 2 Days")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#projectalice"
                                                           className="text-gray-800 text-hover-primary fw-bolder fs-6"
                                                        >{t("Project Alice")}</a>
                                                        <span className="text-muted fw-bold d-block">{t("Phase 1 development")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                                <div className="d-flex align-items-center py-4">
                                                    <span className="bullet bullet-vertical h-40px bg-secondary"></span>
                                                    <div className="flex-grow-1 mx-5">
                                                        <a href="#hrconfidential"
                                                           className="text-gray-800 text-hover-primary fw-bolder fs-6">HR Confidential</a>
                                                        <span className="text-muted fw-bold d-block">{t("Confidential staff documents")}</span>
                                                    </div>
                                                    <span className="badge badge-light fs-8">{t("2 hrs")}</span>
                                                </div>
                                            </div>
                                            <div className="py-3 text-center border-top">
                                                <a href="#viewall" className="btn btn-color-gray-600 btn-active-color-primary">{t("View All")}
                                                    <i className="bi bi-arrow-right-short fs-2"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {syncEventData()}
                            {PopupMenu()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
