import React, { Component } from 'react';
import axios from 'axios';
import LoginVerificationScreen from '../template/LoginVerificationScreen';
import ls from 'localstorage-slim';
import {getRandomNumber} from '../getRandomNumber';
import manageAPICall from "../manageAPICall";
const {
    PUBLIC_URL,
    REACT_APP_PF,
    REACT_APP_PKG_NAME,
    REACT_APP_DEVICE_KEY,
    REACT_APP_JSON_RPC,
    REACT_APP_HOME_URL
} = process.env;

class VerifyUserLogin extends Component {

    constructor(props) {
        super(props);

        const {constData} = props;

        this.state = {
            data: [],
            code: 400,
            message: "",
            UserEmail: "",
            userStatus: "",
            loginText: this.props.loginText,
            pinText: this.props.pinText,
            eventLogo: this.props.eventLogo,
            constData: constData,
        }
    }

    componentDidMount() {
        ls.config.encrypt = true;
        var eventId = ls.get("eventId");
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        var UserEmail = this.props.UserEmail;

        this.setState({
            UserEmail:UserEmail
        });

        let constData = this.state.constData;

        if( "" !== constData && null !== constData ) {
            const data = {
                url: constData.ApiBaseUrl+'/post/index.php',
                method: "POST",
                post_fields: {
                    "id": getRandomNumber,
                    "method": "signIn",
                    "params": {
                        "email":UserEmail,
                        "pin":this.props.VerificationCode,
                        "pf": REACT_APP_PF,
                        "pkg_nm": REACT_APP_PKG_NAME,
                        "event_id": eventId,
                        "device_key": REACT_APP_DEVICE_KEY
                    },
                    "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {
                let result = res.data !== undefined ? res.data : "";

                this.setState({
                    VerifiedUser:false,
                    userStatus: "",
                });

                if(result.hasOwnProperty('error')){
                    this.setState({message:result.error.message });
                } else if ("" !== result && result.hasOwnProperty('result') && result.result.code === 200) {

                    let resultObj = ('' !== result.result) ? result.result : '';

                    let profileStatus = ("" !== resultObj && resultObj.hasOwnProperty('profile')) ? resultObj.profile.status : '';

                    this.setState({
                        userStatus: profileStatus,
                    });

                    if(
                        profileStatus === 'A' &&
                        (
                            (
                                resultObj.hasOwnProperty('profile_status') &&
                                Number( resultObj.profile_status ) === Number( 1 )
                            )  ||
                            (
                                resultObj.hasOwnProperty('profile_platform_count') &&
                                undefined !== resultObj.profile_platform_count
                            )
                        )
                    ) {
                        this.props.CreateFlag();
                        ls.set("user_login_status","success");
                        if( constData.LoginType !== undefined && 'P' === constData.LoginType ) {
                            ls.set("PreLoginSession", resultObj.access_token);
                            if( undefined !== resultObj.profile ){
                                ls.set("PreLoginUserProfile", resultObj.profile);
                            }
                        } else {
                            GetEventsUserData = GetEventsUserData.map(function (el) {
                                if (el.eventId === eventId) {
                                    el.UserLoginSession = resultObj.access_token;
                                    el.UserProfile = resultObj.profile;
                                }
                                return el;
                            });
                        }
                    } else if( profileStatus === 'R' && ( resultObj.hasOwnProperty('profile_status') && Number( resultObj.profile_status ) === Number( 1 )) ) {

                        this.props.CreateFlag();
                        ls.set('login_status', '');
                        ls.set('user_login_status', '');
                        var redirectURl = REACT_APP_HOME_URL+"/my-profile-edit";
                        if( constData.LoginType !== undefined && 'P' === constData.LoginType ) {
                            redirectURl = REACT_APP_HOME_URL+"/";
                            ls.set("PreLoginSession", resultObj.access_token);
                            if( undefined !== resultObj.profile ){
                                ls.set("PreLoginUserProfile", resultObj.profile);
                            }
                        } else {
                            GetEventsUserData = GetEventsUserData.map(function (el) {
                                if (el.eventId === eventId) {
                                    el.UserLoginSession = resultObj.access_token;
                                    el.UserProfile = resultObj.profile;
                                }
                                return el;
                            });
                        }

                        ls.set("eeuserdata", JSON.stringify(GetEventsUserData));

                        setTimeout(()=>{
                            window.location.href = redirectURl;
                        },500);

                    } else {
                        this.setState({
                            message: "This account is not active now"
                        });
                    }
                }

                if( constData.LoginType === undefined || 'P' !== constData.LoginType ) {
                    ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
                }
            }).catch(error => {
                this.setState({
                    message:error
                });
            });
        }
    }

    render(){
        return (
            <>
                <LoginVerificationScreen
                    CreateFlag={this.props.CreateFlag}
                    VerifyLogin={this.props.VerifyLogin}
                    handlePinChange={this.props.handlePinChange}
                    UserEmail={this.props.UserEmail}
                    errorMessage={this.state.message}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    userStatus={this.state.userStatus}
                    constData={this.state.constData}
                />
            </>

        )
    }
}
export default VerifyUserLogin;