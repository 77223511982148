import React, { Component } from 'react';
import axios from 'axios';
import ls from 'localstorage-slim';
import {getRandomNumber} from '../getRandomNumber';
import SponsorCard from "../template/sponsor-card";
import Pagination from "../template/pagination";
const {
    PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC,
    REACT_APP_LOCAL_ACCESS
} = process.env;

class GetSponsors extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);

        this.state = {
            EventSponsors: [],
            code: 400,
            eventId: ls.get('eventId'),
            EventAgendaSponsors: [],
            activePage: this.props.activePage,
            sponsorsLimit: this.props.sponsorsLimit,
            displayPagination: this.props.displayPagination,
            constData: props.constData
        }
    }

    componentDidMount() {

        let constData = this.state.constData;

        ls.config.encrypt = true;
        const isLocalAccess = REACT_APP_LOCAL_ACCESS;
        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var EventSponsors = [];
        var EventAgendaSponsors = [];

        if( GetEventsUserData === null && Number(isLocalAccess) === 0 ) {
            if( null !== constData && '' !== constData ) {

                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "getSponsorList",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_sponsors": "",
                            "event_agenda_sponsors": "",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "event_id": eventId
                        },
                        "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {
                    let result = res.data !== undefined ? res.data[0].result : "";
                    if (undefined !== result && "" !== result && undefined !== result.code && Number( result.code ) === Number( 200 ) ) {
                        var SponsorExists = false;
                        if ( GetEventsUserData !== null ) {
                            GetEventsUserData = GetEventsUserData.map(function (el) {
                                if ( Number( el.eventId ) === Number( eventId ) ) {
                                    SponsorExists = true;
                                    el.EventSponsors = result.event_sponsors;
                                    el.EventAgendaSponsors = result.event_agenda_sponsors;
                                    EventSponsors = result.event_sponsors;
                                    EventAgendaSponsors = result.event_agenda_sponsors;
                                }
                                return el;
                            })
                            if (!SponsorExists) {
                                var agendasLists = {
                                    eventId: eventId,
                                    EventSponsors: result.event_sponsors,
                                    EventAgendaSponsors: result.event_agenda_sponsors,
                                }
                                EventSponsors = result.event_sponsors;
                                EventAgendaSponsors = result.event_agenda_sponsors;
                                GetEventsUserData.push(agendasLists);
                            }
                        } else {
                            GetEventsUserData = [{
                                eventId: eventId,
                                EventSponsors: result.event_sponsors,
                                EventAgendaSponsors: result.event_agenda_sponsors,
                            }]
                            EventSponsors = result.event_sponsors;
                            EventAgendaSponsors = result.event_agenda_sponsors;
                        }
                        this.setState({
                            EventSponsors: EventSponsors,
                            EventAgendaSponsors: EventAgendaSponsors,
                        })
                        ls.set("eeuserdata", JSON.stringify(GetEventsUserData));
                    }
                }).catch(error => {});
            }
        } else {
            if ( GetEventsUserData !== null ) {
                GetEventsUserData = GetEventsUserData.map(function (el) {
                    if ( undefined !== el.eventId && Number(el.eventId) === Number(eventId)) {
                        EventSponsors = undefined !== el.EventSponsors ? el.EventSponsors : '';
                        EventAgendaSponsors = undefined !== el.EventAgendaSponsors ? el.EventAgendaSponsors : '';
                    }
                    return el;
                })
                this.setState({
                    EventSponsors: EventSponsors,
                    EventAgendaSponsors: EventAgendaSponsors,
                })
            }
        }
    }

    SponsorListStructure = () => {
        var EventSponsors = this.state.EventSponsors;
        var totalActiveSponsors = [];
        var RelatedSponsorsIds = [];
        var RelatedSponsors = [];
        var totalCounts = 0;
        var agendaId = 0;

        if( undefined !== this.state.EventSponsors && this.state.EventSponsors.length > 0 ){
            EventSponsors = this.state.EventSponsors;
            EventSponsors.sort((a, b) => (Number(a.sponsor_order) > Number(b.sponsor_order)) ? 1 : -1);

            if( undefined !== this.props.RelatedSponsor && undefined !== this.props.agendaId ) {
                agendaId = this.props.agendaId;
                if( this.state.EventAgendaSponsors !== '' && this.state.EventAgendaSponsors !== undefined ){
                    RelatedSponsorsIds = [];

                    this.state.EventAgendaSponsors.some(function(SponsorIds) {
                        if( undefined !== agendaId && '' !== agendaId && SponsorIds.fk_agenda_id === agendaId && SponsorIds.status === 'A' ) {
                            RelatedSponsorsIds.push(SponsorIds.fk_sponsor_id);
                        }
                    })

                    RelatedSponsors = [];
                    EventSponsors.map(function(Sponsor) {
                        RelatedSponsorsIds.some(function(SponserId) {
                            if ( undefined !== Sponsor && '' !== Sponsor && Sponsor.sponsor_id === SponserId) {
                                RelatedSponsors.push(Sponsor);
                            }
                        })
                        return Sponsor;
                    })
                }
            } else {
                totalActiveSponsors = EventSponsors.filter((Sponsor) =>{
                    if( ( ( this.props.FeatureId !== undefined && Sponsor.fk_feature_id === this.props.FeatureId ) || ( this.props.FeatureId === undefined && Number( Sponsor.fk_feature_id ) === 3 ) ) && Sponsor.status === 'A' ){
                        return Sponsor;
                    }
                });
                totalCounts = ( totalActiveSponsors.length > 0 ) ? totalActiveSponsors.length : 0;
            }
        }

        var ActivePage = Number(this.props.activePage) > 0 ? Number(this.props.activePage) : 1 ;

        var limit = 10;
        var startFrom = ( limit * this.props.activePage ) - limit;

        var displayTo = startFrom + limit;
        var count = 0;

        return(
            <>
                {undefined !== this.props.RelatedSponsor && undefined !== agendaId ?
                    <>
                        {undefined !== RelatedSponsors && RelatedSponsors.length > 0 ?
                            RelatedSponsors.map((Sponsor, index) => {
                            return (
                                <SponsorCard constData={this.state.constData} key={Sponsor.sponsor_id} Sponsor={Sponsor} />
                            );
                        }) : "Sponsors not available"} </>
                    :
                    <>
                        <div className="card card-xl-stretch mb-xl-8">
                            <div className="row card-body p-9 row-cols-1 row-cols-sm-2 row-cols-xl-3">
                                {undefined !== totalActiveSponsors && Number(totalActiveSponsors.length) > Number(0) && EventSponsors !== undefined && EventSponsors !== "" ?
                                    EventSponsors.map((Sponsor, index) => {
                                        if (((this.props.FeatureId !== undefined && Sponsor.fk_feature_id === this.props.FeatureId) || (this.props.FeatureId === undefined && Number(this.props.FeatureId) === 3)) && Sponsor.status === 'A') {

                                            if (Number(count) >= Number(startFrom) && Number(count) < Number(displayTo)) {
                                                count++;
                                                return (
                                                    <SponsorCard constData={this.state.constData} key={index} Sponsor={Sponsor}/>
                                                );
                                            } else {
                                                count++;
                                            }
                                        }
                                        return '';
                                    }) : "Sponsors not available"}
                            </div>
                        </div>
                        {this.state.displayPagination && Number(totalCounts) > 0 ? <div className="d-flex flex-stack flex-wrap">
                        <Pagination constData={this.state.constData} TatalPost={totalCounts} PaginateSpeakers={this.props.handlePaginateSponsorList} ActivePage={ActivePage} perPage={limit} />
                        </div> : ""}
                    </>
                }
            </>
        );
    }

    render() {
        return (this.SponsorListStructure());
    }
}
export default GetSponsors;