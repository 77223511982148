import React, { Component } from 'react';
import axios from 'axios';
import {getRandomNumber} from "../getRandomNumber";
import EventCard from "../template/event-card";
import ls from 'localstorage-slim';

const {
    PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_TEST_DATE,
    REACT_APP_LANG_ID,
    REACT_APP_EVENT_SORT_ORDER,
    REACT_APP_JSON_RPC,
    REACT_APP_PKG_NAME
} = process.env;

class EventData extends Component {

    constructor(props) {

        ls.config.encrypt = true;

        super(props);

        this.state = {
            constData: props.constData,
            data: [],
            code: 400,
            eventId: ls.get('eventId'),
            eventType: props.eventType !== undefined ? props.eventType : "upcoming",
            count: 0,
        }
    }

    componentDidMount() {

        let constData = this.state.constData;
        if (null !== constData || undefined !== constData ) {

            ls.config.encrypt = true;
            var userEmail = ls.get("userEmail");

            const data = {
                url: constData.ApiBaseUrl + '/post/index.php',
                method: "POST",
                post_fields: {
                    'id': getRandomNumber,
                    "method": "getUpdatesBeforeAppStarts",
                    "params": {
                        "app_version": REACT_APP_VERSION,
                        "event_master": REACT_APP_TEST_DATE,
                        "email": undefined !== userEmail ? userEmail : "",
                        "pkg_nm": REACT_APP_PKG_NAME,
                        "lang_id": REACT_APP_LANG_ID,
                        "app_strings": REACT_APP_TEST_DATE,
                        "eventlist_sort_order": REACT_APP_EVENT_SORT_ORDER
                    },
                    "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL + '/functions.php?data=' + JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {
                let result = res.data !== undefined ? res.data.result : "";
                let access_event_ids = [];

                if (undefined !== result && "" !== result && undefined !== result.code && result.code === 200) {
                    if( undefined !== result.access_event_ids && result.access_event_ids ){
                        ls.set('access_event_ids', result.access_event_ids);
                        access_event_ids = result.access_event_ids;
                    }
                    if (typeof result.event_master !== "undefined" && result.event_master !== null) {

                        result.event_master.map(function (el) {

                            if (el.event_id === constData.Event_ID) {
                                ls.set('eventId', constData.Event_ID);
                                ls.set('eventTitle', el.event_name);
                                ls.set('eventType', el.login_type);
                            }

                            return '';
                        });
                    }

                    this.setState({
                        data: result.event_master,
                        access_event_ids: access_event_ids
                    });
                }
            }).catch(error => {
            });
        }
    }

    dateToTimestamp = (date) => {

        if( null === date ) {
            return null;
        }

        const dt = Date.parse(date);
        return dt / 1000;
    }

    dateFormater = (date) => {

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        const formatDate = [];
        formatDate['year']= year;
        formatDate['month']= month;
        formatDate['day']= day;
        formatDate['full_date']= [year, month, day].join('-');

        return formatDate;
    }

    addLoaderEvent = () => {

        var spinner_container = document.getElementById('spinner_container');
        if( spinner_container !== null ){
            spinner_container.style.display = "block";
        }
    }

    removeLoaderEvent = () => {

        setTimeout( () => {
            var spinner_container = document.getElementById('spinner_container');
            if( spinner_container !== null ){
                spinner_container.style.display = "none";
            }
        },1000)
    }

    EventStructure = () => {

        let constData = this.state.constData;
        let access_event_ids = [];

        if( null === constData && "" === constData ) {
            return null;
        }

        ls.config.encrypt = true;
        const currentDate = new Date();
        var count = 0;

        this.addLoaderEvent();

        return(
            Number( this.state.data.length ) > Number( 0 ) ? this.state.data.map((event , index ) => {
                var display_event = false;
                var userRegisterEvent = false;
                const eventStartDate = event.start_date !== undefined ? event.start_date : '';
                access_event_ids = this.state.access_event_ids;
                if( 'P' !== constData.LoginType || ( undefined !== access_event_ids && null !== access_event_ids && access_event_ids.includes(Number(event.event_id)) ) ){
                    userRegisterEvent = true;
                }

                if( undefined !== event.show_on_eventlist && event.show_on_eventlist && ( ( (undefined !== event.is_public && Number(event.is_public) === 1) || (undefined !== event.login_type && Number(event.login_type) === 0) ) || userRegisterEvent ) ) {
                    if( this.state.eventType === 'upcoming' && this.dateToTimestamp(eventStartDate) >= this.dateToTimestamp(currentDate) ) {
                        display_event = true;
                    } else if( this.state.eventType === 'past' && this.dateToTimestamp(eventStartDate) <= this.dateToTimestamp(currentDate) ) {
                        display_event = true;
                    }
                }

                if( Number(this.state.data.length) === index+1) {
                    this.removeLoaderEvent();
                }

                if( display_event ){
                    var user_login = '';
                    var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

                    if( constData.LoginType === undefined || 'P' !== constData.LoginType ) {
                        if (typeof GetEventsUserData !== "undefined" && GetEventsUserData !== null) {
                            GetEventsUserData.map(function (el) {
                                if (el.eventId === event.event_id) {
                                    return user_login = el.UserLoginSession !== undefined ? el.UserLoginSession : '';
                                }
                                return '';
                            })
                        }
                    }
                    count++;
                    return (
                        <EventCard key={event.event_id} event={event} userLogin={user_login} EventType={this.state.eventType} constData={this.state.constData} />
                    );
                } else if( count === 0 && this.state.data.length === ( index + 1 ) ) {
                    return <div className="col-md-12 test-card" id="event_0">Events not available</div>;
                }
                return "";
            }) : (
                <>
                    <div className="col-md-12 test-card" id="event_0">Loading...</div>
                </>
            )
        )
    }

    render() {
        return (
            this.EventStructure()
        );
    }
}

export default EventData;