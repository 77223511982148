import React from "react";
import { useLocation } from "react-router-dom";
import GetSidebarMenu from "../api/get-sidebar-menu";
import ls from "localstorage-slim";
const {REACT_APP_HOME_URL, REACT_APP_URL} = process.env;

const SideMenu = (props) => {

    const {constData} = props;

    const location = useLocation();
    var current_path = location.pathname;
    const parent_page = ls.get("parent_page");
    var active = '';
    if( current_path === REACT_APP_HOME_URL+'/virtual-sessions' || ( undefined !== parent_page && null !== parent_page && parent_page === REACT_APP_HOME_URL+'/virtual-sessions')) {
        active = "active";
    } else if( current_path === REACT_APP_HOME_URL+'/schedule' && parent_page === REACT_APP_HOME_URL+'/virtual-sessions' ){
        ls.remove("parent_page");
    }

    let LogoUrl = (null !== constData) ? constData.LogoUrl: REACT_APP_URL+'/webapp_new/img/ee_blue.png'
    return (
        <div id="kt_aside" className="aside bg-primary" data-kt-drawer="true" data-kt-drawer-name="aside"
             data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
             data-kt-drawer-width="auto" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_toggle">
            <div className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8"
                 id="kt_aside_logo">
                <a href={REACT_APP_HOME_URL+"/"}>
                    <img alt="Logo" src={LogoUrl} className="h-55px"/>
                </a>
            </div>
            <div className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0"
                 id="kt_aside_nav">
                <div id="kt_aside_menu"
                     className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6"
                     data-kt-menu="true">

                    <div className="menu-item py-3">
                        <a href={REACT_APP_HOME_URL+"/dashboard"}
                           className={`menu-link menu-center ${current_path === "/dashboard" ? "active" : ""}`}
                           title="Dashboard" data-bs-toggle="tooltip" data-bs-trigger="hover"
                           data-bs-dismiss="click" data-bs-placement="right">
                                <span className="menu-icon me-0">
                                    <i className="bi bi-house fs-1"></i>
                                </span>
                        </a>
                    </div>
                    <GetSidebarMenu constData={constData} />
                    <div className="menu-item py-3">
                        <a href={REACT_APP_HOME_URL+"/virtual-sessions"}
                           className={`menu-link menu-center ${active}`}
                           title="Virtual Sessions" data-bs-toggle="tooltip" data-bs-trigger="hover"
                           data-bs-dismiss="click" data-bs-placement="right">
                            <span className="menu-icon me-0">
                                <i className="bi bi-person-video3 fs-1"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="aside-footer d-flex flex-column align-items-center flex-column-auto"
                 id="kt_aside_footer">
                <div className="mb-7">
                    <button type="button"
                            className="btn btm-sm btn-icon btn-color-white btn-active-color-primary btn-active-light"
                            data-kt-menu-trigger="click" data-kt-menu-overflow="true"
                            data-kt-menu-placement="top-start" data-bs-toggle="tooltip" data-bs-placement="right"
                            data-bs-dismiss="click" title="Quick actions">
                        <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path
                                    d="M3 2H10C10.6 2 11 2.4 11 3V10C11 10.6 10.6 11 10 11H3C2.4 11 2 10.6 2 10V3C2 2.4 2.4 2 3 2Z"
                                    fill="black"/>
                                <path opacity="0.3"
                                      d="M14 2H21C21.6 2 22 2.4 22 3V10C22 10.6 21.6 11 21 11H14C13.4 11 13 10.6 13 10V3C13 2.4 13.4 2 14 2Z"
                                      fill="black"/>
                                <path opacity="0.3"
                                      d="M3 13H10C10.6 13 11 13.4 11 14V21C11 21.6 10.6 22 10 22H3C2.4 22 2 21.6 2 21V14C2 13.4 2.4 13 3 13Z"
                                      fill="black"/>
                                <path opacity="0.3"
                                      d="M14 13H21C21.6 13 22 13.4 22 14V21C22 21.6 21.6 22 21 22H14C13.4 22 13 21.6 13 21V14C13 13.4 13.4 13 14 13Z"
                                      fill="black"/>
                            </svg>
                        </span>
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                        <div className="menu-item px-3">
                            <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                        </div>
                        <div className="separator mb-3 opacity-75"></div>
                        <div className="menu-item px-3">
                            <a href="#newticket" className="menu-link px-3">New Ticket</a>
                        </div>
                        <div className="menu-item px-3">
                            <a href="#newcustomer" className="menu-link px-3">New Customer</a>
                        </div>
                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                            <a href="#newgroup" className="menu-link px-3">
                                <span className="menu-title">New Group</span>
                                <span className="menu-arrow"></span>
                            </a>
                            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                <div className="menu-item px-3">
                                    <a href="#admingroup" className="menu-link px-3">Admin Group</a>
                                </div>
                                <div className="menu-item px-3">
                                    <a href="#staffgroup" className="menu-link px-3">Staff Group</a>
                                </div>
                                <div className="menu-item px-3">
                                    <a href="#membergroup" className="menu-link px-3">Member Group</a>
                                </div>
                            </div>
                        </div>
                        <div className="menu-item px-3">
                            <a href="#newcontact" className="menu-link px-3">New Contact</a>
                        </div>
                        <div className="separator mt-3 opacity-75"></div>
                        <div className="menu-item px-3">
                            <div className="menu-content px-3 py-3">
                                <a className="btn btn-primary btn-sm px-4" href="#generate_reports">Generate Reports</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;