import React, { Component } from 'react';
import ls from 'localstorage-slim';
import {} from "../template/sponser-list";
import MaterialCard from "../template/material-card";

class GetMaterials extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);

        this.state = {
            isEventMaterials: false,
            EventMaterials: [],
            code: 400,
            eventId: ls.get('eventId'),
            EventMaterialsConnection: [],
            constData: props.constData,
        }
    }

    GetMaterials() {

        ls.config.encrypt = true;

        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

        if( GetEventsUserData !== null ) {
            var EventMaterials = [];
            var EventMaterialsConnection = [];
            GetEventsUserData.map(function(el) {
                if( el.eventId === eventId){
                    EventMaterials = el.EventMaterials;
                    EventMaterialsConnection = el.EventMaterialsConnection;
                }
                return el;
            });

            this.setState({
                isEventMaterials: true,
                EventMaterials: EventMaterials,
                EventMaterialsConnection: EventMaterialsConnection,
            });

            return EventMaterials;
        }
    }

    MaterialsListStructure = () =>{

        if( !this.state.isEventMaterials &&this.state.EventMaterials !== undefined && this.state.EventMaterials.length === 0 ){
            this.GetMaterials();
        }

        var EventMaterials = this.state.EventMaterials;
        var EventMaterialsConnection = this.state.EventMaterialsConnection;
        var Materials = undefined !== EventMaterials ? EventMaterials : [];
        var allow_feature_ids = [];

        if( undefined !== this.props.FeatureId && undefined !== this.props.speakerId && undefined !== EventMaterialsConnection ){
            Materials = [];

            EventMaterialsConnection.map( (connection) => {
                if( this.props.FeatureId === '4' ){
                    allow_feature_ids = [4,4001,4002,4003,4004,4005]
                }
                if( allow_feature_ids.some( id => Number(connection.fk_feature_id) === Number(id)) && connection.row_id === this.props.speakerId  && connection.status === "A" ){
                    Materials.push( connection.fk_material_id );
                }
                return connection;
            });
        }else{

            return(
                EventMaterials !== undefined && EventMaterials !== "" && Number(EventMaterials.length) > Number(0) ?
                    EventMaterials.map((Material) => {
                        if ( undefined !== Material.status && Material.status === "A" ){
                            return(
                                <MaterialCard Material={Material} constData={this.state.constData}/>
                            );
                        }
                        return "";
                    }) : "Materials not available"
            );
        }

        if( undefined !== Materials ){
            return(
                EventMaterials !== undefined && EventMaterials !== "" && Number(EventMaterials.length) > Number(0) ?
                    EventMaterials.map((Material) => {
                        if( Materials.some(item => Material.material_id === item) ){
                            return(
                                <MaterialCard Material={Material} constData={this.state.constData}/>
                            );
                        }
                        return false;
                    }) : "Materials not available"
            );
        }
    }

    render() {

        return (
            this.MaterialsListStructure()
        );
    }
}

export default GetMaterials;