import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import AboutData from "../api/get-about";
import PageTitle from "../template/PageTitle";

class AboutList extends Component {

    constructor(props){
        super(props)

        this.state = {
            DefaultTitle: "About",
            constData: this.props.constData
        }
    }

    render(){

        return (
            <>

                <SideMenu constData={this.state.constData}/>
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle={this.state.DefaultTitle}/></h1>
                                </div>
                            </div>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="row card-body p-9">
                                    <div className="accordion" id="kt_accordion_1">
                                         <AboutData constData={this.state.constData}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData}/>
                </div>
            </>
        )
    }
}

export default AboutList;