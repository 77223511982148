import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ls from 'localstorage-slim';
import blank from "../../assets/media/avatars/blank.png";
import BackButton from "../template/backbutton";
import PageTitle from "../template/PageTitle";

class AttendeesDetails extends Component {

    constructor(props){
        ls.config.encrypt = true;
        super(props)

        this.state = {
            attendeeId: ls.get("attendeeId") !== null ? ls.get("attendeeId") : '',
            eventId: ls.get("eventId"),
            constData: this.props.constData
        }
    }

    componentDidMount() {
        ls.config.encrypt = true;
        var eventId = this.state.eventId;
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var AttendeeData = [];
        var CurrentAttendee = '';

        if( GetEventsUserData === null || GetEventsUserData.some(function(el) {return Number(el.eventId) === Number(eventId) && el.AttendeeData !== null;}) ) {
            GetEventsUserData.map(function (el) {
                if( Number(el.eventId) === Number(eventId) && ( AttendeeData === undefined || AttendeeData === null || AttendeeData === '' || AttendeeData.length === 0 ) ){

                    AttendeeData = el.AttendeeData;
                }
                return el;
            });

            if( AttendeeData !== '' && AttendeeData !== undefined ){
                AttendeeData.map((attendee, index) => {
                    if( attendee.profile_id === this.state.attendeeId &&  ( CurrentAttendee === undefined || CurrentAttendee === null || CurrentAttendee === '' ) ){
                        CurrentAttendee = attendee;
                    }
                    return attendee;
                })
            }

            this.setState({
                AttendeeData: AttendeeData,
                CurrentAttendee: CurrentAttendee,
            });
        }
    }

    render(){

        let constData = this.state.constData;

        var attendee = this.state.CurrentAttendee !== null ? this.state.CurrentAttendee : '';
        var Attendeesalut = '';
        var AttendeeFirstName = '';
        var AttendeeLastName = '';
        var AttendeeJobTitle = '';
        var AttendeeCompanyName = '';
        var AttendeeEmail = '';
        var AttendeeImage = blank;
        if(attendee !== undefined) {
            Attendeesalut = attendee.salut !== undefined ? attendee.salut : '';
            AttendeeFirstName = attendee.fname !== undefined ? attendee.fname : '';
            AttendeeLastName = attendee.lname !== undefined ? attendee.lname : '';
            AttendeeJobTitle = attendee.title !== undefined ? attendee.title : '';
            AttendeeCompanyName = attendee.company !== undefined ? attendee.company : '';
            AttendeeImage = (attendee.pic !== undefined && attendee.pic !== '' && null !== constData && '' !== constData ) ? constData.BaseUrl + 'uploads/account/profile/' + attendee.pic : blank;
            AttendeeEmail = attendee.email !== undefined  ? attendee.email : '';
        }

        return (
            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Attendees"/></h1>
                                </div>
                                <div className="d-flex flex-wrap my-2">
                                    <BackButton constData={this.state.constData} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="flex-column flex-lg-row-auto w-lg-300px w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body">
                                            <div className="d-flex flex-center flex-column py-5">
                                                <div className="symbol symbol-160px symbol-circle mb-7">
                                                    <img src={AttendeeImage} alt={AttendeeFirstName}/>
                                                </div>
                                                <a href="#AttendeeName" className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">{Attendeesalut} {AttendeeFirstName} {AttendeeLastName}</a>
                                                <div className="mb-9">
                                                    <div className="badge badge-lg badge-light-primary d-inline">{AttendeeJobTitle}</div>
                                                </div>
                                            </div>
                                            <h4 className="mb-2 fs-5">Company</h4>
                                            <p className="fs-5 text-gray-900">{AttendeeCompanyName}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-10">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="kt_user_view_overview_tab"
                                             role="tabpanel">
                                            <div className="card mb-6 mb-xl-9">
                                                <div className="card-header cursor-pointer">
                                                    <div className="card-title flex-column">
                                                        <h2 className="mb-1">Contact Info</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body p-9 pb-2">
                                                    <div id="kt_user_view_details" className="collapse show">
                                                        <div className="row pb-5 fs-6">
                                                            {AttendeeEmail ? <div className="row mb-7">
                                                                <label className="col-lg-3 fw-bold text-muted">Email</label>
                                                                <div className="col-lg-9">
                                                                    <a href={"mailto:" + AttendeeEmail }
                                                                       target="_blank" rel="noreferrer"><span
                                                                        className="fw-bolder fs-6">{AttendeeEmail}</span></a>
                                                                </div>
                                                            </div> : ''}
                                                            <div className="row mb-7">
                                                                <label
                                                                    className="col-lg-3 fw-bold text-muted">Phone</label>
                                                                <div className="col-lg-9">
                                                                    <span className="fw-bolder fs-6 text-gray-800">+91 9824895645</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label
                                                                    className="col-lg-3 fw-bold text-muted">Address</label>
                                                                <div className="col-lg-9">
                                                                    <span className="fw-bolder fs-6 text-gray-800">101 Collin Street, Melbourne 3000 VIC, Australia</span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-3 fw-bold text-muted">Social</label>
                                                                <div className="col-lg-9">
                                                                    <div className="mb-6">
                                                                        <a href="facebook.com/timchand"
                                                                        className="fw-bolder me-2" target="_blank" rel="noreferrer">
                                                                            <i className="bi bi-facebook text-facebook-color fs-1 me-2"></i>
                                                                            <span>facebook.com/timchand</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="mb-6">
                                                                        <a href="#linkedinTitle"
                                                                        className="fw-bolder me-2"
                                                                        target="_blank" rel="noreferrer">
                                                                            <i className="bi bi-linkedin text-linkedin-color fs-1 me-2"></i>
                                                                            <span>linkedin.com/timchand</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="mb-6">
                                                                        <a href="https://twitter.com/timchand"
                                                                        className="fw-bolder me-2" target="_blank" rel="noreferrer">
                                                                            <i className="bi bi-twitter text-twitter-color fs-1 me-2"></i>
                                                                            <span>@timchand</span>
                                                                        </a>
                                                                    </div>
                                                                    <div className="mb-6">
                                                                        <a href="#googleTitle"
                                                                        className="fw-bolder me-2"
                                                                        target="_blank" rel="noreferrer">
                                                                            <i className="bi bi-google text-google-plus-color fs-1 me-2"></i>
                                                                            <span>plus.google.com/timchnad</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default AttendeesDetails;