import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ls from 'localstorage-slim';
import {getRandomNumber} from "../getRandomNumber";
import axios from "axios";
const {
    REACT_APP_HOME_URL,
    PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC
} = process.env;

const LoginVerificationScreen = (props) => {
    ls.config.encrypt = true;

    const {VerifyLogin,handlePinChange, UserEmail, errorMessage, loginText, pinText, userStatus,constData} = props;

    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState('');

    var eventId = ls.get("eventId");
    var PreLoginSession = ls.get("PreLoginSession");
    var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));

    if( null !== constData) {

        if( undefined !== constData.LoginType &&
            'P' === constData.LoginType &&
            PreLoginSession !== undefined &&
            PreLoginSession !== null && PreLoginSession !== ''
        ){
            window.location.href = REACT_APP_HOME_URL+'/';
        } else if( undefined === constData.LoginType || 'P' !== constData.LoginType ) {

            if(null !== GetEventsUserData) {
                GetEventsUserData.map(function (el) {
                    if (userStatus === 'A' &&
                        Number(el.eventId) === Number(eventId) &&
                        el.UserLoginSession !== null &&
                        el.UserLoginSession !== undefined &&
                        el.UserLoginSession !== ""
                    ) {
                        window.location.href = REACT_APP_HOME_URL + '/dashboard';
                    }
                    return el;
                });
            }
        }
    }

    const handleResendEmail = () => {

        if( null !== constData ) {

            var ktResendEmail = document.getElementById('kt_resend_email');

            if( undefined !== ktResendEmail && '' !== ktResendEmail && null !== ktResendEmail ){
                ktResendEmail.classList.add('disabled');
            }

            var eventId = ls.get("eventId");
            const data = {
                url: constData.ApiBaseUrl+'/post/index.php',
                method: "POST",
                post_fields: {
                    "id":getRandomNumber,
                    "method":"signUp",
                    "params":{
                        "email":UserEmail,
                        "app_version": REACT_APP_VERSION,
                        "pkg_nm": REACT_APP_PKG_NAME,
                        "lang_id": REACT_APP_LANG_ID,
                        "event_id": eventId
                    },
                    "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {
                let result = res.data !== undefined ? res.data : "";

                if(result.hasOwnProperty('error')){
                    props.errorMessage = result.error.message;
                } else if (result.hasOwnProperty('result') && result.result.code === 200) {
                    props.LoginSession = true;
                    props.AccessToken = result.result.access_token;
                    setSuccessMessage( t("We have sent 4 digit code in you email successfully."));

                    if( result.result.hasOwnProperty('hide_resend_email_btn') && undefined !== result.result.hide_resend_email_btn && 1 === result.result.hide_resend_email_btn){
                        ktResendEmail.classList.add('d-none');
                    }

                    setTimeout(function (){
                        setSuccessMessage("");
                    }, 5000);
                }
                if( undefined !== ktResendEmail && '' !== ktResendEmail && null !== ktResendEmail ){
                    ktResendEmail.classList.remove('disabled');
                }
            }).catch(error => {});
        }
    }

    return (
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form className="form w-100" onSubmit={VerifyLogin} noValidate={"novalidate"} id="kt_sign_in_form" action="#">
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">{loginText}</h1>
                </div>
                <div className="fv-row mb-10">
                    <input className="form-control form-control-lg form-control-solid" value={UserEmail}
                           disabled type="text" name="email" autoComplete="off"/>
                </div>
                <div className="text-center mb-10">
                    <div className="text-gray-600 fw-bold fs-6">{pinText}</div>
                </div>
                <div className="fv-row mb-10">
                    <div className="d-flex flex-stack mb-2">
                        <label className="form-label fw-bolder text-dark fs-6 mb-0">{t("PIN")}</label>
                    </div>
                    <input className="form-control form-control-lg form-control-solid" onChange={handlePinChange} placeholder={t('Enter PIN here')}
                           type="password" name="password" autoComplete="off"/>
                    <span className={"error-message"} style={{color: 'red'}}>{errorMessage}</span>
                </div>
                <div className="text-center">
                    <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                        <span className="indicator-label">{t("Get Started")}</span>
                        <span className="indicator-progress">{t("Please wait...")}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                    <button onClick={handleResendEmail} type="button" id="kt_resend_email" className="btn btn-lg w-100 mb-5">
                        <span className="indicator-label">{t("Resend Email")}</span>
                    </button>
                    {(successMessage !== '') ? (
                        <span className={"success-message"} style={{color: 'green'}}>{successMessage}</span>
                    ) : ''}
                </div>
            </form>
        </div>
    );
};

export default LoginVerificationScreen;