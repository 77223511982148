import React, { Component } from 'react';
import axios from 'axios';
import ls from 'localstorage-slim';
import ScheduleList from "../template/schedule-list";
import {format} from "date-fns";
import {getRandomNumber} from '../getRandomNumber';

const {
    PUBLIC_URL,
    REACT_APP_LOCAL_ACCESS,
    REACT_APP_VERSION,
    REACT_APP_PKG_NAME,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC
} = process.env;

class AgendaData extends Component {

    constructor(props) {

        ls.config.encrypt = true;

        super(props);

        var eventId = ls.get("eventId");
        var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        var virtualSession = false;
        var Dashboard = false;
        if (props.VirtualSession !== undefined) {
            virtualSession = true;
        }

        if (props.Dashboard !== undefined) {
            Dashboard = true;
        }

        var ProfileId = '';
        var appGroupUsers = [];
        var app_group_connections = [];
        if (GetEventsUserData !== undefined && GetEventsUserData !== null) {
            GetEventsUserData.map(function (el) {
                if (Number(el.eventId) === Number(eventId) && el.UserProfile !== undefined) {
                    var UserData = el.UserProfile;
                    ProfileId = UserData.profile_id;
                }
                if (Number(el.eventId) === Number(eventId)) {
                    appGroupUsers = el.app_group_users;
                    app_group_connections = el.app_group_connections;
                }
                return el;
            });
        }

        this.state = {
            isAgendaDataArr: false,
            AgendaDataArr: [],
            code: 400,
            eventId: ls.get('eventId'),
            DateList: [],
            virtualSession: virtualSession,
            Dashboard: Dashboard,
            ProfileId: ProfileId,
            appGroupUsers: appGroupUsers,
            app_group_connections: app_group_connections,
            constData: props.constData,
        }
    }

    dateFormater = (date, formats = 'y-m-d') => {
        var CurrentDate = '' !== date ? new Date(date) : new Date();

        var formatDate = '';
        if( formats === 'y-m-d' ) {
            formatDate = `${CurrentDate.getFullYear()}-${CurrentDate.getMonth() + 1}-${CurrentDate.getDate()}`;
        } else if( formats === 'y_m_d' ) {
            formatDate = `${CurrentDate.getFullYear()}_${CurrentDate.getMonth() + 1}_${CurrentDate.getDate()}`;
        } else if( formats === 'd_m_y' ) {
            formatDate = `${CurrentDate.getDate()}_${CurrentDate.getMonth() + 1}_${CurrentDate.getFullYear()}`;
        } else if( formats === 'd-m-y' ) {
            formatDate = `${CurrentDate.getDate()}-${CurrentDate.getMonth() + 1}-${CurrentDate.getFullYear()}`;
        } else if( formats === 'd' ) {
            formatDate = `${CurrentDate.getDate()}`;
        } else if( formats === 'm' ) {
            formatDate = `${CurrentDate.getMonth()+1}`;
        } else if( formats === 'y' ) {
            formatDate = `${CurrentDate.getFullYear()}`;
        } else if( formats === 'D' ) {
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            formatDate = days[CurrentDate.getDay()];
        } else if( formats === 'M' ) {
            formatDate = format(CurrentDate, "MMM");
        } else {
            formatDate = format(CurrentDate, formats);
        }

        return formatDate;
    }

    GetAgendas = async () => {

        let constData = this.state.constData;

        var agendasLists = [];
        if( '' !== constData && null !== constData ) {

            ls.config.encrypt = true;
            const isLocalAccess = REACT_APP_LOCAL_ACCESS;
            var eventId = this.state.eventId;
            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            var EventAgendaLocations = [];
            var EventAgendaSponsors = [];
            var AgendaLocations = [];
            var EventAgendaCategories = [];
            var EventCategories = [];
            var EventAgendaPeople = [];
            var EventAgendaLiveSettings = [];
            var LiveSessionPreference = [];

            if( GetEventsUserData === null && Number(isLocalAccess) === 0  ) {

                const data = {
                    url: constData.ApiBaseUrl+'/webapp/index.php',
                    method: "POST",
                    post_fields: {
                        "id": getRandomNumber,
                        "method": "getAgendaList",
                        "params": {
                            "app_version": REACT_APP_VERSION,
                            "event_agendas": "",
                            "event_agenda_categories": "",
                            "event_agenda_live_settings": "",
                            "live_session_preference": "",
                            "event_agenda_locations": "",
                            "event_agenda_people": "",
                            "event_agenda_sponsors": "",
                            "event_categories": "",
                            "event_locations": "",
                            "pkg_nm": REACT_APP_PKG_NAME,
                            "lang_id": REACT_APP_LANG_ID,
                            "event_id": eventId
                        }, "jsonrpc": REACT_APP_JSON_RPC
                    },
                };

                await axios({
                    url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }).then(res => {

                    var result = res.data !== undefined ? res.data[0].result : "";
                    if (undefined !== result && "" !== result && undefined !== result.code && result.code === 200) {
                        var SpeakerExists = false;
                        if( GetEventsUserData !== null ){
                            GetEventsUserData = GetEventsUserData.map(function(el) {
                                if( Number( el.eventId )  === Number( eventId ) ) {
                                    SpeakerExists = true;
                                    el.AgendasData = result.event_agendas;
                                    el.AgendaLocations = result.event_locations;
                                    el.EventCategories = result.event_categories;
                                    el.EventAgendaLocations = result.event_agenda_locations;
                                    el.EventAgendaCategories = result.event_agenda_categories;
                                    el.EventAgendaSponsors = result.event_agenda_sponsors;
                                    el.EventAgendaPeople = result.event_agenda_people;
                                    el.EventAgendaLiveSettings = result.event_agenda_live_settings;
                                    el.LiveSessionPreference = result.live_session_preference;
                                }
                                return el;
                            });
                            if( !SpeakerExists ){
                                agendasLists = {
                                    eventId:eventId,
                                    AgendasData: result.event_agendas,
                                    AgendaLocations: result.event_locations,
                                    EventAgendaLocations: result.event_agenda_locations,
                                    EventCategories: result.event_categories,
                                    EventAgendaCategories: result.event_agenda_categories,
                                    EventAgendaSponsors: result.event_agenda_sponsors,
                                    EventAgendaPeople: result.event_agenda_people,
                                    EventAgendaLiveSettings: result.event_agenda_live_settings,
                                    LiveSessionPreference: result.live_session_preference,
                                }
                                GetEventsUserData.push(agendasLists);
                            }
                        }else {
                            GetEventsUserData = [{
                                eventId:eventId,
                                AgendasData: result.event_people,
                                AgendaLocations: result.event_locations,
                                EventAgendaLocations: result.event_agenda_locations,
                                EventCategories: result.event_categories,
                                EventAgendaCategories: result.event_agenda_categories,
                                EventAgendaSponsors: result.event_agenda_sponsors,
                                EventAgendaPeople: result.event_agenda_people,
                                EventAgendaLiveSettings: result.event_agenda_live_settings,
                                LiveSessionPreference: result.live_session_preference,

                            }]
                        }
                        if( GetEventsUserData !== null ){
                            GetEventsUserData.map(function(el) {
                                if( Number( el.eventId ) === Number( eventId )){
                                    agendasLists = el.AgendasData;
                                    EventCategories = el.EventCategories;
                                    AgendaLocations = el.AgendaLocations;
                                    EventAgendaLocations = el.EventAgendaLocations;
                                    EventAgendaCategories = el.EventAgendaCategories;
                                    EventAgendaSponsors = el.EventAgendaSponsors;
                                    EventAgendaPeople = el.EventAgendaPeople;
                                    EventAgendaLiveSettings = el.EventAgendaLiveSettings;
                                    LiveSessionPreference = el.LiveSessionPreference;
                                }
                                return el;
                            })
                            this.setState({
                                isAgendaDataArr: true,
                                AgendaDataArr: agendasLists,
                                EventCategories: EventCategories,
                                AgendaLocations: AgendaLocations,
                                EventAgendaLocations: EventAgendaLocations,
                                EventAgendaCategories: EventAgendaCategories,
                                EventAgendaSponsors: EventAgendaSponsors,
                                EventAgendaPeople: EventAgendaPeople,
                                EventAgendaLiveSettings: EventAgendaLiveSettings,
                                LiveSessionPreference: LiveSessionPreference,
                            })

                            ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                            this.DateListHandler(agendasLists, EventAgendaPeople, EventAgendaSponsors);
                        }
                    }
                }).catch(error => {});
            }else{
                if( GetEventsUserData !== null ){
                    GetEventsUserData.map(function(el) {
                        if( Number( el.eventId ) === Number( eventId ) ){
                            agendasLists = el.AgendasData;
                            EventCategories = el.EventCategories;
                            AgendaLocations = el.AgendaLocations;
                            EventAgendaLocations = el.EventAgendaLocations;
                            EventAgendaCategories = el.EventAgendaCategories;
                            EventAgendaSponsors = el.EventAgendaSponsors;
                            EventAgendaPeople = el.EventAgendaPeople;
                            EventAgendaLiveSettings = el.EventAgendaLiveSettings;
                            LiveSessionPreference = el.LiveSessionPreference;
                        }
                        return el;
                    })
                    this.setState({
                        isAgendaDataArr: true,
                        AgendaDataArr: agendasLists,
                        EventCategories: EventCategories,
                        AgendaLocations: AgendaLocations,
                        EventAgendaLocations: EventAgendaLocations,
                        EventAgendaCategories: EventAgendaCategories,
                        EventAgendaSponsors: EventAgendaSponsors,
                        EventAgendaPeople: EventAgendaPeople,
                        EventAgendaLiveSettings: EventAgendaLiveSettings,
                        LiveSessionPreference: LiveSessionPreference
                    })
                    this.DateListHandler(agendasLists, EventAgendaPeople, EventAgendaSponsors);
                }
            }
        }

        return agendasLists;
    }

    DateListHandler = ( agendasLists = [], EventAgendaPeople = [], EventAgendaSponsors = [] ) => {
        ls.config.encrypt = true;

        var ProfileId = this.state.ProfileId;
        var app_group_connections = this.state.app_group_connections;
        var appGroupUsers = this.state.appGroupUsers;
        EventAgendaSponsors = this.state.EventAgendaSponsors;
        var AgendaSponsors = [];
        var Dashboard =  this.state.Dashboard;
        var speakerId = this.props.speakerId;

        var ActiveDate = '';
        var DateListArr = [];
        var virtualSession = this.state.virtualSession;
        let CurrentDate = new Date();

        CurrentDate = `${CurrentDate.getDate()}-${CurrentDate.getMonth() + 1}-${CurrentDate.getFullYear()}`;

        if( undefined !== speakerId && undefined !== EventAgendaPeople ){
            EventAgendaPeople.map( ( speaker ) => {
                if (speaker.fk_person_id === speakerId  && speaker.status === "A" ){
                    AgendaSponsors.push(speaker.fk_agenda_id);
                }
                return AgendaSponsors;
            });
        }

        if( this.state.DateList.length !== undefined && this.state.DateList.length === 0  ) {
            if (agendasLists !== '' && agendasLists !== undefined) {
                var AgendaDataArr = agendasLists;
                AgendaDataArr.map((e) => {
                    var display = false;
                    var agendaExists = true;

                    var DisplayEvent = true;
                    var regular_start_date = e.start_time !== undefined ? new Date(e.start_time.replace(/\s/, 'T')) : '';
                    var DateString = regular_start_date !== undefined ? new Date(regular_start_date) : '';
                    let AgendaDate = `${DateString.getDate()}-${DateString.getMonth() + 1}-${DateString.getFullYear()}`;

                    if( ! Dashboard || ( Dashboard && CurrentDate === AgendaDate ) ) {
                        if (speakerId !== undefined) {
                            DisplayEvent = false;
                            if (AgendaSponsors !== undefined && AgendaSponsors.length !== 0) {
                                AgendaSponsors.map((sponsor) => {
                                    if (sponsor === e.agenda_id) {
                                        DisplayEvent = true;
                                        return DisplayEvent;
                                    }
                                    return false;
                                });
                            }
                        }

                        if (Number(e.fk_event_id) === Number(this.state.eventId) && e.status !== null && e.status === "A" && DisplayEvent) {
                            if ((virtualSession === true && e.show_broadcast === "1" && e.broadcast_link !== '') || virtualSession === false) {
                                if (Number(e.is_private) === Number(1) && app_group_connections !== undefined && appGroupUsers !== undefined) {
                                    app_group_connections.map((connection) => {
                                        if (Number(connection.fk_feature_id) === Number(1) && Number(connection.row_id) === Number(e.agenda_id)) {
                                            appGroupUsers.map((group) => {
                                                if (Number(group.fk_user_id) === Number(ProfileId) && Number(group.fk_group_id) === Number(connection.fk_group_id)) {
                                                    display = true;
                                                }
                                                return group;
                                            });
                                        }
                                        return connection;
                                    })
                                }
                                if ((Number(e.is_private) === Number(0) && regular_start_date !== undefined && regular_start_date !== null) || display) {
                                    var dateKey = regular_start_date.getFullYear() + '_' + (Number(regular_start_date.getMonth()) + 1) + '_' + regular_start_date.getDate();
                                    var count = 0;
                                    DateListArr.some(function (agendalist) {
                                        var dataexists = true;
                                        if (agendalist.agendaDateKey === dateKey) {
                                            agendaExists = false;
                                            agendalist.agendaData.some(function (agenda) {
                                                if (Number(e.agenda_id) === Number(agenda.agenda_id)) {
                                                    dataexists = false;
                                                }
                                            })
                                            if (dataexists) {
                                                dataexists = true;
                                                var key = count;
                                                DateListArr[key].agendaData.push(e);
                                            }
                                        }
                                        count++;
                                    })

                                    if (agendaExists) {
                                        agendaExists = true;
                                        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                                        let AgendaDate = `${DateString.getDate()}-${DateString.getMonth() + 1}-${DateString.getFullYear()}`;
                                        if (CurrentDate === AgendaDate) {
                                            ActiveDate = dateKey;
                                        }
                                        var start_date = undefined !== DateString ? format(DateString, 'MMM dd, yyyy') : '';
                                        var agendaWeekday = undefined !== regular_start_date ? days[DateString.getDay()] : '';
                                        var agenda = [];
                                        agenda = {
                                            agendaDateKey: dateKey,
                                            agendaDay: agendaWeekday,
                                            agendaDate: start_date,
                                            agendaData: [e]
                                        }

                                        DateListArr.push(agenda);
                                    }
                                }
                            }
                        }
                        return e;
                    }

                    return e;
                })

                DateListArr.sort((a, b) => new Date(a.agendaDateKey.split('_')) - new Date(b.agendaDateKey.split('_')));

                this.setState({
                    DateList: DateListArr,
                    ActiveDate: ActiveDate
                });
            }
            return DateListArr;
        }
    }

    ScheduleStructure = () => {
        if( !this.state.isAgendaDataArr && this.state.AgendaDataArr === undefined ){
             this.GetAgendas();
        } else if( !this.state.isAgendaDataArr && this.state.AgendaDataArr.length === 0 ) {
            this.GetAgendas();
        }
        ls.config.encrypt = true;
        return(
            <div className="card card-flush mb-6 mb-xl-9">
                <div className="card-body p-9 pt-4">
                    { ! this.props.Dashboard ?
                        <ul className={"nav nav-pills d-flex flex-nowrap hover-scroll-x py-2"}>
                            {this.ScheduleDateHandeler()}
                        </ul> : "" }
                    <div className={"tab-content"}>
                        {this.ScheduleListHandler()}
                    </div>
                </div>
            </div>
        )
    }

    ScheduleDateHandeler = () =>{

        var ActiveDate = "";
        if( undefined !== this.state.ActiveDate && "" !== this.state.ActiveDate && null !== this.state.ActiveDate ){
            ActiveDate = this.state.ActiveDate;
        }
        var DateListArr = this.state.DateList;

        var uniqueDateList = [];
        var itemsDateList = {};
        if(DateListArr && DateListArr.length > 0 ) {
            DateListArr.map(function (el) {
                var dateKey = el.agendaDateKey;
                if(!itemsDateList[dateKey]) {
                    uniqueDateList.push(el);
                    itemsDateList[dateKey] = true;
                }
            });
        }

        return(
            "" !== uniqueDateList && uniqueDateList.length > 0 ?
                 [...uniqueDateList].reverse().map((date, index) => {
                    var ActiveClass = '';
                    if( ActiveDate === '' ){
                        ActiveDate = date.agendaDateKey;
                        ActiveClass = " active";
                        this.setState({
                            ActiveDate: ActiveDate
                        })
                    }else if( ActiveDate === date.agendaDateKey ){
                        ActiveClass = " active";
                    }else{
                        ActiveClass = "";
                    }
                    return(
                        <li key={date.agendaDateKey} className={"nav-item me-1"}>
                            <a className={"nav-link btn d-flex flex-column flex-center min-w-90px me-2 py-4 btn-active-primary " + ActiveClass}
                               data-bs-toggle="tab" href={"#kt_schedule_day_" + date.agendaDateKey }>
                                <span className={"opacity-50 fs-7 fw-bold"}>{date.agendaDay}</span>
                                <span className={"fs-6 fw-boldest min-w-90px"}>{date.agendaDate}</span>
                            </a>
                        </li>
                    );
                }) : ""
        );
    }

    getDisplayAgenda = ( date, type='f' ) => {

        const allAgendaData = this.state.AgendaDataArr;

        if( undefined === allAgendaData || null === allAgendaData || allAgendaData.length === 0 ) {
            return [];
        }

        var allAgendaDates = [];
        allAgendaData.map( (agenda) => {
            allAgendaDates.push(new Date(agenda.start_time));
            return agenda;
        });

        var maxAgendaDates = allAgendaDates.length > 0 ? new Date(Math.max(...allAgendaDates)) : '';
        var minAgendaDates = allAgendaDates.length > 0 ? new Date(Math.min(...allAgendaDates)) : '';

        const maxdate = this.dateFormater(maxAgendaDates);
        const mindate = this.dateFormater(minAgendaDates);

        var getSelectedAgenda = [];
        allAgendaData.map( (agenda) => {
            const DateString = agenda.start_time !== undefined ? agenda.start_time : '';
            const AgendaDate = this.dateFormater(DateString);

            if( date === AgendaDate ) {
                getSelectedAgenda.push(agenda);
            }
            return agenda;
        });

        if( getSelectedAgenda.length === 0 ) {
            var current = new Date(date);
            if( type === 'p' ) {
                if( date === mindate ) {
                    getSelectedAgenda = [];
                } else {
                    var prevDay = new Date(current);
                    prevDay.setDate(current.getDate() - 1);
                    var fullPrevdate = this.dateFormater(prevDay);
                    getSelectedAgenda = this.getDisplayAgenda( fullPrevdate, 'p' );
                }
            } else {
                if( date === maxdate || ( undefined !== this.props.Dashboard && this.props.Dashboard ) ) {
                    getSelectedAgenda = this.getDisplayAgenda( this.dateFormater(''), 'p' );
                } else {
                    var nextDay = new Date(current);
                    nextDay.setDate(current.getDate() + 1);
                    var fullNextdate = this.dateFormater(nextDay);
                    getSelectedAgenda = this.getDisplayAgenda( fullNextdate, 'f' );
                }
            }
        }

        return getSelectedAgenda
    };

    ScheduleListHandler = () => {
        if( undefined !== this.props.Dashboard && this.props.Dashboard ) {
            const data = this.getDisplayAgenda(this.dateFormater(''));
            if (data !== '' && data !== undefined && data.length > 0 ) {
                const eventDate = data.length > 0 ? data[0].start_time:'';
                const getAgendaList = [{
                    agendaDateKey: '' !== eventDate ? this.dateFormater(eventDate, 'y_m_d') : '',
                    agendaDay: '' !== eventDate ? this.dateFormater(eventDate, 'D') : '',
                    agendaDate: '' !== eventDate ? this.dateFormater(eventDate, 'MMM dd, yyyy'): '',
                    agendaData: data.length > 0 ? data : '',
                }];

                return (
                    getAgendaList.map((agendas, index) => {
                        return (
                            <ScheduleList key={agendas.agendaDateKey} Dashboard={this.props.Dashboard}
                                          activeTab={agendas.agendaDateKey}
                                          EventAgendaCategories={this.state.EventAgendaCategories}
                                          EventAgendaLocations={this.state.EventAgendaLocations}
                                          AgendaLocations={this.state.AgendaLocations}
                                          EventCategories={this.state.EventCategories} agenda={agendas}/>
                        );
                    })
                );
            } else {
                return "No result found.";
            }
        } else {

            if (this.state.DateList !== '' && this.state.DateList !== undefined) {

                return (
                    this.state.DateList.map((agendas, index) => {
                        return (
                            <ScheduleList key={agendas.agendaDateKey} Dashboard={this.props.Dashboard}
                                          activeTab={this.state.ActiveDate}
                                          EventAgendaCategories={this.state.EventAgendaCategories}
                                          EventAgendaLocations={this.state.EventAgendaLocations}
                                          AgendaLocations={this.state.AgendaLocations}
                                          EventCategories={this.state.EventCategories} agenda={agendas}/>
                        );
                    })
                );
            } else {
                return "No result found.";
            }
        }
    }

    render() {
        return (
            <div className="col-xxl-12">
                {this.ScheduleStructure()}
            </div>
        );
    }
}
export default AgendaData;