import React from 'react';
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import ls from 'localstorage-slim';
import axios from "axios";
import {getRandomNumber} from "../getRandomNumber";
const {
    REACT_APP_URL,
    REACT_APP_API_URL,
    REACT_APP_HOME_URL,
    PUBLIC_URL,
    REACT_APP_LANG_ID,
    REACT_APP_VERSION,
    REACT_APP_PF,
    REACT_APP_UDID,
    REACT_APP_OS_VER,
    REACT_APP_DEVICE_NAME,
    REACT_APP_JSON_RPC
} = process.env;

const EventCard = (props) => {

    ls.config.encrypt = true;
    const { t } = useTranslation();
    const {event,userLogin,EventType, constData} = props;

    var baseUrl = REACT_APP_URL+REACT_APP_API_URL+'/';
    var start_date = '';
    var end_date = '';
    var LinkTag = '';
    var GetEventsUserData = '';
    var PreLoginSession = '';
    var PreLoginUserProfile = '';
    var UserLogin = '';
    var loggedIn = false;
    if( null != constData ) {

        baseUrl = constData.BaseUrl;
        start_date = event.start_date !== undefined ? format(new Date(event.start_date.replace(/\s/, 'T')), 'MMM dd, yyyy') : '';
        end_date = event.end_date !== undefined ? format(new Date(event.end_date.replace(/\s/, 'T')), 'MMM dd, yyyy') : '';
        LinkTag = userLogin !== '' || Number( event.login_type ) !== Number( 0 )  ? REACT_APP_HOME_URL+"/login" : REACT_APP_HOME_URL+"/dashboard";
        GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
        PreLoginSession = ls.get("PreLoginSession");
        PreLoginUserProfile = ls.get("PreLoginUserProfile");
        UserLogin = true;

        if( undefined !== constData.LoginType && 'P' === constData.LoginType ){

            if( '' !== PreLoginUserProfile && null !== PreLoginUserProfile ) {
                if( PreLoginUserProfile.status === 'A' ) {
                    LinkTag = REACT_APP_HOME_URL + "/dashboard";
                } else if( PreLoginUserProfile.status === 'R' ) {
                    LinkTag = REACT_APP_HOME_URL+"/my-profile-edit";
                }
            }
        } else {
            if (typeof GetEventsUserData !== "undefined" && GetEventsUserData !== null) {
                GetEventsUserData.map(function (el) {
                    if (el.eventId === event.event_id) {
                        return loggedIn = el.UserLoginSession !== undefined;
                    }
                    return '';
                })
            }
        }
    }

    const manageEventCardOnClick = (event, LinkTag) => {

        if( null != constData && EventType !== 'past' ) {

            ls.config.encrypt = true;

            var GetEventsUserData = JSON.parse(ls.get("eeuserdata"));
            var PreLoginSession = ls.get("PreLoginSession");
            var PreLoginUserProfile = ls.get("PreLoginUserProfile");

            ls.set('eventId', event.event_id);
            ls.set('eventTitle', event.event_name);
            ls.set('eventType', event.login_type);

            if(constData.LoginType === undefined || 'P' !== constData.LoginType ){

                if( undefined !== GetEventsUserData && null !== GetEventsUserData ) {
                    GetEventsUserData.map(function (el) {
                        if (el.eventId === event.event_id) {
                            if( null !== PreLoginSession && undefined !== PreLoginSession ){
                                el.UserLoginSession = PreLoginSession;
                            }
                            if( null !== PreLoginUserProfile && undefined !== PreLoginUserProfile ){
                                el.UserProfile = PreLoginUserProfile;
                            }
                            UserLogin = false;
                        }
                        return el;
                    })
                }

                if(UserLogin) {

                    if( undefined !== PreLoginSession &&
                        null !== PreLoginSession &&
                        undefined !== PreLoginUserProfile &&
                        null !== PreLoginUserProfile) {
                        var eventObj = {
                            eventId: event.event_id,
                            UserLoginSession: PreLoginSession,
                            UserProfile: PreLoginUserProfile,
                        }
                        if( undefined === GetEventsUserData || null === GetEventsUserData ) {
                            GetEventsUserData = [];
                        }
                        GetEventsUserData.push(eventObj);
                    }
                }

                ls.set("eeuserdata",JSON.stringify(GetEventsUserData));

                setTimeout( function (){
                    if( props.EventType !== 'past' ) {
                        window.location.href = ( loggedIn && ! UserLogin ) ? REACT_APP_HOME_URL + "/dashboard" : LinkTag;
                    }
                },800);

            } else {

                var userProfile = "";
                var userEmail = PreLoginUserProfile.email;
                if( Number( event.login_type ) !== Number( 0 ) ) {

                    const data = {
                        url: constData.ApiBaseUrl+'/post/index.php',
                        method: "POST",
                        post_fields: {
                            "method": "registerDeviceMultiple",
                            "id": getRandomNumber,
                            "params": {
                                "lang_id": REACT_APP_LANG_ID,
                                "app_version": REACT_APP_VERSION,
                                "platform": REACT_APP_PF,
                                "udid": REACT_APP_UDID,
                                "os_version": REACT_APP_OS_VER,
                                "email": userEmail,
                                "event_id": event.event_id,
                                "app_login": constData.LoginType,
                                "name": REACT_APP_DEVICE_NAME,
                                "pf": REACT_APP_PF
                            },
                            "jsonrpc": REACT_APP_JSON_RPC,
                        },
                    };

                    axios({
                        url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                        },
                    }).then(res => {
                        if( res.status === 200 && undefined !== res.data.result.profile ){

                            userProfile = res.data.result.profile;
                            if( undefined !== GetEventsUserData && null !== GetEventsUserData ){
                                GetEventsUserData.map(function (el) {
                                    if (el.eventId === event.event_id) {
                                        if( null !== PreLoginSession && undefined !== PreLoginSession ){
                                            el.UserLoginSession = PreLoginSession;
                                        }
                                        if( null !== PreLoginUserProfile && undefined !== PreLoginUserProfile ){
                                            el.UserProfile = PreLoginUserProfile;
                                        }
                                        UserLogin = false;
                                    }
                                    return el;
                                })
                            }

                            if( UserLogin ){
                                if( undefined !== PreLoginSession &&
                                    null !== PreLoginSession &&
                                    undefined !== PreLoginUserProfile &&
                                    null !== PreLoginUserProfile) {

                                    ls.set("PreLoginSession", PreLoginSession);
                                    if( undefined !== userProfile ){
                                        ls.set("PreLoginUserProfile", userProfile);
                                        if( userProfile.status === 'A' ) {
                                            LinkTag = REACT_APP_HOME_URL + "/dashboard";
                                            ls.set('login_status', 'success');
                                            ls.set('user_login_status', 'success');
                                        }
                                    }

                                    var eventObj = {
                                        eventId: event.event_id,
                                        UserLoginSession: PreLoginSession,
                                        UserProfile: userProfile,
                                    }
                                    if(undefined === GetEventsUserData || null === GetEventsUserData){
                                        GetEventsUserData = [];
                                    }
                                    GetEventsUserData.push(eventObj);
                                }
                            } else {
                                if( undefined !== userProfile ){
                                    ls.set("PreLoginUserProfile", userProfile);
                                    if( userProfile.status === 'A' ) {
                                        LinkTag = REACT_APP_HOME_URL + "/dashboard";
                                        ls.set('login_status', 'success');
                                        ls.set('user_login_status', 'success');
                                    }
                                }
                            }

                            ls.set("eeuserdata",JSON.stringify(GetEventsUserData));
                            setTimeout( function (){
                                if( props.EventType !== 'past' ) {
                                   window.location.href= LinkTag;
                                }
                            },800);

                        } else {
                            alert("The user is not registered for this event.");
                        }
                    }).catch(error => {});
                } else {
                    window.location.href= LinkTag;
                }
            }
        }
    }

    return (
        <div key={event.event_id} className={`col-md-6 col-xl-4 test-card card-${props.EventType}`} id={"event_" + event.event_id} >
            <a onClick={(e) => {
                e.preventDefault();
                manageEventCardOnClick(event, LinkTag);
            } } href={`#${event.event_name}`} className={"card border-hover-primary"}>
                <div className={"card-header border-0 pt-9"}>
                    <div className={"card-title m-0"}>
                        <div className={"symbol symbol-70px w-70px bg-light"}>
                            <img src={ baseUrl + "/uploads/events/icon/" + event.icon} alt="event card"/>
                        </div>
                    </div>
                    {
                        props.EventType !== undefined && props.EventType === 'past' ? <div className={"card-toolbar"}><span className={"badge badge-light-success fw-bolder me-auto px-4 py-3"}>{t("PAST")}</span></div> : ''
                    }
                </div>
                <div className={"card-body p-9 pt-4"}>
                    <div className={"fs-3 fw-bolder text-dark mb-1"}>{event.event_name}</div>
                    <div className={"fs-5 text-gray-600"}>
                        <i className={"bi bi-calendar-date-fill"}></i> {start_date} {t("to")} {end_date}
                    </div>
                    <div className={"d-flex flex-wrap mt-5"}>
                        <div className={"border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"}>
                            <div className={"fw-bold text-gray-800"}><i className={"bi bi-geo-alt-fill"}></i> {event.location}</div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default EventCard;