import React, {Component} from 'react';
import logo from '../../assets/media/logos/ee-logo.png';
import SignUpScreen from "../template/SignUpScreen";
import validator from 'validator'
import SendUserVerificationCode from "../api/send-verification-code";
import VerifyUserLogin from "../api/verify-login-code";
import LoginVerificationScreen from "../template/LoginVerificationScreen";
import ls from "localstorage-slim";
import {getRandomNumber} from "../getRandomNumber";
import axios from "axios";

const {REACT_APP_URL,
    REACT_APP_API_URL,
    REACT_APP_HOME_URL,
    REACT_APP_VERSION,
    REACT_APP_LANG_ID,
    REACT_APP_JSON_RPC,
    PUBLIC_URL,
    REACT_APP_DEVICE_ID,
    REACT_APP_DEVICE_KEY,
    REACT_APP_PF
} = process.env;

class Login extends Component {

    constructor(props){
        super(props);

        const {constData} = props;

        this.state = {
            constData: constData,
            UserEmail: "",
            EmailSubmitted: false,
            VerificationCode: "",
            loginText: "Welcome to our Client Event.",
            pinText: "We have sent an email to the above address to validate your identity. Please check the spam folder in case you do not see the email in your inbox. Enter the 4 digit code that was included in the email for app access.",
            eventLogo: logo,
            getLoginSettings: false,
            Content: <SignUpScreen
                SendVerificationCode={this.SendVerificationCode}
                CreateFlag={this.CreateFlag}
                handleEmailChange={this.handleEmailChange}
                loginText={"Welcome to our Client Event."}
                pinText={"We have sent an email to the above address to validate your identity. Please check the spam folder in case you do not see the email in your inbox. Enter the 4 digit code that was included in the email for app access."}
                eventLogo={logo}
                constData={constData}
            />,
            displayContent: false
        }

        document.body.classList.add('bg-body');
    }

    getLoginWelcomeSettings = () => {

        let constData = this.state.constData;
        if( null !== constData && "" !== constData ) {

            var eventId = ls.get("eventId");
            var loginType = ls.get("LoginType");

            this.setState({
                getLoginSettings: true,
            });

            const data = {
                url: `${constData.ApiBaseUrl}/post/index.php`,
                method: "POST",
                post_fields: {
                    "id":getRandomNumber,
                    "method":"getLoginSettings",
                    "params":{
                        "app_version":REACT_APP_VERSION,
                        "pf":REACT_APP_PF,
                        "device_key": REACT_APP_DEVICE_KEY,
                        "app_device_id": REACT_APP_DEVICE_ID,
                        "event_id":eventId,
                        "login_type":loginType,
                        "lang_id": REACT_APP_LANG_ID
                    },
                    "jsonrpc": REACT_APP_JSON_RPC
                },
            };

            axios({
                url: PUBLIC_URL+'/functions.php?data='+JSON.stringify(data),
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(res => {

                let result = res.data !== undefined ? res.data.result : "";
                if (result.code !== undefined && result.code === 200) {

                    let eventLogoPath = REACT_APP_URL+REACT_APP_API_URL+'/uploads/events/logo/';
                    let eventLogoURL = result.event_logo ? eventLogoPath+result.event_logo : this.state.eventLogo;
                    let loginText = result.login_text !== undefined ? result.login_text: this.state.loginText;
                    let pinText = result.pin_text !== undefined ? result.pin_text: this.state.pinText;

                    this.setState({
                        getLoginSettings: true,
                        loginText: loginText,
                        pinText: pinText,
                        eventLogo: eventLogoURL,
                        Content: <SignUpScreen
                            SendVerificationCode={this.SendVerificationCode}
                            CreateFlag={this.CreateFlag}
                            handleEmailChange={this.handleEmailChange}
                            loginText={loginText}
                            pinText={pinText}
                            eventLogo={eventLogoURL}
                            constData={constData}
                        />,
                    });
                }

            }).catch(error => {
            });
        }
    }

    CreateFlag = () => {
        ls.config.encrypt = true;
        ls.set('login_status', 'success');
    }

    handleEmailChange = (e) => {
        if( e.target.value.length > 320 ){
            this.setState({
                Content: <SignUpScreen
                    SendVerificationCode={this.SendVerificationCode}
                    CreateFlag={this.CreateFlag}
                    handleEmailChange={this.handleEmailChange}
                    UserEmail={e.target.value}
                    errorMessage={"Max 320 character allowed"}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />
            });
        } else if ( e.target.value.length > 300 || e.target.value.length === 0 ) {
            this.setState({
                Content: <SignUpScreen
                    SendVerificationCode={this.SendVerificationCode}
                    CreateFlag={this.CreateFlag}
                    handleEmailChange={this.handleEmailChange}
                    UserEmail={e.target.value}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />,
                UserEmail: e.target.value
            });
        } else {
            this.setState({
                UserEmail: e.target.value
            });
        }
    }

    SendVerificationCode = (e) => {

        if (validator.isEmail(this.state.UserEmail)) {
            this.setState({
                Content: <SendUserVerificationCode
                    CreateFlag={this.CreateFlag}
                    VerifyLogin={this.VerifyLogin}
                    handlePinChange={this.handlePinChange}
                    userEmail={this.state.UserEmail}
                    SendVerificationCode={this.SendVerificationCode}
                    handleEmailChange={this.handleEmailChange}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />,
            });
        } else if ( undefined === this.state.UserEmail || null === this.state.UserEmail || "" === this.state.UserEmail ) {
            this.setState({
                Content: <SignUpScreen
                    CreateFlag={this.CreateFlag}
                    SendVerificationCode={this.SendVerificationCode}
                    handleEmailChange={this.handleEmailChange}
                    errorMessage={"Please fill the email field"}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />
            });
        } else {
            this.setState({
                Content: <SignUpScreen
                    CreateFlag={this.CreateFlag}
                    SendVerificationCode={this.SendVerificationCode}
                    handleEmailChange={this.handleEmailChange}
                    errorMessage={"Invalid email address."}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />
            });
        }

        e.preventDefault();
    }

    handlePinChange = (e) => {
        this.setState({
            VerificationCode: e.target.value
        });
    }

    VerifyLogin = (e) => {
        if( undefined === this.state.VerificationCode || null === this.state.VerificationCode || "" === this.state.VerificationCode ){
            this.setState({
                Content: <LoginVerificationScreen
                    CreateFlag={this.CreateFlag}
                    VerifyLogin={this.VerifyLogin}
                    handlePinChange={this.handlePinChange}
                    UserEmail={this.state.UserEmail}
                    errorMessage={"please enter the PIN"}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />
            });
        } else {
            this.setState({
                Content: <VerifyUserLogin
                    CreateFlag={this.CreateFlag}
                    VerifyLogin={this.VerifyLogin}
                    handlePinChange={this.handlePinChange}
                    UserEmail={this.state.UserEmail}
                    VerificationCode={this.state.VerificationCode}
                    loginText={this.state.loginText}
                    pinText={this.state.pinText}
                    eventLogo={this.state.eventLogo}
                    constData={this.state.constData}
                />,
                VerificationCode: ""
            });
        }
        e.preventDefault();
    }

    render(){

        if(!this.state.getLoginSettings) {
            this.getLoginWelcomeSettings();
        }

        if(this.state.Content) {
            setTimeout(() => {
                var spinner_container = document.getElementById('spinner_container');
                if (spinner_container !== null) {
                    spinner_container.style.display = "none";
                    this.setState({displayContent:true})
                }
            }, 800);
        }

        return (
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                <a href={REACT_APP_HOME_URL+"/"} className="mb-15">
                    <img alt="Logo" src={this.state.eventLogo} className="h-40px" />
                </a>
                {this.state.displayContent ? this.state.Content : ""}
            </div>
        )
    }
}

export default Login;