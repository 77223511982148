import React from "react";
import {useTranslation} from "react-i18next";
import { NavLink } from "./NavbarElements";
const {REACT_APP_HOME_URL} = process.env;

const Navbar = (props) => {
    const { t } = useTranslation();
    return (
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
                <NavLink className="menu-link px-2" to={REACT_APP_HOME_URL+"/"}>
                    {t('About')}
                </NavLink>
            </li>
            <li className="menu-item">
                <NavLink className="menu-link px-2" to={REACT_APP_HOME_URL+"/"}>
                    {t('Support')}
                </NavLink>
            </li>
            <li className="menu-item">
                <NavLink className="menu-link px-2" to={REACT_APP_HOME_URL+"/"}>
                    {t('Contact')}
                </NavLink>
            </li>
        </ul>
    );
};

export default Navbar;

