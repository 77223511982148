import React, { Component } from 'react';
import PageTitle from "../template/PageTitle";
import { withTranslation } from 'react-i18next';
const {REACT_APP_HOME_URL} = process.env;

class PageNotFound extends Component {

    constructor(props){

        super(props);

        this.state = {
            DefaultTitle: "404",
            constData: props.constData,
            redirect: props.redirect
        }

        if( undefined !== props.redirect && props.redirect ){
            if( props.constData.LoginType !== undefined && 'P' === props.constData.LoginType ) {
                window.location.href = REACT_APP_HOME_URL + '/login';
            } else {
                window.location.href = REACT_APP_HOME_URL;
            }
        }
    }

    render(){

        const { t } = this.props;

        if( undefined !== this.props.redirect && this.props.redirect ) {

            return (<div className="spinner-container" id="spinner_container">
                <div className="loading-spinner"></div>
            </div>);
        } else {

            return (
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl 404-content" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle={this.state.DefaultTitle}/></h1>
                                </div>
                            </div>
                            <div className="card card-xl-stretch mb-xl-8">
                                <div className="row card-body p-9">
                                    <p>{t('Sorry there is no any data found.')}</p>
                                    <a className={"back-to-home"} href={REACT_APP_HOME_URL+"/"} >{t('Go to Home')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
export default withTranslation()(PageNotFound);
