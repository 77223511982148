import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import GetSponsors from "../api/get-sponsors";
import PageTitle from "../template/PageTitle";

class SponsorList2 extends Component {

    constructor(props){
        super(props)

        var sponsorsLimit = 50;

        this.state = {
            constData: props.constData,
            content: <GetSponsors constData={this.state.constData} displayPagination={true} FeatureId={"3002"} activePage={1} sponsorsLimit={sponsorsLimit} handlePaginateSponsorList={this.handlePaginateSponsorList} />,
            sponsorsLimit: sponsorsLimit
        }
    }

    renderSponsorsData( ActivePage= 1 ){
        return (
            <GetSponsors constData={this.state.constData} displayPagination={true} FeatureId={"3002"} activePage={ActivePage} sponsorsLimit={this.state.sponsorsLimit} handlePaginateSponsorList={this.handlePaginateSponsorList} />
        )
    }

    handlePaginateSponsorList = (e) => {
        if( e !== undefined){
            this.setState({
                ActivePage: e,
                content : this.renderSponsorsData(e),
            })
        }else{
            this.setState({
                ActivePage: 1,
                content : this.renderSponsorsData(1),
            })
        }
    }

    render(){
        return (

            <>
                <SideMenu constData={this.state.constData} />
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-10 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Sponsors 2"/></h1>
                                </div>
                            </div>
                            {this.state.content}
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default SponsorList2;