import React, { Component } from 'react';
import Footer from "../template/footer";
import SideMenu from "../template/sideMenu";
import Header from "../template/header";
import ls from 'localstorage-slim';
import AttendeeData from "../api/get-attendee";
import PageTitle from "../template/PageTitle";

class AttendeesList extends Component {

    constructor(props) {
        ls.config.encrypt = true;
        super(props);

        var attendeeLimit = 50;
        var totalAttendeeCount = 0;

        this.state = {
            constData: props.constData,
            content: <AttendeeData constData={props.constData} fetch={"yes"}  displayPagination={true} activePage={1} attendeeLimit={attendeeLimit} handlePaginateSpeakersList={this.handlePaginateSpeakersList} />,
            allDataLoad: false,
            ActivePage: 1,
            AllAttendees: [],
            AllAttendeesData: [],
            perPage: attendeeLimit,
            totalPage: totalAttendeeCount,
            search : '',
        }
    }

    handleSearchSpeakersList = (e) => {
        var searchAttendee = e.target.value;
        searchAttendee = searchAttendee.replace(/[^a-zA-Z0-9\-\.\@\s]/, "");
        this.setState({
            search: searchAttendee,
            ActivePage: 1,
            content : this.renderSpeakersData(searchAttendee),
        })
    }

    renderSpeakersData( search, ActivePage= 1 ){
        var searchAttendee = search ? search : '';
        return (
            <AttendeeData constData={this.state.constData} displayPagination={true} activePage={ActivePage} attendeeLimit={this.state.perPage} searchAttendee={searchAttendee} handlePaginateSpeakersList={this.handlePaginateSpeakersList} />
        )
    }

    handlePaginateSpeakersList = (e) => {
        if( e !== undefined){
            this.setState({
                ActivePage: e,
                content : this.renderSpeakersData(this.state.search,e),
            })
        }else{
            this.setState({
                ActivePage: 1,
                content : this.renderSpeakersData(this.state.search,1),
            })
        }
    }

    render(){

        return (
            <>
                <SideMenu constData={this.state.constData}/>
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    <Header constData={this.state.constData} />
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="container-xxl" id="kt_content_container">
                            <div className="d-flex flex-wrap flex-stack mb-6">
                                <div className="col-md-3 d-flex align-items-center">
                                    <h1 className="fw-bold me-3 fw-flex"><PageTitle constData={this.state.constData} DefaultTitle="Attendees"/></h1>
                                </div>
                                <div className="card-toolbar my-1 d-flex flex-wrap">
                                    <div className="d-flex align-items-center position-relative my-1 me-4">
                                        <span className="svg-icon svg-icon-3 position-absolute ms-3"><i className="bi bi-search"></i></span>
                                        <input type={"text"} id={"kt_filter_search"} value={this.state.search} onChange={this.handleSearchSpeakersList} className={"form-control form-select-sm w-250px ps-9"} placeholder={"Search Attendees"} style={{border: "none"}}/>
                                    </div>
                                </div>
                            </div>
                            {this.state.content}
                        </div>
                    </div>
                    <Footer constData={this.state.constData} />
                </div>
            </>
        )
    }
}

export default AttendeesList;