import axios from "axios";
import ls from "localstorage-slim";

const {
    REACT_APP_URL,
    REACT_APP_HOME_URL,
    REACT_APP_SERVER_CONFIG_URL,
    PUBLIC_URL,
    REACT_APP_API_KEY,
    REACT_APP_API_SECRET,
    REACT_APP_LOGIN_TYPE,
    REACT_APP_LANG_ID,
    REACT_APP_API_URL
} = process.env;

const apiKey = REACT_APP_API_KEY;
const apiSecret = REACT_APP_API_SECRET;
const LogoUrl = REACT_APP_URL+'/webapp_new/img/ee_blue.png';
const SpeakerImageBaseUrl = REACT_APP_HOME_URL+'/uploads/speaker/';
const IsLoading = true;
const LangId = REACT_APP_LANG_ID;

const AvailableSideMenu  = [
    {id:1,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule"},
    {id:1001,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule1"},
    {id:1002,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule2"},
    {id:1003,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule3"},
    {id:1004,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule4"},
    {id:1005,icon:"bi-calendar4-week",url:REACT_APP_HOME_URL+"/schedule5"},
    {id:3,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors"},
    {id:3001,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors1"},
    {id:3002,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors2"},
    {id:3003,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors3"},
    {id:3004,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors4"},
    {id:3005,icon:"bi-grid",url:REACT_APP_HOME_URL+"/sponsors5"},
    {id:4,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers"},
    {id:4001,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers1"},
    {id:4002,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers2"},
    {id:4003,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers3"},
    {id:4004,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers4"},
    {id:4005,icon:"bi-mic",url:REACT_APP_HOME_URL+"/speakers5"},
    {id:11,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials"},
    {id:11001,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials1"},
    {id:11002,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials2"},
    {id:11003,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials3"},
    {id:11004,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials4"},
    {id:11005,icon:"bi-file-earmark",url:REACT_APP_HOME_URL+"/materials5"},
    {id:18,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about"},
    {id:18001,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about1"},
    {id:18002,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about2"},
    {id:18003,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about3"},
    {id:18004,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about4"},
    {id:18005,icon:"bi-info-square",url:REACT_APP_HOME_URL+"/about5"},
    {id:26,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees"},
    {id:26001,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees1"},
    {id:26002,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees2"},
    {id:26003,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees3"},
    {id:26004,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees4"},
    {id:26005,icon:"bi-people",url:REACT_APP_HOME_URL+"/attendees5"}
];

var ServerData = '';
var BaseUrl = REACT_APP_URL+REACT_APP_API_URL+'/';
var ApiBaseUrl = REACT_APP_URL+REACT_APP_API_URL+'/webservices';
var LoginType = REACT_APP_LOGIN_TYPE;
var Event_ID = "";

var CurrentPath = window.location.pathname;
if( CurrentPath === REACT_APP_HOME_URL+'/login' || CurrentPath === REACT_APP_HOME_URL+'/' ) {
    localStorage.removeItem("serverConfig");
}

export const constantSetup = async () => {

    const serverConfig = JSON.parse(localStorage.getItem("serverConfig"));

    if( null !== serverConfig ) {

        BaseUrl = serverConfig.url;
        ApiBaseUrl = serverConfig.url + '/webservices';
        ApiBaseUrl.replace('//webservices', "/webservices");
        LoginType = serverConfig.login_type !== undefined ? serverConfig.login_type : REACT_APP_LOGIN_TYPE;
        Event_ID = serverConfig.event_id !== undefined ? serverConfig.event_id : "";

        return {
            apiKey,
            apiSecret,
            BaseUrl,
            LogoUrl,
            IsLoading,
            ApiBaseUrl,
            AvailableSideMenu,
            LangId,
            SpeakerImageBaseUrl,
            LoginType,
            Event_ID
        };

    } else {

        const data = {
            url: `${REACT_APP_SERVER_CONFIG_URL}/dev_eventedge-server-config.json`,
            method: "GET",
            post_fields:{},
        };

        return await axios({
            url: PUBLIC_URL + '/functions.php?data=' + JSON.stringify(data),
            method: "GET",
            headers: {"Access-Control-Allow-Origin": "*"},
        }).then(res => {
            let result = res.data;
            if (res.status !== undefined && res.status === 200) {
                ServerData = result.ee !== undefined ? result.ee : '';
                localStorage.setItem("serverConfig", JSON.stringify(ServerData));
                if (ServerData !== undefined && ServerData !== "" && ServerData.url !== undefined) {
                    BaseUrl = ServerData.url;
                    ApiBaseUrl = ServerData.url + '/webservices';
                    ApiBaseUrl.replace('//webservices', "/webservices");
                    LoginType = ServerData.login_type !== undefined ? ServerData.login_type : REACT_APP_LOGIN_TYPE;
                    Event_ID = ServerData.event_id !== undefined ? ServerData.event_id : "";

                    var Login_type = ls.get("LoginType");

                    if( ( undefined !== Login_type && null !== Login_type && "" !== Login_type && LoginType !== Login_type ) || (LoginType !== Login_type && "P" !== LoginType) ) {
                        ls.remove("eeuserdata");
                        ls.remove("PreLoginSession");
                        ls.remove("PreLoginUserProfile");
                        ls.remove("login_status");
                        ls.remove("eventTitle");
                        ls.remove("user_login_status");
                        ls.remove("eventType");
                        ls.remove("userEmail");
                        ls.remove("Event_ID");
                        ls.set('LoginType',LoginType);
                    }

                    ls.set("Event_ID",Event_ID);
                }
            }

            return {
                apiKey,
                apiSecret,
                BaseUrl,
                LogoUrl,
                IsLoading,
                ApiBaseUrl,
                AvailableSideMenu,
                LangId,
                SpeakerImageBaseUrl,
                LoginType,
                Event_ID
            };
        }).catch(error => {});
    }
};